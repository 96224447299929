import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { LiveChatService } from 'src/app/service/live-chat-service';


@Component({
  selector: 'app-fila-atendimento',
  templateUrl: './fila-atendimento.component.html',
  styleUrls: ['./fila-atendimento.component.scss']
})
export class FilaAtendimentoComponent implements OnInit {

  public showChat: boolean;
  public arrayGruposAtendente: any[];
  public grupoSelecionado: any;

  public arrayClientesGrupos: any[];
  public arrayClientesEmAberto: any[];
  public arrayChatCliente: any[];
  public currentLine: string;
  public clienteChatSelecionado: any;
  public mensagem: string;
  public sender: any;
  public atendenteStatus: any;
  public respostaRapidaPorGrupo: any;

  constructor(
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService,
    public realtime: LiveChatService
  ) {
    this.showChat = false;
    this.arrayGruposAtendente = [];
    this.arrayClientesGrupos = [];
    this.arrayClientesEmAberto = [];
    this.arrayChatCliente = [];
    this.clienteChatSelecionado = null;
    this.mensagem = "";
    this.sender = null;
    this.atendenteStatus = null;
    this.respostaRapidaPorGrupo = null;
  }

  ngOnInit() {
    this.auth.getUser();
    this.buscarGrupos();
    this.realtime.initializeNewUserConnectionAsync(this.auth.user);

    this.realtime.novaMensagemRecebida.subscribe(sender => {
      console.log(sender);
      this.sender = sender;
      this.insereParametrosRealtime(sender);
    });

    this.realtime.onConnectionSuccessfully.subscribe(response => {
      if (response._connectionState != "Connected")
        setTimeout(() => this.realtime.initializeNewUserConnectionAsync(this.auth.user), 5000);
    });

    this.buscarStatusAtendente();
    this.buscarClientesPorAbertos();

  }

  modalRedirecionarOutroGrupo(item) {
    //this.selecionarConversa(item);

    this.auth.getUser();
    this.util.showLoading();
    let url = API + "Grupo/Grupo?ID_EMPRESA" + this.auth.user.user.guid_empresa;
    this.ajax.get(url)
      .subscribe(response => {
        this.util.closeLoading();

        let todosGrupos = response.data;
        this.openModalTodosGrupos(todosGrupos, item);

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Dados");
      });

  }

  openModalTodosGrupos(arrayTodosGrupos: any[], item) {
    let listGruposModal = {};
    arrayTodosGrupos.forEach(c => {
      listGruposModal[c.iD_GRUPO] = c.nome.toUpperCase();
    });
    console.log(listGruposModal);

    Swal.fire({
      text: 'Escolha o novo Grupo para o Cliente',
      input: 'select',
      type: "info",
      showCancelButton: true,
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Confirmar Encaminhamento',
      inputOptions: listGruposModal,
    }).then((result) => {
      if (result.value) {
        this.encaminharClienteOutroGrupo(result.value, item);
      }
    });
  }

  buscarMotivoEncerramentos(item: any) {
    this.auth.getUser();
    this.util.showLoading();
    let url = API + `Grupo/Grupo/buscarMotivoEncerramentoPorGrupo?guid_empresa=${item.guid_empresa}&guid_grupo=${item.guid_grupo}`;
    this.ajax.get(url)
      .subscribe(response => {
        this.util.closeLoading();

        let todosMotivos = response.data;
        this.modalEncerrarAtendimento(item, todosMotivos);

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Dados");
      });
  }


  modalEncerrarAtendimento(item: any, todosMotivos: any[]) {

    let listMotivosModal = {};
    todosMotivos.forEach(c => {
      listMotivosModal[c.iD_MOTIVO_ENCERRAMENTO] = c.titulo.toUpperCase();
    });



    Swal.fire({
      text: 'Tem Certeza que deseja encerrar o atendimento?',
      type: "info",
      showCancelButton: true,
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Confirmar',
      inputOptions: listMotivosModal,
      input: 'select'

    }).then((result) => {
      if (result.value) {
        this.encerrarAtendimento(item, result.value);
      }
    });
  }

  encerrarAtendimento(item: any, guid_encerramento: string) {

    this.auth.getUser();
    this.util.showLoading();
    let dados = {
      "ID_FILA_ATENDIMENTO": item.guid_fila_atendimento,
      "ID_EMPRESA": item.guid_empresa,
      "ID_MOTIVO_ENCERRAMENTO": guid_encerramento
    }
    debugger
    let url = API + "FilaAtendimento/FilaAtendimento/encerrarAtendimento";
    this.ajax.post(url, dados)
      .subscribe(response => {
        this.buscarGrupos();
        this.buscarClientesPorAbertos();
        this.arrayChatCliente = [];
        this.util.closeLoading();
        this.util.toasterShowSuccess("Atenção", "Atendimento Encerrado com Sucesso");
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
      });
  }

  encaminharClienteOutroGrupo(guid_novo_grupo: string, item: any) {
    this.auth.getUser();
    console.log('Novo grupo', guid_novo_grupo);
    this.util.showLoading();
    let dados = {
      "ID_FILA_ATENDIMENTO": item.guid_fila_atendimento,
      "ID_GRUPO": guid_novo_grupo,
      "ID_EMPRESA": item.guid_empresa
    }
    debugger
    let url = API + "FilaAtendimento/FilaAtendimento/encaminharClienteNovoGrupo";
    this.ajax.post(url, dados)
      .subscribe(response => {
        this.buscarGrupos();
        this.buscarClientesPorAbertos();
        this.arrayChatCliente = [];
        this.util.closeLoading();
        this.util.toasterShowSuccess("Atenção", "Status Alterado com Sucesso");
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
      });
  }

  buscarStatusAtendente() {
    this.auth.getUser();
    this.util.showLoading();
    let url = API + "Atendente/Atendente/BuscarStatus/" + this.auth.user.user.guid_atendente;
    this.ajax.get(url)
      .subscribe(response => {

        this.atendenteStatus = response.data;
        console.log(response);
        this.buscarGrupos();
        //this.buscarAtendentesConectados();
        this.util.closeLoading();

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Dados");
      });
  }

  alterarStatusAtendente(guid: string) {
    console.log(guid);
    this.auth.getUser();

    let dados = {
      "guid_atendente": this.auth.user.user.guid_atendente,
      "novo_guid_status_atendente": guid
    }

    this.util.showLoading();
    let url = API + "Atendente/Atendente/AlterarStatusTrabalhoAtendente";
    this.ajax.post(url, dados)
      .subscribe(response => {
        this.buscarStatusAtendente();
        this.util.closeLoading();
        this.util.toasterShowSuccess("Atenção", "Status Alterado com Sucesso");
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
      });
  }

  insereParametrosRealtime(sender) {
    this.arrayGruposAtendente.forEach(c => {
      let itemRealTime = sender.grupos.find(x => x.guid_grupo == c.iD_GRUPO);
      c.totalEmFila = itemRealTime.totalEmFila;
    });

    this.arrayClientesEmAberto.forEach(c => {
      let clienteRealTime = sender.abertos.clientes.find(x => x.guid_contato == c.guid_contato);
      console.log('clienteRealTime', clienteRealTime);
      if (clienteRealTime != undefined)
        c.qtd_nao_lidas = clienteRealTime.totalMensagem - c.qtd_mensagens;
      else
        c.qtd_nao_lidas = 0;
    })

    //console.log(this.arrayGruposAtendente);
    debugger
    if (this.clienteChatSelecionado != null)
      this.buscarChatCompleto(this.clienteChatSelecionado);
  }

  mostrarChat() {
    this.showChat = true;
  }

  esconderChat() {
    this.showChat = false;
  }

  vincularAtendente(cliente) {
    this.auth.getUser();
    let dados = {
      "ID_FILA_ATENDIMENTO": cliente.guid_fila_atendimento,
      "ID_EMPRESA": cliente.guid_empresa,
      "ID_ATENDENTE": this.auth.user.user.guid_atendente
    };
    this.util.showLoading();
    let url = API + `FilaAtendimento/FilaAtendimento/VincularAtendenteCliente`;
    this.ajax.post(url, dados)
      .subscribe(response => {
        console.log('sdfsdf', response);

        if (!response.hasErrors) {
          this.buscarClientesPorGrupo(cliente.guid_grupo);
          this.buscarGrupos();
        }
        else
          this.util.toasterShowError("Atenção", response.message);


        this.util.closeLoading();
      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
      });
  }

  buscarGrupos() {
    // status disponivel
    //if(this.atendenteStatus != null && this.atendenteStatus.iD_STATUS_ATENDENTE == "2dbe82c6-d972-437e-85c2-3f2980578a0b"){
    this.auth.getUser();
    this.util.showLoading();
    let url = API + `FilaAtendimento/FilaAtendimento/buscarGruposPorAtendente?ID_EMPRESA=${this.auth.user.user.guid_empresa}&ID_ATENDENTE=${this.auth.user.user.guid_atendente}`;
    this.ajax.get(url)
      .subscribe(response => {
        console.log(response);
        this.arrayGruposAtendente = response.data;
        console.log(this.arrayGruposAtendente);

        this.util.closeLoading();
      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Dados");
      });
    //}

  }

  selecionarGrupo(item: any) {
    this.grupoSelecionado = item;
    this.esconderChat();
    this.buscarClientesPorGrupo(item.iD_GRUPO);
  }

  buscarClientesPorGrupo(guid_grupo: string) {
    this.arrayClientesGrupos = [];
    this.auth.getUser();
    this.util.showLoading();
    let url = API + `FilaAtendimento/FilaAtendimento/buscarClientePorGrupo?ID_EMPRESA=${this.auth.user.user.guid_empresa}&ID_GRUPO=${guid_grupo}`;
    this.ajax.get(url)
      .subscribe(response => {

        console.log(response.data);
        this.arrayClientesGrupos = response.data;
        this.buscarGrupos();
        this.util.closeLoading();
      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Dados");
      });
  }


  buscarClientesPorAbertos() {
    this.arrayClientesEmAberto = [];
    this.arrayChatCliente = [];
    this.auth.getUser();
    this.util.showLoading();
    let url = API + `FilaAtendimento/FilaAtendimento/buscarClienteEmAberto?ID_EMPRESA=${this.auth.user.user.guid_empresa}&ID_ATENDENTE=${this.auth.user.user.guid_atendente}`;
    this.ajax.get(url)
      .subscribe(response => {

        console.log(response.data);
        this.arrayClientesEmAberto = response.data;
        this.grupoSelecionado = { nome: "Aberto" };
        this.mostrarChat();

        this.util.closeLoading();
      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Dados");
      });
  }

  selecionarConversa(item) {
    this.currentLine = item.guid_contato;
    //item.selecionado = !item.selecionado;
    console.log('conversaSelecionada', item);
    this.clienteChatSelecionado = item;
    this.buscarChatCompleto(item);
  }

  onChangeResponstaRapida(evento){
    //console.log('onChangeResponstaRapida',evento);
    this.mensagem = evento;
  }

  buscaMensagensRapidas(item) {
    this.respostaRapidaPorGrupo = null;
    let url = API + `Grupo/Grupo/buscarMensagemRapidaPorGrupo?guid_empresa=${item.guid_empresa}&guid_grupo=${item.guid_grupo}`;
    this.ajax.get(url)
      .subscribe(response => {

        this.respostaRapidaPorGrupo = response.data;
        console.log('responstas rapidas',response.data);

      }, err => {
        this.util.toasterShowError("Atenção", "Erro ao Buscar Dados");
      });
  }

  EnterSubmit(event) {
    if (event.key === "Enter") {
      this.EnviarMensagem();
    }
  }

  buscarChatCompleto(item) {
    this.arrayChatCliente = [];
    //this.util.showLoading();
    let dados = {
      "ID_EMPRESA": item.guid_empresa,
      "TELEFONE": item.telefone
    }
    let url = API + `FilaAtendimento/FilaAtendimento/buscarChatCompletoPorCliente`;
    this.ajax.post(url, dados)
      .subscribe(response => {

        this.arrayChatCliente = response.data;
        //console.log(this.arrayChatCliente);
        this.buscaMensagensRapidas(item);

        try {
          setTimeout(() => {
            let lastId = this.arrayChatCliente.length - 2;
            var elmnt = document.getElementById("sc" + lastId);
            elmnt.scrollIntoView();

          }, 300);
        } catch (e) {
          //this.util.closeLoading();
        }
        //this.util.closeLoading();


      }, err => {
        //this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Dados");
      });
  }

  EnviarMensagem() {
    this.auth.getUser();
    if (this.mensagem != "") {
      this.util.showLoading();
      let dados = {
        "numero": this.clienteChatSelecionado.telefone,
        "texto": this.mensagem,
        "guid_empresa": this.clienteChatSelecionado.guid_empresa,
        "guid_atendente": this.clienteChatSelecionado.guid_atendente,
        "guid_contato": this.clienteChatSelecionado.guid_contato,
        "nomeAtendente": this.clienteChatSelecionado.nomeAtendente,
        "mumeroEmpresa": this.auth.user.user.telefoneEmpresa
      }
      //let url = API + `Whatsapp/WhatsappApi/EnvioMensagem`;
      let url = API + 'Whatsapp/WhatsappApiOficial/EnvioMensagem';
      this.ajax.post(url, dados)
        .subscribe(response => {

          this.mensagem = "";
          this.buscarChatCompleto(this.clienteChatSelecionado);

          this.util.closeLoading();
        }, err => {
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Enviar Mensagem");
        });
    } else {
      this.util.toasterShowError("Atenção", "Digite a mensagem antes de enviar");
    }


  }

}

import { Component, OnInit } from '@angular/core';
import { atendente } from 'src/app/model/atendente.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';

@Component({
  selector: 'app-cadastro-atendente',
  templateUrl: './cadastro-atendente.component.html',
  styleUrls: ['./cadastro-atendente.component.scss']
})
export class CadastroAtendenteComponent implements OnInit {

  public usuario: any;
  public arrayUsuarios: any[];
  public query: string;

  constructor(
    private ajax: AjaxService,
    private util: UtilService,
    private auth: AuthService
  ) {
    this.usuario = { id: "", nome: "", sobrenome: "", email: "", perfil: "ATE", senha: "", confirmacaoSenha: "" };
    this.auth.getUser();
  }

  ngOnInit() {
    this.buscarAtendentes();
  }

  validaSalvar(): boolean {

    if (this.usuario.nome == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo nome");
      return false;
    }

    if (this.usuario.email == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo email");
      return false;
    }

    if(this.usuario.id == "" || this.usuario.senha != ''){
      if (this.usuario.senha == "") {
        this.util.toasterShowError("Atenção", "Preencha o campo senha");
        return false;
      }
  
      if (this.usuario.confirmacaoSenha == "") {
        this.util.toasterShowError("Atenção", "Preencha o campo confirmaçao de senha");
        return false;
      }
  
      if (this.usuario.senha != this.usuario.confirmacaoSenha) {
        this.util.toasterShowError("Atenção", "Senhas não são iguais, tente novamente por favor");
        return false;
      }
    }

    

    return true;
  }

  montarObjSave(): any {
    this.auth.getUser();
    let objSalvar = {
      "guid_empresa": this.auth.user.idEmpresa,
      "nome": this.usuario.nome,
      "sobrenome": this.usuario.sobrenome,
      "email": this.usuario.email,
      "perfil": this.usuario.perfil,
      "usuario": this.usuario.email
    };

    if(this.usuario.senha != "")
      objSalvar['senha'] = this.usuario.senha;

    if(this.usuario.id != "")
      objSalvar['guid_usuario'] = this.usuario.id;

    return objSalvar;
  }

  limpar() {
    this.usuario = { id: "", nome: "", sobrenome: "", email: "", perfil: "ATE", senha: "", confirmacaoSenha: "" };
  }

  salvarAtendente() {
    if (this.validaSalvar()) {
      let objSalvar = this.montarObjSave();
      this.util.showLoading();
      let url = API + "Atendente/Atendente/CadastroAtendente";
      this.ajax.post(url, objSalvar)
        .subscribe(response => {

          if (!response.hasErrors) {
            this.limpar();
            this.util.toasterShowSuccess("Atenção", "Salvo com Sucesso");
            this.buscarAtendentes();
          }
          else
            this.util.toasterShowError("Atenção", response.message);

          this.util.closeLoading();
        }, err => {

          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
        });
    }
  }

  desabilitarAtendente(item){
    console.log(item);
    this.util.showLoading();
      let url = API + "Atendente/Atendente/AlterarStatusAtendente";
      this.ajax.post(url, {"guid_atendente": item.guid_atendente, "status": item.ativo})
        .subscribe(response => {

          if (!response.hasErrors) {
            this.limpar();
            this.util.toasterShowSuccess("Atenção", "Salvo com Sucesso");
          }
          else
            this.util.toasterShowError("Atenção", response.message);

          this.util.closeLoading();
        }, err => {

          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
        });
  }

  buscarAtendentes() {
    this.auth.getUser();
    this.util.showLoading();
    let url = API + "Atendente/Atendente/BuscarAtendentes/" + this.auth.user.idEmpresa;
    this.ajax.get(url)
      .subscribe(response => {

        this.arrayUsuarios = response.data;
        this.util.closeLoading();
      }, err => {

        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao buscar Dados");
      });
  }

  selecionarItem(item){
    console.log(item);
    this.usuario = { 
      id: item.guid_usuario, 
      nome: item.nome, 
      sobrenome: "", 
      email: item.email, 
      perfil: item.perfil == "Administrador" ? "ADM" : "ATE", 
      senha: "", 
      confirmacaoSenha: "" 
    };
    window.scrollTo(0, 0);
  }


}

<div class="row">
    <div class="col-sm-4 "></div>
  <div class="col-sm-12 col-md-4">
    <app-card cardTitle="MOTIVO"  [hidHeader]="true" [options]="false">
       <div class="col-12">
        <h4 class="tituloCard text-center">Cadastro</h4>
       </div>       
      <div class="row ">
        <label class="sub-title">Titulo</label>
        <input type="text" class="form-control" [(ngModel)]="motivo.titulo" name="motivo.titulo" placeholder="">
      </div>
      <div class="row">
        <label class="sub-title">Descrição</label>
        <textarea class="form-control" [(ngModel)]="motivo.descricao" name="motivo.descricao" rows="10"></textarea>
      </div>
      <br>
      <div class="row">
        <div class="col-sm-12 float-right">
          <button type="button" class="btn btn-primary float-right " (click)="salvarMotivoEncerramento()" *ngIf="motivo.id == ''">
            <i class="feather icon-check-circle mr-2 f-18"></i>
            Salvar
          </button>
          <button type="button" class="btn btn-info float-right " (click)="salvarMotivoEncerramento()" *ngIf="motivo.id != ''">
            <i class="feather icon-check-circle mr-2 f-18"></i>
            Salvar Edição
          </button>
         <div class="col-1 float-right"></div>
          <button type="button" class="btn btn-warning float-right " *ngIf="motivo.id != ''" (click)="limpar()" style="padding-right:20px;">
            <i class="feather icon-check-circle mr-2 f-18"></i>
            Descartar Alteração
          </button>
          <!--<button type="button" class="btn btn-primary float-right " (click)="salvarMotivoEncerramento()">
            <i class="feather icon-check-circle mr-2 f-18"></i>
            Salvar
          </button>-->
        </div>
        <!--<div fxLayout="column-reverse" fxLayoutAlign="start start" fxLayoutGap="gappx">
                
              </div><div class="col-sm-12 col-xl-10 text-right" >
                <br>
                <button type="button" class="btn btn-warning " ><i class="fa fa-arrow-left"> </i> &nbsp;
                  LIMPAR PARA ADICIONAR NOVO
                </button>&nbsp;&nbsp;&nbsp;
                <button type="button" class="btn btn-info " > ATUALIZAR &nbsp;<i
                    class="feather icon-check-circle"></i>
                </button>
              </div>-->
      </div>
    </app-card>
  </div>

</div>


<div class="row">
  <app-card cardTitle="{{arrayMotivos?.length}} Motivos Cadastrados" blockClass="table-border-style" [options]="false" class="col-md-12">
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>titulo</th>
            <th>Descricao</th>
            <th>Acão</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of arrayMotivos">
            <td>{{item.titulo}}</td>
            <td>{{item.descricao}}</td>
            <td>
              <button type="button" class="btn btn-icon btn-info " (click)="selecionarItem(item)">
                <i class="fa fa-edit"></i>
              </button>
              <!--&nbsp;&nbsp;
              <button type="button" class="btn btn-icon btn-danger " >
                <i class="fa fa-trash"></i>
              </button>-->

            </td>
          </tr>          
        </tbody>
      </table>
    </div>
  </app-card>
</div>

import { UtilService } from 'src/app/service/util.service';
import { Component, OnInit } from '@angular/core';
import { API } from 'src/environments/environment';
import { ResponseNet } from 'src/app/model/ResponseNet.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { SampleData } from 'src/app/model/sampledata.model';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';

//https://github.com/Zicrael/ngx-tree-dnd/blob/master/README.MD
declare var $: any;

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent implements OnInit {

  //sampleData = new SampleData().data; // add the data to a property

  public tituloFluxo: string;
  public titulo: string;
  public descricao: string;
  public cod: string;
  public config: any;
  public myTree: any[];
  public data: any[];
  public nodeSelecionado: any;
  public perguntaPai: any;
  public ehEdicao: boolean;

  constructor(
    public util: UtilService,
    private ajax: AjaxService,
  ) {
    //this.configurar();
    this.limparModal();
    this.ehEdicao = false;
  }

  limparModal() {
    this.titulo = "";
    this.tituloFluxo = "";
    this.descricao = "";
    this.cod = "";
    this.nodeSelecionado = null;
    this.perguntaPai = null;
  }

  ngOnInit() {
    this.buscarDadosTree();
  }

  eventoUpdate(item: any) {
    this.ehEdicao = true;
    console.log('item a atualizar', item);
    this.limparModal();
    this.nodeSelecionado = this.perguntaPai = item;
    this.titulo = this.nodeSelecionado.titulo;
    this.tituloFluxo = this.nodeSelecionado.titulO_FLUXO;
    this.descricao = this.nodeSelecionado.descricao;
    this.cod = this.nodeSelecionado.cod;
    $("#btmodal").click();
  }

  eventoDel(item: any) {
    this.ehEdicao = false;
    console.log('item a deletar', item);
    this.limparModal();
    this.nodeSelecionado = this.perguntaPai = item;
    

    Swal.fire({
      title: 'ATENÇÃO?',
      text: "Você tem certeza que deseja 'DELETAR' este fluxo?",
      type: 'warning',
      showCancelButton: true,
      //confirmButtonColor: '#3085d6',
      confirmButtonText: 'Confirmar'
    }).then((result) => {      
      if (result.value) {
         this.deletar();
      }
    })

  }


  
  deletar() {
    console.log('salvar node', this.nodeSelecionado); 
    this.util.showLoading();
    var url = API + 'Fluxo/Fluxo/deletar/'+this.nodeSelecionado.iD_FLUXO;
    this.ajax.post(url, {})
      .subscribe((response: ResponseNet) => {

        this.util.closeLoading(); 
        this.buscarDadosTree();


      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
      });

  }

  eventoadd(item: any) {
    this.ehEdicao = false;
    this.limparModal();
    this.nodeSelecionado = this.perguntaPai = item;
    $("#btmodal").click();
  }

  buscarPergPai(idPai: string) {
    if (idPai) {
      this.util.showLoading();
      var url = API + 'Fluxo/Fluxo?ID_FLUXO=' + idPai;
      this.ajax.get(url)
        .subscribe((response: ResponseNet) => {

          console.log(response);
          this.perguntaPai = response.data[0];
          this.util.closeLoading();

        }, err => {
          debugger
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
        });
    }
  }

  buscarDadosTree() {
    this.util.showLoading();
    var url = API + 'Fluxo/Fluxo';
    this.ajax.get(url)
      .subscribe((response: ResponseNet) => {
        this.data = response.data;
        //this.data = this.ajustaObj(response.data);
        //console.log(this.data);
        this.montarTree();
        console.log('arvore', this.myTree);
        this.util.closeLoading();

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Dados");
      });
  }


  montarTree() {
    this.myTree = this.data.filter(item => item.iD_FLUXO_PAI == null);
    this.myTree.forEach(item => this.buildTree(item));
    console.log(this.myTree);
  }

  buildTree(item) {
    const getChildrem = this.data.filter(child => child.iD_FLUXO_PAI == item.iD_FLUXO);
    item['childrens'] = getChildrem;
    item.childrens.forEach(i => this.buildTree(i));
  }


  montarDados() {
    debugger
    return {
      "TITULO_FLUXO": this.tituloFluxo,
      "TITULO": this.titulo,
      "DESCRICAO": this.descricao,
      "ID_FLUXO_PAI": this.nodeSelecionado.iD_FLUXO,
      "COD": this.cod
    };
  }

  montarDadosEdicao() {
    debugger
    return {
      "TITULO_FLUXO": this.tituloFluxo,
      "TITULO": this.titulo,
      "DESCRICAO": this.descricao,
      //"ID_FLUXO_PAI": this.nodeSelecionado.iD_FLUXO,
      "ID_FLUXO": this.nodeSelecionado.iD_FLUXO,
      "COD": this.cod
    };
  }

  salvarEdicao(modal) {
    console.log('salvar node', this.nodeSelecionado);
    this.util.closeModal(modal);
    let dados = this.montarDadosEdicao();
    console.log(dados);

    this.util.showLoading();
    //let dados = this.montarDados();
    var url = API + 'Fluxo/Fluxo';
    this.ajax.post(url, dados)
      .subscribe((response: ResponseNet) => {

        this.util.closeLoading();
        this.util.closeModal(modal);
        this.buscarDadosTree();


      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
      });

  }

  salvarNode(modal) {
    console.log('salvar node', this.nodeSelecionado);
    this.util.closeModal(modal);
    let dados = this.montarDados();
    console.log(dados);

    this.util.showLoading();
    //let dados = this.montarDados();
    var url = API + 'Fluxo/Fluxo';
    this.ajax.post(url, dados)
      .subscribe((response: ResponseNet) => {

        this.util.closeLoading();
        this.util.closeModal(modal);
        this.buscarDadosTree();


      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
      });

  }



}

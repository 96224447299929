<div class="row">
  <app-card [hidHeader]="false" cardTitle="Empresa Cadastrada" [options]="false" class="col-sm-12" id="inicioPagina">
    <div class="row ">
      <div class="col-sm-6 col-xl-6 ">
        <label class="sub-title">Nome</label>
        <input type="text" class="form-control" [(ngModel)]="nome" name="nome" placeholder="">
      </div>
      <div class="col-sm-12 col-xl-3 ">
        <label class="sub-title">CNPJ</label>
        <input type="text" class="form-control" [textMask]="{mask: maskCNPJ}" [(ngModel)]="cnpj" name="cnpj"
          placeholder="">
      </div>

      <div class="col-sm-12 col-xl-3">
        <label class="sub-title">Tipo Atendimento </label>
        <select class="form-control" [(ngModel)]="tipoAtendimento" name="tipoAtendimento">
          <option value="bot">Bot</option>
          <option value="atendimento">Atendimento</option>
          <option value="bot_atendimento">Bot/Atendimento</option>
        </select>
      </div>
    </div>

    <div class="row">      
      <div class="col-sm-6 col-xl-3">
        <label class="sub-title">Numero Bot </label>
        <input type="text" class="form-control" [(ngModel)]="telBot" name="telBot" placeholder="">
      </div>
      <div class="col-sm-6 col-xl-3">
        <label class="sub-title">Contato </label>
        <input type="text" class="form-control" [textMask]="{mask: maskTelefoneCelular}" [(ngModel)]="telContato"
          name="telContato" placeholder="">
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-sm-6 col-xl-3">
        <label class="sub-title">App Facebook </label>
        <input type="text" class="form-control" [(ngModel)]="appFacebook" name="appFacebook" placeholder="">
      </div>
      <div class="col-sm-6 col-xl-9">
        <label class="sub-title">Token Facebook</label>
        <input type="text" class="form-control" [(ngModel)]="token" name="token" placeholder="">
      </div>
    </div>
    <br>

    <div class="row">
      <div class="col-sm-12 ">
        <button type="button" class="btn btn-primary float-right " (click)="salvar()">
          <i class="feather icon-check-circle mr-2 f-18"></i>
          Salvar
        </button>
      </div>
    </div>
  </app-card>
</div>

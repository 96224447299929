<div class="row">
  <app-card [hidHeader]="true" [options]="false" class="col-sm-12" id="inicioPagina">
    <div class="row ">
      <div class="col-sm-6 col-xl-4 ">
        <label class="sub-title">Nome</label>
        <input type="text" class="form-control" [(ngModel)]="usuario.nome" name="usuario.nome" placeholder="">
      </div>
      <!--<div class="col-sm-6 col-xl-4 ">
        <label class="sub-title">Sobrenome</label>
        <input type="text" class="form-control" [(ngModel)]="usuario.sobrenome" name="usuario.sobrenome" placeholder="">
      </div>-->
      <div class="col-sm-12 col-xl-4 ">
        <label class="sub-title">Email</label>
        <input type="text" class="form-control" [(ngModel)]="usuario.email" name="usuario.email" placeholder="">
      </div>
      <div class="col-sm-12 col-xl-4">
        <label class="sub-title">Perfil </label>
        <select class="form-control" [(ngModel)]="usuario.perfil" name="usuario.perfil">
          <option value="ATE">Atendente</option>
          <option value="ADM">Administrador</option>
        </select>
        <!--<input type="text" class="form-control" [(ngModel)]="usuario.perfil" name="usuario.perfil" placeholder="">-->
      </div>
      <div class="col-sm-6 col-xl-3">
        <label class="sub-title">Senha </label>
        <input type="password" class="form-control" [(ngModel)]="usuario.senha" name="usuario.senha" placeholder="">
      </div>
      <div class="col-sm-6 col-xl-3">
        <label class="sub-title">Confirmação de Senha </label>
        <input type="password" class="form-control" [(ngModel)]="usuario.confirmacaoSenha"
          name="usuario.confirmacaoSenha" placeholder="">
      </div>
    </div>
    <br>
    <!--<div class="row">
      <div class="col-sm-12">
        <div class="col-md-8"></div>
        <div class="col-md-2">
          <button type="button" class="btn btn-primary float-right " (click)="salvarAtendente()"
            *ngIf="usuario.id == ''">
            <i class="feather icon-check-circle mr-2 f-18"></i>
            Salvar
          </button>
        </div>
        <div class="col-md-2">
          <button type="button" class="btn btn-info float-right " *ngIf="usuario.id != ''">
            <i class="feather icon-check-circle mr-2 f-18"></i>
            Salvar Edição
          </button>
        </div>
        <div class="col-md-3">
          <button type="button" class="btn btn-warning float-right " *ngIf="usuario.id != ''" (click)="limpar()">
            <i class="feather icon-check-circle mr-2 f-18"></i>
            Descartar Alteração
          </button>
        </div>
      </div>

    </div>-->
    <div class="row">
      <div class="col-sm-12 ">
        <button type="button" class="btn btn-primary float-right " (click)="salvarAtendente()" *ngIf="usuario.id == ''">
          <i class="feather icon-check-circle mr-2 f-18"></i>
          Salvar
        </button>
        <button type="button" class="btn btn-info float-right " (click)="salvarAtendente()" *ngIf="usuario.id != ''">
          <i class="feather icon-check-circle mr-2 f-18"></i>
          Salvar Edição
        </button>
       <div class="col-1 float-right"></div>
        <button type="button" class="btn btn-warning float-right " *ngIf="usuario.id != ''" (click)="limpar()" style="padding-right:20px;">
          <i class="feather icon-check-circle mr-2 f-18"></i>
          Descartar Alteração
        </button>

      </div>

    </div>
  </app-card>
</div>


<div class="row">
  <app-card cardTitle="{{arrayUsuarios?.length}} Usuários Cadastrados" [options]="false" class="col-md-12">
    <div class="row ">
      <div class="col-md-6 col-sm-12 ">
        <label for="exampleInputEmail1">Filtro</label>
        <input type="text" class="form-control form-control-sm " [(ngModel)]="query"
          placeholder="Digite seu filtro, sera feito uma busca em todas as colunas" >
      </div>
    </div><br>
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Email</th>
            <th>Status</th>
            <th>Perfil</th>
            <th>Acão</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of arrayUsuarios  | fullTextSearch:query" >
            <td>{{item.nome}}</td>
            <td>{{item.email}}</td>
            <td>
              <span *ngIf="item.ativo" class="badge badge-success">ATIVO</span>
              <span *ngIf="!item.ativo" class="badge badge-danger">INATIVO</span>
              <div class="form-group">
                <div class="switch switch-primary d-inline m-r-10">
                  <input type="checkbox" id="{{item.guid_atendente}}" [(ngModel)]="item.ativo"
                    (change)="desabilitarAtendente(item)">
                  <label for="{{item.guid_atendente}}" class="cr"></label>
                </div>
              </div>
            </td>
            <td>{{item.perfil}}</td>
            <td>
              <button type="button" class="btn btn-icon btn-info " (click)="selecionarItem(item)">
                <i class="fa fa-edit"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </app-card>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import Drawflow, {
  ConnectionEvent,
  ConnectionStartEvent,
  DrawFlowEditorMode,
  DrawflowConnection,
  DrawflowConnectionDetail,
  DrawflowNode,
  MousePositionEvent,
} from 'drawflow';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';
import { NodeElement } from './node.model';


@Component({
  selector: 'app-draw-flow',
  templateUrl: './draw-flow.component.html',
  styleUrls: ['./draw-flow.component.scss']
})
export class DrawFlowComponent implements OnInit {

  id: any = null;
  editor: any = null;
  transform: string = "";
  mobile_item_selec: string = "";
  mobile_last_move: any = null;
  mousePosition: any;
  nodeSelected: any;

  constructor(
    private sanitizer: DomSanitizer,
    private ajax: AjaxService,
    public util: UtilService,
    public auth: AuthService
  ) { }

  ngOnInit(): void {
    this.auth.getUser();
    this.drawflow({ drawflow: { Home: { data: {} } } });    
    this.buscarNodes();
  }

  drawflow(itens) {

    this.id = document.getElementById("drawflow");
    this.editor = new Drawflow(this.id);
    this.registerEvents(this.editor);
    this.editor.reroute = true;
    this.editor.drawflow = itens;
    //this.editor.drawflow = this.drawflows();
    this.editor.start();

    const elements = document.getElementsByClassName("drag-drawflow");
    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener("touchend", this.drop, false);
      elements[i].addEventListener("touchmove", this.positionMobile, false);
      elements[i].addEventListener("touchstart", this.drag, false);
    }
  }

  /*dadosFakee (){
    var test = { "drawflow": { "Home": { "data": { "1": { "id": 1, "name": "begin", "data": {}, "class": "begin", "html": "<div><div ><i ></i> Begin</div></div>", "typenode": false, "inputs": {"input_1": { "connections": [] }}, "outputs": { "output_1": { "connections": [] } }, "pos_x": 50, "pos_y": 50 } } }, "Other": { "data": {} } }};
   console.log(test);
    return test ;
  }*/

  ajusteDados(itensArray: any[]): any {
    let obj = { drawflow: { Home: { data: {} } } };
    
    for (const [key, value] of Object.entries(itensArray)) {

      value.inputs = value.inputs == null ? {'input_1': {'connections': []}} : value.inputs;
      value.outputs = value.outputs == null ? {'output_1': {'connections': []}} : value.outputs;
      value.data = value.data == null ? {} : value.data;

      obj.drawflow.Home.data[value.id] = value;
    }
    console.log(obj);
    return obj;
  }




  buscarNodes() {
    this.util.showLoading();
    var url = API + 'DrawFlow/DrawFlow/BuscarFluxo';
    this.ajax.get(url)
      .subscribe(response => {

        this.util.closeLoading();
        let objResponse = this.ajusteDados(response.data);
        if(response.data.length > 0)
          this.drawflow(objResponse);

      }, err => {

        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Dados");
      });
  }

  teste(): void {
    console.log("teste");
  }

  positionMobile(ev) {
    this.mobile_last_move = ev;
  }

  allowDrop(ev) {
    ev.preventDefault();
  }

  onSubmit() {
    const dataExport = this.editor.export();
    //console.log('dataExport galooo:>> ', dataExport.drawflow.Home.data);

    let objSave = [];
    for (const [key, value] of Object.entries(dataExport.drawflow.Home.data)) {
      let obj = this.montarObjSave(value);
      objSave.push(obj);
    }

    console.log('obj a salvar', objSave);

    let url = API + "DrawFlow/DrawFlow";
    this.ajax.post(url, objSave)
      .subscribe(response => {

        //this.limparModal();
        this.util.closeLoading();
        this.util.toasterShowSuccess("Atenção", "Fluxo salvo com sucesso");
        //this.util.closeModal(modal);
        //this.buscarDadosTree();
        debugger
        //this.atualizar.emit('sucesso');

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
      });

  }

  montarObjSave(obj: any): any {


    var ajustado = {
      "ID_NODE": obj.id,
      "CLASS": obj.class,
      "HTML": obj.html,
      "NAME": obj.name,
      "POS_X": obj.pos_x,
      "POS_Y": obj.pos_y,
      "TYPENODE": obj.typenode,
      "CHAVE": obj.data.chave,
      "CORPO": obj.data.corpo,
      "NOME": obj.data.nome
    };

    if (obj.inputs.input_1.connections.length > 0) {
      let inputs = [];
      obj.inputs.input_1.connections.forEach(item => {
        inputs.push({ "NODE": item.node, "INPUT": item.input });
      })
      ajustado['INPUTS'] = inputs;
    }

    if (obj.outputs.output_1.connections.length > 0) {
      let outputs = [];
      obj.outputs.output_1.connections.forEach(item => {
        outputs.push({ "NODE": item.node, "OUTPUT": item.output });
      })
      ajustado['OUTPUTS'] = outputs;
    }

    return ajustado;
  }

  drag(ev) {
    console.log("drag", ev);
    if (ev.type === "touchstart") {
      this.mobile_item_selec = ev.target
        .closest(".drag-drawflow")
        .getAttribute("data-node");
    } else {
      ev.dataTransfer.setData("node", ev.target.getAttribute("data-node"));
    }
  }

  drop(ev) {
    console.log("drop", ev);
    if (ev.type === "touchend") {
      let parentdrawflow = document
        .elementFromPoint(
          this.mobile_last_move.touches[0].clientX,
          this.mobile_last_move.touches[0].clientY
        )
        .closest("#drawflow");
      if (parentdrawflow != null) {
        this.addNodeToDrawFlow(
          this.mobile_item_selec,
          this.mobile_last_move.touches[0].clientX,
          this.mobile_last_move.touches[0].clientY
        );
      }
      this.mobile_item_selec = "";
    } else {
      ev.preventDefault();
      let data = ev.dataTransfer.getData("node");
      this.addNodeToDrawFlow(data, ev.clientX, ev.clientY);
    }
  }

  addNodeToDrawFlow(name, pos_x, pos_y) {

    if (this.editor.editor_mode === "fixed") {
      return false;
    }
    pos_x =
      pos_x *
      (this.editor.precanvas.clientWidth /
        (this.editor.precanvas.clientWidth * this.editor.zoom)) -
      this.editor.precanvas.getBoundingClientRect().x *
      (this.editor.precanvas.clientWidth /
        (this.editor.precanvas.clientWidth * this.editor.zoom));
    pos_y =
      pos_y *
      (this.editor.precanvas.clientHeight /
        (this.editor.precanvas.clientHeight * this.editor.zoom)) -
      this.editor.precanvas.getBoundingClientRect().y *
      (this.editor.precanvas.clientHeight /
        (this.editor.precanvas.clientHeight * this.editor.zoom));


    this.editor.draggable_inputs = true;

    switch (name) {
      case "facebook":
        let facebook = `
        <div>
          <div class="title-box"><i class="fab fa-facebook"></i> Facebook Message</div>
        </div>
        `;
        this.editor.addNode(
          "facebook",
          0,
          1,
          pos_x,
          pos_y,
          "facebook",
          {},
          facebook
        );
        break;
      case "slack":
        let slackchat = `
          <div>
            <div class="title-box"><i class="fab fa-slack"></i> Slack chat message</div>
          </div>
          `;
        this.editor.addNode(
          "slack",
          1,
          0,
          pos_x,
          pos_y,
          "slack",
          {},
          slackchat
        );
        break;
      case "github":
        let githubtemplate = `
          <div>
            <div class="title-box"><i class="fab fa-github "></i> Github Stars</div>
            <div class="box">
              <p>Enter repository url</p>
            <input type="text" df-name>
            </div>
          </div>
          `;
        this.editor.addNode(
          "github",
          0,
          1,
          pos_x,
          pos_y,
          "github",
          { name: "" },
          githubtemplate
        );
        break;
      case "telegram":
        let telegrambot = `
          <div>
            <div class="title-box"><i class="fab fa-telegram-plane"></i> Telegram bot</div>
            <div class="box">
              <p>Send to telegram</p>
              <p>select channel</p>
              <select df-channel>
                <option value="channel_1">Channel 1</option>
                <option value="channel_2">Channel 2</option>
                <option value="channel_3">Channel 3</option>
                <option value="channel_4">Channel 4</option>
              </select>
            </div>
          </div>
          `;
        this.editor.addNode(
          "telegram",
          1,
          0,
          pos_x,
          pos_y,
          "telegram",
          { channel: "channel_3" },
          telegrambot
        );
        break;
      case "aws":
        let aws = `
          <div>
            <div class="title-box"><i class="fab fa-aws"></i> Aws Save </div>
            <div class="box">
              <p>Save in aws</p>
              <input type="text" df-db-dbname placeholder="DB name"><br><br>
              <input type="text" df-db-key placeholder="DB key">
              <p>Output Log</p>
            </div>
          </div>
          `;
        this.editor.addNode(
          "aws",
          1,
          1,
          pos_x,
          pos_y,
          "aws",
          { db: { dbname: "", key: "" } },
          aws
        );
        break;
      case "log":
        let log = `
            <div>
              <div class="title-box"><i class="fas fa-file-signature"></i> Save log file </div>
            </div>
            `;
        this.editor.addNode("log", 1, 0, pos_x, pos_y, "log", {}, log);
        break;
      case "google":
        let google = `
            <div>
              <div class="title-box"><i class="fab fa-google-drive"></i> Google Drive save </div>
            </div>
            `;
        this.editor.addNode("google", 1, 0, pos_x, pos_y, "google", {}, google);
        break;
      case "email":
        let email = `
            <div>
              <div class="title-box"><i class="fas fa-at"></i> Send Email </div>
            </div>
            `;
        this.editor.addNode("email", 1, 0, pos_x, pos_y, "email", {}, email);
        break;

      case "template":
        let template = `
            <div>
              <div class="box">
              <label class="tituloNodeCenter" >Título Fluxo</label>
                <input df-titulo>
                <label class="tituloNode">Título</label>
                <input df-nome>
                <label class="tituloNode">Chave</label>
                <input df-chave>
                <label class="tituloNode">Mensagem</label>
                <textarea df-corpo></textarea>               
                <button  class='edit-node-button'>Salvar</button>
              </div>
            </div>
            `;
        this.editor.addNode(
          "template",
          1,
          1,
          pos_x,
          pos_y,
          "template",
          { nome: "", corpo: "", chave: "", titulo: "", id: 0 },
          template
        );
        break;
      case "multiple":
        let multiple = `
            <div>
              <div class="box">
                Multiple!
              </div>
            </div>
            `;
        this.editor.addNode(
          "multiple",
          3,
          4,
          pos_x,
          pos_y,
          "multiple",
          {},
          multiple
        );
        break;
      case "personalized":
        let personalized = `
            <div>
              Personalized
            </div>
            `;
        this.editor.addNode(
          "personalized",
          1,
          1,
          pos_x,
          pos_y,
          "personalized",
          {},
          personalized
        );
        break;

      case 'dbclick':
        var dbclick = `
            <div>
           
            <div class="title-box"><i class="fas fa-mouse"></i> Db Click</div><br>
              <div class="box dbclickbox" ondblclick="showpopup(event)">
                Db Click here
                <button  class='edit-node-button' name='editNode' id='editNode'>Editar Node</button>
                <div class="modal" style="display:none">
                  <div class="modal-content">
                    <span class="close" onclick="closemodal(event)">&times;</span>
                    Change your variable {name} !
                    <input type="text" df-name>
                  </div>

                </div>
              </div>
            </div>
            `;
        this.editor.addNode('dbclick', 1, 1, pos_x, pos_y, 'dbclick', { name: '' }, dbclick);
        break;

      default:
    }
  }




  changeModule(event) {
    let all = document.querySelectorAll(".menu ul li");
    for (let i = 0; i < all.length; i++) {
      all[i].classList.remove("selected");
    }
    event.target.classList.add("selected");
  }

  savePosition(obj) {
    debugger
    this.auth.getUser();
    obj["ID_EMPRESA"] = this.auth.user.idEmpresa;

    let url = API + "DrawFlow/DrawFlow/moveNode";
    this.ajax.post(url, obj)
      .subscribe(response => {
        //this.limparModal();
        this.util.closeLoading();
        //this.util.toasterShowInfo("Atenção", "Posição Atualizada com Sucesso");


      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Nova Posição");
      });
  }

  /*createNode(obj) {
    let url = API + "DrawFlow/DrawFlow/createNode";
    this.ajax.post(url, obj)
      .subscribe(response => {

        //this.limparModal();
        this.util.closeLoading();
        this.util.toasterShowSuccess("Atenção", "Posição Atualizada");
        //this.util.closeModal(modal);
        //this.buscarDadosTree();
        debugger
        //this.atualizar.emit('sucesso');

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
      });
  }*/

  public objAnt: any;
  createNode(obj) {
    //if (obj.ID_DRAW_FLOW != undefined && obj.ID_DRAW_FLOW != null && obj.ID_DRAW_FLOW > 0)
    //  this.util.toasterShowInfo("TEste", "Ira alterar");


    let url = API + "DrawFlow/DrawFlow/createNode";
    this.ajax.post(url, obj)
      .subscribe(response => {
        //atualizdo o node

 
          if (obj.ID_DRAW_FLOW != undefined && obj.ID_DRAW_FLOW != null && obj.ID_DRAW_FLOW > 0)
            this.util.toasterShowInfo("Atenção", "Item Atualizado com Sucesso!");



        this.editor.drawflow.drawflow.Home.data[obj.ID_NODE].data.id = response.data.iD_DRAW_FLOW;

        this.util.closeLoading();
        //this.util.toasterShowSuccess("Atenção", "Node Criado com Sucesso");
        this.objAnt = null;

      }, err => {

        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
      });

  }


  createConnection(obj) {

    let url = API + "DrawFlow/DrawFlow/createConnection";
    this.ajax.post(url, obj)
      .subscribe(response => { 
        console.log(response);

      }, err => {

        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
      });

  }



  montarNodeCreate(obj): any {
    this.auth.getUser();
    debugger
    let objsave = {
      "ID_NODE": obj.id,
      "CLASS": obj.class,
      "HTML": obj.html,
      "NAME": obj.name,
      "POS_X": obj.pos_x,
      "POS_Y": obj.pos_y,
      "TYPENODE": obj.typenode,
      "CHAVE": obj.data.chave,
      "CORPO": obj.data.corpo,
      "NOME": obj.data.nome,
      "TITULO": obj.data.titulo,
      "ID_EMPRESA": this.auth.user.idEmpresa
    };

    if (obj.data.id != undefined && obj.data.id != null && obj.data.id > 0)
      objsave['ID_DRAW_FLOW'] = obj.data.id

    return objsave;
  }


  registerEvents(editor: any): void {
    this.objAnt = null;

    editor.on("nodeCreated", id => {
      console.log("Node created " + id);
      let dados = this.editor.drawflow.drawflow.Home.data[id];
      debugger
      let objSave = this.montarNodeCreate(dados);
      console.log(objSave);
      this.createNode(objSave);
    });

    editor.on('click', (e: any) => {

      /*if (e.target.classList[0] === 'edit-node-button') {

        let selected = e.target.closest(".drawflow_content_node").parentElement.id;
        let dados = this.editor.drawflow.drawflow.Home.data[`${selected.slice(5)}`];
        let objSave = this.montarNodeCreate(dados);
        
        // esta chamando click 2 vezes .. isso é pra salvar apenas um
        if (this.objAnt == null)
          this.createNode(objSave);

        this.objAnt = objSave;
      }*/

    });



    /*editor.on("contextmenu", event => {
      console.log("contextmenu " + event);
      if (event.target.closest(".drawflow_content_node") != null || event.target.classList[0] === 'drawflow-node') {
        this.showConextMenu(event.clientX, event.clientY);
      }
    });*/



    editor.on("nodeRemoved", id => {
      console.log("Node removed " + id);


    });

    editor.on("nodeSelected", id => {
      console.log("Node selected " + id);
      this.nodeSelected = id;
      let dados = this.editor.drawflow.drawflow.Home.data[id];
      console.log('vamos ser sem output', dados);
    });

    editor.on("moduleCreated", name => {
      console.log("Module Created " + name);
    });

    editor.on("moduleChanged", name => {
      console.log("Module Changed " + name);
    });

    editor.on("connectionCreated", connection => {

      console.log("Connection created");
      console.log(connection);

      this.createConnection(connection);

    });

    editor.on("connectionRemoved", connection => {
      console.log("Connection removed");
      console.log(connection);
    });

    editor.on("mouseMove", position => {
      //console.log("Position mouse x:" + position.x + " y:" + position.y);
      this.mousePosition = position;
    });



    editor.on("nodeMoved", id => {
      console.log("Node moved " + id);

      let pos_x =
        this.mousePosition.x *
        (this.editor.precanvas.clientWidth /
          (this.editor.precanvas.clientWidth * this.editor.zoom)) -
        this.editor.precanvas.getBoundingClientRect().x *
        (this.editor.precanvas.clientWidth /
          (this.editor.precanvas.clientWidth * this.editor.zoom));
      let pos_y =
        this.mousePosition.y *
        (this.editor.precanvas.clientHeight /
          (this.editor.precanvas.clientHeight * this.editor.zoom)) -
        this.editor.precanvas.getBoundingClientRect().y *
        (this.editor.precanvas.clientHeight /
          (this.editor.precanvas.clientHeight * this.editor.zoom));

      let obj = {
        "id": this.editor.drawflow.drawflow.Home.data[id].data.id,
        "pos_x": pos_x,
        "pos_y": pos_y,
      };
      //console.log(obj);
      this.savePosition(obj);
    });

    editor.on("zoom", zoom => {
      console.log("Zoom level " + zoom);
    });

    editor.on("translate", position => {
      //console.log("Translate x:" + position.x + " y:" + position.y);
    });

    editor.on("addReroute", id => {
      console.log("Reroute added " + id);
    });

    editor.on("removeReroute", id => {
      console.log("Reroute removed " + id);
    });
  }



  drawflows(): any {



    return {
      drawflow: {
        Home: {
          data: {
            "1": {
              id: 1,
              name: "welcome",
              data: {},
              class: "welcome",
              html:
                '\n    <div>\n      <div class="title-box">👏 Welcome!!</div>\n      <div class="box">\n        <p>Simple flow library <b>demo</b>\n        <a href="https://github.com/jerosoler/Drawflow" target="_blank">Drawflow</a> by <b>Jero Soler</b></p><br>\n\n        <p>Multiple input / outputs<br>\n           Data sync nodes<br>\n           Import / export<br>\n           Modules support<br>\n           Simple use<br>\n           Type: Fixed or Edit<br>\n           Events: view console<br>\n           Pure Javascript<br>\n        </p>\n        <br>\n        <p><b><u>Shortkeys:</u></b></p>\n        <p>🎹 <b>Delete</b> for remove selected<br>\n        💠 Mouse Left Click == Move<br>\n        ❌ Mouse Right == Delete Option<br>\n        🔍 Ctrl + Wheel == Zoom<br>\n        📱 Mobile support<br>\n        ...</p>\n      </div>\n    </div>\n    ',
              typenode: false,
              inputs: {},
              outputs: {},
              pos_x: 50,
              pos_y: 50
            },
            "2": {
              id: 2,
              name: "slack",
              data: {},
              class: "slack",
              html:
                '\n          <div>\n            <div class="title-box"><i class="fab fa-slack"></i> Slack chat message</div>\n          </div>\n          ',
              typenode: false,
              inputs: {
                input_1: { connections: [{ node: "7", input: "output_1" }] }
              },
              outputs: {},
              pos_x: 1028,
              pos_y: 87
            },
            "3": {
              id: 3,
              name: "telegram",
              data: { channel: "channel_2" },
              class: "telegram",
              html:
                '\n          <div>\n            <div class="title-box"><i class="fab fa-telegram-plane"></i> Telegram bot</div>\n            <div class="box">\n              <p>Send to telegram</p>\n              <p>select channel</p>\n              <select df-channel>\n                <option value="channel_1">Channel 1</option>\n                <option value="channel_2">Channel 2</option>\n                <option value="channel_3">Channel 3</option>\n                <option value="channel_4">Channel 4</option>\n              </select>\n            </div>\n          </div>\n          ',
              typenode: false,
              inputs: {
                input_1: { connections: [{ node: "7", input: "output_1" }] }
              },
              outputs: {},
              pos_x: 1032,
              pos_y: 184
            },
            "4": {
              id: 4,
              name: "email",
              data: {},
              class: "email",
              html:
                '\n            <div>\n              <div class="title-box"><i class="fas fa-at"></i> Send Email </div>\n            </div>\n            ',
              typenode: false,
              inputs: {
                input_1: { connections: [{ node: "5", input: "output_1" }] }
              },
              outputs: {},
              pos_x: 1033,
              pos_y: 439
            },
            "5": {
              id: 5,
              name: "template",
              data: { template: "Write your template" },
              class: "template",
              html:
                '\n            <div>\n              <div class="title-box"><i class="fas fa-code"></i> Template</div>\n              <div class="box">\n                Ger Vars\n                <textarea df-template></textarea>\n                Output template with vars\n              </div>\n            </div>\n            ',
              typenode: false,
              inputs: {
                input_1: { connections: [{ node: "6", input: "output_1" }] }
              },
              outputs: {
                output_1: {
                  connections: [
                    { node: "4", output: "input_1" },
                    { node: "11", output: "input_1" }
                  ]
                }
              },
              pos_x: 607,
              pos_y: 304
            },
            "6": {
              id: 6,
              name: "github",
              data: { name: "https://github.com/jerosoler/Drawflow" },
              class: "github",
              html:
                '\n          <div>\n            <div class="title-box"><i class="fab fa-github "></i> Github Stars</div>\n            <div class="box">\n              <p>Enter repository url</p>\n            <input type="text" df-name>\n            </div>\n          </div>\n          ',
              typenode: false,
              inputs: {},
              outputs: {
                output_1: { connections: [{ node: "5", output: "input_1" }] }
              },
              pos_x: 341,
              pos_y: 191
            },
            "7": {
              id: 7,
              name: "facebook",
              data: {},
              class: "facebook",
              html:
                '\n        <div>\n          <div class="title-box"><i class="fab fa-facebook"></i> Facebook Message</div>\n        </div>\n        ',
              typenode: false,
              inputs: {},
              outputs: {
                output_1: {
                  connections: [
                    { node: "2", output: "input_1" },
                    { node: "3", output: "input_1" },
                    { node: "11", output: "input_1" }
                  ]
                }
              },
              pos_x: 347,
              pos_y: 87
            },
            "11": {
              id: 11,
              name: "log",
              data: {},
              class: "log",
              html:
                '\n            <div>\n              <div class="title-box"><i class="fas fa-file-signature"></i> Save log file </div>\n            </div>\n            ',
              typenode: false,
              inputs: {
                input_1: {
                  connections: [
                    { node: "5", input: "output_1" },
                    { node: "7", input: "output_1" }
                  ]
                }
              },
              outputs: {},
              pos_x: 1031,
              pos_y: 363
            }
          }
        }
      }
    };
  }

}

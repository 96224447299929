<div class="row">
    <div class="col-md-4"></div>
  <div class="col-sm-12 col-md-4">
    <app-card cardTitle="MOTIVO"  [hidHeader]="true" [options]="false">
       <div class="col-12">
        <h4 class="tituloCard text-center">Cadastro</h4>
       </div>       
      <div class="row ">
        <label class="sub-title">Titulo</label>
        <input type="text" class="form-control" [(ngModel)]="resposta.titulo" name="resposta.titulo" placeholder="">
      </div>
      <div class="row">
        <label class="sub-title">Descrição</label>
        <textarea class="form-control" [(ngModel)]="resposta.descricao" name="resposta.descricao" rows="10"></textarea>
      </div>
      <br>
      <div class="row">
        <div class="col-sm-12 float-right">
          <!--<button type="button" class="btn btn-primary float-right " (click)="salvarRespostaRapida()">
            <i class="feather icon-check-circle mr-2 f-18"></i>
            Salvar
          </button>-->
          <button type="button" class="btn btn-primary float-right " (click)="salvarRespostaRapida()" *ngIf="resposta.id == ''">
            <i class="feather icon-check-circle mr-2 f-18"></i>
            Salvar
          </button>
          <button type="button" class="btn btn-info float-right " (click)="salvarRespostaRapida()" *ngIf="resposta.id != ''">
            <i class="feather icon-check-circle mr-2 f-18"></i>
            Salvar Edição
          </button>
         <div class="col-1 float-right"></div>
          <button type="button" class="btn btn-warning float-right " *ngIf="resposta.id != ''" (click)="limpar()" style="padding-right:20px;">
            <i class="feather icon-check-circle mr-2 f-18"></i>
            Descartar Alteração
          </button>
        </div>
        <!--<div fxLayout="column-reverse" fxLayoutAlign="start start" fxLayoutGap="gappx">
                
              </div><div class="col-sm-12 col-xl-10 text-right" >
                <br>
                <button type="button" class="btn btn-warning " ><i class="fa fa-arrow-left"> </i> &nbsp;
                  LIMPAR PARA ADICIONAR NOVO
                </button>&nbsp;&nbsp;&nbsp;
                <button type="button" class="btn btn-info " > ATUALIZAR &nbsp;<i
                    class="feather icon-check-circle"></i>
                </button>
              </div>-->
      </div>
    </app-card>
  </div>

</div>


<div class="row">
  <app-card cardTitle="{{arrayRespostas?.length}} Respostas Cadastrados" blockClass="table-border-style" [options]="false" class="col-md-12">
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr >
            <th>Titulo</th>
            <th>Acão</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of arrayRespostas">
            <td>{{item.titulo}}</td>
            <td>
              <button type="button" class="btn btn-icon btn-info " (click)="selecionarItem(item)">
                <i class="fa fa-edit"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </app-card>
</div>

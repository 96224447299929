import { Component, OnInit } from '@angular/core';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API, maskCNPJ, maskTelefoneCelular } from 'src/environments/environment';

@Component({
  selector: 'app-cadastro-empresa',
  templateUrl: './cadastro-empresa.component.html',
  styleUrls: ['./cadastro-empresa.component.scss']
})
export class CadastroEmpresaComponent implements OnInit {


  public appFacebook: string;
  public token: string;
  public nome: string;
  public cnpj: string;
  public telBot: string;
  public telContato: string;
  public tipoAtendimento: string;

  public empresa: any;
  public maskTelefoneCelular = maskTelefoneCelular;
  public maskCNPJ = maskCNPJ

  constructor(
    private ajax: AjaxService,
    private util: UtilService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.limpar();
    this.buscarEmpresa()
  }

 limpar(){
  this.appFacebook = "";
  this.token = "";
  this.nome = "";
  this.cnpj = "";
  this.telBot = "";
  this.telContato = "";
  this.tipoAtendimento = "";
 }

  montarObj() {
    this.auth.getUser();
    return {
      "ID_EMPRESA": this.auth.user.idEmpresa,
      "NOME": this.nome,
      "CNPJ": this.cnpj,
      "APP_FACEBOK": this.appFacebook,
      "TOKEN_FACEBOOK": this.token,
      "TELEFONE_BOT": this.telBot,
      "TEOEFONE_CONTATO": this.telContato,
      "TIPO_ATENDIMENTO": this.tipoAtendimento
    }
  }

  validar(): boolean {
    debugger
    if (this.nome == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Nome");
      return false;
    }

    if (this.util.removeMask(this.cnpj) == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo CNPJ");
      return false;
    }

    if (this.tipoAtendimento == null ||this.tipoAtendimento == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Tipo de Atendimento");
      return false;
    }

    if (this.appFacebook == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo App Facebook");
      return false;
    }

    if (this.token == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Token");
      return false;
    }

    if (this.telBot == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Número Bot");
      return false;
    }

    if (this.util.removeMask(this.telContato) == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Contato");
      return false;
    }

    return true;
  }

  salvar() {
    if (this.validar()) {
      let dados = this.montarObj();
      let url = API + "Empresa/Empresa";
      this.util.showLoading();
      this.ajax.post(url, dados)
        .subscribe((resp) => {
          debugger
          this.util.closeLoading();
          this.util.toasterShowSuccess("Sucesso", "Salvo com sucesso!");


        }, err => {
          debugger
          this.util.closeLoading();
          this.util.messageError(err);
        });
    }
  }

  buscarEmpresa() {
    this.auth.getUser();
    this.util.showLoading();
    let url = API + "Empresa/Empresa?ID_EMPRESA=" + this.auth.user.idEmpresa;
    this.ajax.get(url)
      .subscribe(response => {

        this.empresa = response.data[0];
        this.cnpj = this.empresa.cnpj;
        this.appFacebook = this.empresa.apP_FACEBOK;
        this.token = this.empresa.tokeN_FACEBOOK;
        this.nome = this.empresa.nome;
        this.telBot = this.empresa.telefonE_BOT;
        this.telContato = this.empresa.teoefonE_CONTATO;
        this.tipoAtendimento = this.empresa.tipO_ATENDIMENTO;

        console.log(this.empresa);
        this.util.closeLoading();
      }, err => {

        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao buscar Dados");
      });
  }

}

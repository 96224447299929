<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>

<div class='title'>

  <app-new-tree [items]="myTree" 
  (acaoAdd)="eventoadd($event)"
  (acaoUpdate)="eventoUpdate($event)"
  (acaoDel)="eventoDel($event)"
  ></app-new-tree>

</div>




<button type="button" name="btmodal" id="btmodal" style="display: none; width: 0px; height: 0px"
  (click)="modalChaveAcesso.show()">Launch demo modal</button>

<app-ui-modal #modalChaveAcesso [hideFooter]="true" [dialogClass]="'modal-lg'" [containerClick]="false">
  <div class="app-modal-header">
    <h5 class="modal-title titulo"> <span *ngIf="ehEdicao">Edição Fluxo: </span> ENTRE COM A INFORMAÇAO DO BOT</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="util.closeModal(modalChaveAcesso)"><span aria-hidden="true">&times;</span></button>
  </div>
  
  <div class="app-modal-body">
    <app-card cardTitle="Pergunta Pai" [options]="false" *ngIf="perguntaPai && !ehEdicao">
      <div class="row"  style="padding-left:25px;padding-right:25px;padding-bottom:15px;">
        <label>{{perguntaPai?.titulO_FLUXO}}</label>
        <textarea class="form-control " [(ngModel)]="perguntaPai.descricao" rows=5 disabled></textarea>
      </div>
    </app-card>
    
    <div class="row">
      <div class="col-md-3">
        <label>Chave</label>
        <input type="text" class="form-control " placeholder="Chave" id="cod" name="cod" [(ngModel)]="cod" 
        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
      </div>
      <div class="col-md-9 ">
        <label>Título do Fluxo (não ira aparecer no bot)</label>
        <input type="text" class="form-control " placeholder="Título" id="tituloFluxo" name="tituloFluxo" [(ngModel)]="tituloFluxo">
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 ">
        <label>Título</label>
        <input type="text" class="form-control " placeholder="Título " id="titulo" name="titulo" [(ngModel)]="titulo">
      </div>
      <div class="col-md-12" style="padding-top: 12px;">
        <div class="form-group">
          <label>Descrição</label>
          <textarea class="form-control " placeholder="Descrição" placeholder="Digite uma descrição, caso haja"
            id="descricao" name="descricao" [(ngModel)]="descricao" rows=5></textarea>
        </div>
      </div>
    </div>
    <div class="row" style="padding-top: 22px;">

      <div class="col-md-6 " align="center">
        <button type="button" class="btn  btn-info" style="padding-left: 40px; padding-right: 40px;"
          (click)="util.closeModal(modalChaveAcesso)">
          <i class="feather icon-back"></i>
          VOLTAR
        </button>
      </div>
      <div class="col-md-6 " align="center">
        <button type="button" class="btn  btn-success" style="padding-left: 40px; padding-right: 40px;"
          (click)="salvarNode(modalChaveAcesso)" *ngIf="!ehEdicao">
          <i class="feather icon-edit"></i>
          SALVAR
        </button>
        <button type="button" class="btn  btn-dark" style="padding-left: 40px; padding-right: 40px;"
          (click)="salvarEdicao(modalChaveAcesso)" *ngIf="ehEdicao">
          <i class="feather icon-edit"></i>
          SALVAR EDICAO
        </button>
      </div>

    </div>
  </div>
</app-ui-modal>


<!--<button id="modalChaveAcesso" type="button" >modal</button>-->
<!--<button type="button" name="btmodal" id="btmodal"  style="display: none; width: 0px; height: 0px"  (click)="modalChaveAcesso.show()">Launch demo modal</button>

<app-ui-modal #modalChaveAcesso [hideFooter]="true" [dialogClass]="'modal-lg'"  [containerClick]="false">
    <div class="app-modal-header">
      <h5 class="modal-title titulo">ENTRE COM A INFORMAÇAO DO BOT</h5>
      <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="util.closeModal(modalChaveAcesso)"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
      <div class="col-md-12" >
        <div class="form-group">
            <label>Título</label> 
          <input type="text" class="form-control " placeholder="Título" id="titulo" name="titulo"  [(ngModel)]="titulo" >          
        </div>
      </div>
      <div class="col-md-12" style="padding-top: 12px;">
        <div class="form-group">
         <label>Descrição</label>  
          <textarea class="form-control " placeholder="Descrição" placeholder="Digite uma descrição, caso haja" 
          id="descricao" name="descricao"  [(ngModel)]="descricao" 
           rows=5></textarea>        
        </div>
      </div>
      <div class="row" style="padding-top: 22px;">
  
        <div class="col-md-6 " align="center">
          <button type="button" class="btn  btn-info" style="padding-left: 40px; padding-right: 40px;" (click)="util.closeModal(modalChaveAcesso)">
            <i class="feather icon-edit"></i>
            VOLTAR
          </button>
        </div>
        <div class="col-md-6 " align="center">
            <button type="button" class="btn  btn-success" style="padding-left: 40px; padding-right: 40px;" (click)="salvarNode(modalChaveAcesso)">
              <i class="feather icon-edit"></i>
              SALVAR
            </button>
          </div>
  
      </div>
    </div>
  </app-ui-modal>-->

<!--<section class="tree-container">
    <h2>Angular 6 Tree Component</h2>
    <bn-ng-tree [items]="items"></bn-ng-tree>
    <div class="tree-box">
      <bn-ng-tree
        [show-checkbox]="true"
        [show-select-btn]="true"
        [show-counts]="true"
        [show-search]="true"
        [items]="items"
        [auto-check-child]="true"
        (onChange)="selectedItem($event)"
        (onChecked)="checkedItems($event)"
      ></bn-ng-tree>
    </div>
   
    <div *ngIf="currentItem" class="selected-item">
      <h2>Selected Item</h2>
      <div class="selected-box">
        <h4>Name:</h4>
        {{ currentItem.name }}
      </div>
    </div>
  </section>-->

<div class="row">
  <div class="col-md-6 col-sm-12">
    <app-card [hidHeader]="true" [options]="false">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Descricao</th>
              <th>Data Criação</th>
              <!--<th>Acão</th>-->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of arrayListas  ; let i = index"
            [ngClass]="{highlight: selectedRowIndex === i}" >
              <td (click)="selecionarLinha(item, i)">{{item.nome}} </td>
              <td (click)="selecionarLinha(item, i)">{{item.descricao}}</td>
              <td (click)="selecionarLinha(item, i)">{{item.datA_CRIACAO | date: 'dd/MM/yyyy h:mm'}}</td>
              <!--<td>
                <button type="button" class="btn btn-icon btn-danger ">
                  <i class="fa fa-trash"></i>
                </button>
              </td>-->
            </tr>
          </tbody>
        </table>
      </div>

    </app-card>
  </div>
  <div class="col-md-6 col-sm-12">
    <app-card [hidHeader]="true" [options]="false">

      <div class="row" *ngIf="arrayContatoLista?.length > 0">
        <div class="col-md-6 col-xl-6 ">
          <label class="sub-title">Nome Disparo</label>
          <input type="text" class="form-control" [(ngModel)]="nomeDisparo" name="nomeDisparo" placeholder="">
        </div>
        <div class="col-md-6 col-xl-6 ">
          <label class="sub-title">Nome Template</label>
          <!--<input type="text" class="form-control" [(ngModel)]="templateNome" name="templateNome" placeholder="Business Facebook">-->
          <!--<select class="form-control" (change)="onChangeResponstaRapida($event.target.value)">-->
            <select class="form-control" [(ngModel)]="templateNome" name="templateNome" >
              <option [value]="i.iD_TEMPLATE" *ngFor="let i of arrayTemplates">{{i.nomE_TEMPLATE}}</option>
            </select>
        </div>
        <div class="col-md-12 col-xl-12 ">
          <button type="button" style="margin-top: 26px;" class="btn btn-warning float-right " (click)="dispararEnvio()">
            <i class="feather icon-check-circle mr-2 f-18"></i>
            Disparar Envio
          </button>
        </div>

      </div>
      <div class="row ">
        <div class="col-sm-6 col-xl-6 ">
          <br>
          <label class="sub-title">Itens Lista </label>
        </div>
      </div>

      <div class="row" >
        <table class="table table-hover">
          <thead>
            <tr>
              <th></th>
              <th>Nome</th>
              <th>Telefone</th>
              <!--<th>enviado</th>-->
              <th>Acão</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of arrayContatoLista  | fullTextSearch:query">
              <td>
                <i *ngIf="item.eh_importacao" class="fas fa-cloud-upload-alt"></i>
                <i *ngIf="!item.eh_importacao"class="feather icon-user"></i>
              </td>
              <td>{{item.nome}}</td>
              <td>{{item.telefone}}</td>
              <!--<td>{{item.enviado}}</td>-->
              <td>
                <button type="button" class="btn btn-icon btn-danger ">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </app-card>
  </div>

</div>

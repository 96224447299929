import { Component, OnInit } from '@angular/core';
import { API } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { LiveChatService } from 'src/app/service/live-chat-service';
import { Router } from '@angular/router';
import { AjaxService } from 'src/app/service/ajax.service';
import { UtilService } from 'src/app/service/util.service';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-home-programa',
  templateUrl: './home-programa.component.html',
  styleUrls: ['./home-programa.component.scss']
})
export class HomeProgramaComponent implements OnInit {

  public chat: any;
  public arrayAtendentes: any[];
  public query: string;
  public parametros = { atendenteConectado: 0, atendimentoEmEspera: 0, atendimentosEmAberto: 0 }




  constructor(
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService,
    public realtime: LiveChatService
  ) {
    this.limparParametros();
  }




  ngOnInit() {

    this.buscarAtendentes();
    this.buscarAtendentesConectados();


  }

  limparParametros() {
    this.parametros = { atendenteConectado: 0, atendimentoEmEspera: 0, atendimentosEmAberto: 0 };
  }

  removeAtendentesInativos(arrayAtendente: any[]) {
    let arr = arrayAtendente.filter(function (item) {
      return item.ativo
    })
    return arr;
  }

  buscarAtendentesConectados() {
    this.auth.getUser();   
    let url = API + "Atendente/Atendente/BuscarQuantidadesAtendentes/" + this.auth.user.idEmpresa;
    return this.ajax.get(url)
      .subscribe(response => {
        console.log(response);
        this.parametros = response.data;
        this.buscarAtendimentosFila();
      }, err => {
        debugger
     
        this.util.toasterShowError("Atenção", "Erro ao Buscar quantidade de Atendentes conectados");
      });
  }

  buscarAtendimentosFila() {
    this.auth.getUser();
    //this.util.showLoading();
    let url = API + "FilaAtendimento/FilaAtendimento/buscarQtdEmAbertoEmEspera?ID_EMPRESA=" + this.auth.user.idEmpresa;
    return this.ajax.get(url)
      .subscribe(response => {
        console.log(response);
        this.parametros.atendimentosEmAberto = response.data.atendimentosEmAberto;
        this.parametros.atendimentoEmEspera = response.data.atendimentoEmEspera;
      }, err => {
        debugger
        //this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Parametros");
      });
  }

  buscarAtendentes() {
    this.auth.getUser();
    this.util.showLoading();
    let url = API + "Atendente/Atendente/BuscarAtendentes/" + this.auth.user.idEmpresa;
    return this.ajax.get(url)
      .subscribe(response => {
        this.arrayAtendentes = this.removeAtendentesInativos(response.data);
        //this.qtdProgramas = response.data.length;
        this.util.closeLoading();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Atendentes");
      });
  }

  modalAlterarStatus(item) {
    Swal.fire({
      text: 'Escolha o Novo Status',
      input: 'select',
      inputOptions: {
        '1dbe82c6-d972-437e-85c2-3f2980578a0a': 'DESCONECTADO',
        '2dbe82c6-d972-437e-85c2-3f2980578a0b': 'DISPONÍVEL',
        '3dbe82c6-d972-437e-85c2-3f2980578a0c': 'EM PAUSA'
      },
    }).then((result) => {
      if (result.value) {
        this.alterarStatus(result.value, item);
      }
    });

  }

  alterarStatus(novoStatus, item) {

    console.log(item);
    this.auth.getUser();
    console.log('ira mudar para ', novoStatus);

    let dados = {
      "guid_atendente": item.guid_atendente,
      "novo_guid_status_atendente": novoStatus
    }

    this.util.showLoading();
    let url = API + "Atendente/Atendente/AlterarStatusTrabalhoAtendente";
    return this.ajax.post(url, dados)
      .subscribe(response => {
        this.buscarAtendentes();
        this.buscarAtendentesConectados();
        this.util.closeLoading();
        this.util.toasterShowSuccess("Atenção", "Status Alterado com Sucesso");
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
      });

  }


}



<div class="row">
    <div class="col-xl-4 col-md-6">
      <app-card [hidHeader]="true" cardClass="bg-c-green" align="center">
        <h6 class="text-white"> Atendentes <br>CONECTADOS  </h6>
        <h3 class="text-white">{{parametros.atendenteConectado}}</h3>
      </app-card>
    </div>
    <div class="col-xl-4 col-md-6">
      <app-card [hidHeader]="true" cardClass="bg-c-blue" align="center">
        <h6 class="text-white"> Atendimentos <br>ABERTOS {{realtime?.response?.mensagem}}</h6>
        <h3 class="text-white">{{parametros.atendimentosEmAberto}}</h3>
      </app-card>
    </div>
    <div class="col-xl-4 col-md-6">
      <app-card [hidHeader]="true" cardClass="bg-c-yellow" align="center">
        <h6 class="text-white"> Atendimentos <br>EM ESPERA </h6>
        <h3 class="text-white">{{parametros.atendimentoEmEspera}}</h3>
      </app-card>
    </div>
  
  
    <!--<div class="col-xl-3 col-md-6">
        <app-card [hidHeader]="true" cardClass="prod-p-card bg-c-red">
          <div class="row align-items-center m-b-0">
            <div class="col">
              <h6 class="m-b-5 text-white">Total Profit</h6>
              <h3 class="m-b-0 text-white">$1,783</h3>
            </div>
            <div class="col-auto">
              <i class="fas fa-money-bill-alt text-white">dfgdf</i>
            </div>
          </div>
        </app-card>
      </div>
      <div class="col-xl-4 col-md-6">
        <app-card [hidHeader]="true" cardClass="prod-p-card bg-c-blue">
          <div class="row align-items-center m-b-0">
            <div class="col">
              <h6 class="m-b-5 text-white">Total Perguntas Cadastradas</h6>
              <h3 class="m-b-0 text-white">{{qtdPergunta}}</h3>
            </div>
            <div class="col-auto">
              <i class="fas fa-database text-white"></i>
            </div>
          </div>
        </app-card>
      </div>
      <div class="col-xl-4 col-md-6">
        <app-card [hidHeader]="true" cardClass="prod-p-card bg-c-green">
          <div class="row align-items-center m-b-0">
            <div class="col">
              <h6 class="m-b-5 text-white">Total Respostas Triagem Ativas</h6>
              <h3 class="m-b-0 text-white">{{qtdRespostas}}</h3>
            </div>
            <div class="col-auto">
              <i class="feather icon-file-text text-white"></i>
            </div>
          </div>
        </app-card>
      </div>
      <div class="col-xl-4 col-md-6">
        <app-card [hidHeader]="true" cardClass="prod-p-card bg-c-yellow">
          <div class="row align-items-center m-b-0">
            <div class="col">
              <h6 class="m-b-5 text-white">Total Programas Ativos</h6>
              <h3 class="m-b-0 text-white">{{qtdProgramas}}</h3>
            </div>
            <div class="col-auto">
              <i class="fas fa-tags text-white"></i>
            </div>
          </div>
        </app-card>
      </div>-->
  
  
  </div>
  
  <div class="row">
    <div class="col-xl-12 col-md-12">
      <app-card cardTitle="Atendentes Ativos" blockClass="scrollable-body" [options]="false">
        <perfect-scrollbar [style.max-height]="'810px'" [style.padding-top]="0">
          <div class="row ">
            <div class="col-md-6 col-sm-12 ">
              <label for="exampleInputEmail1">Filtro</label>
              <input type="text" class="form-control form-control-sm " [(ngModel)]="query"
                placeholder="Digite seu filtro, sera feito uma busca em todas as colunas">
            </div>
          </div><br>
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Alterar Status</th>
                  <th>Perfil</th>
                  <th>Grupos</th>
                  <th></th>
                  <!--<th>Acão</th>-->
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of arrayAtendentes  | fullTextSearch:query">
                  <td>{{item.nome}}</td>
                  <td>{{item.email}}</td>
                  <td>     
                    <button *ngIf="item.status == 'DESCONECTADO'" type="button" class="btn btn-icon btn-danger " (click)="modalAlterarStatus(item)">
                      {{item.status}}
                    </button> 
                    <button *ngIf="item.status == 'DISPONÍVEL'" type="button" class="btn btn-icon btn-success " (click)="modalAlterarStatus(item)">
                      {{item.status}}
                    </button>  
                    <button *ngIf="item.status == 'EM PAUSA'" type="button" class="btn btn-icon btn-warning " (click)="modalAlterarStatus(item)">
                      {{item.status}}
                    </button>             
                    <!--<span *ngIf="item.status == 'DESCONECTADO'" class="badge badge-danger status">{{item.status}}</span>
                    <span *ngIf="item.status == 'DISPONÍVEL'" class="badge badge-success status">{{item.status}}</span>
                    <span *ngIf="item.status == 'EM PAUSA'" class="badge badge-warning status">{{item.status}}</span>-->
                  </td>
                  <td>{{item.perfil}}</td>
                  <td><span [innerHTML]="item.grupos"></span></td>
                  <td>
                    <span *ngIf="item.ativo" class="badge badge-success">ATIVO</span>
                    <span *ngIf="!item.ativo" class="badge badge-danger">INATIVO</span>
                  </td>
                  <!--<td>
                    <button type="button" class="btn btn-icon btn-info ">
                      <i class="fa fa-edit"></i>
                    </button>
                  </td>-->
                </tr>
              </tbody>
            </table>
          </div>
  
          <!--<div class="row m-b-25 align-items-center" *ngFor="let item of arrayAtendentes;  let i = index">
            <div class="col-auto p-r-0">
              <i class="feather icon-file-text badge-light-success feed-icon"></i>
            </div>
            <div class="col">
              <a href="javascript:">
                <h6 class="m-b-5"> {{item.nome}}
                  <span class="text-muted float-right f-14"><label class="badge badge-light-info">{{29+i}}</label></span>
                  <span class="text-muted float-right f-14">
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp;
                  </span>
                  <span class="text-muted float-right f-14">{{item.datA_INICIO | date: "dd/MM/yyyy"}} ate
                    {{item.datA_FIM | date: "dd/MM/yyyy"}}</span>
  
  
                </h6>
              </a>
            </div>
          </div>-->
  
  
  
        </perfect-scrollbar>
      </app-card>
    </div>
  </div>
  
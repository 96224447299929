import { state } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Qrcode } from 'src/app/model/Qrcode';
import { ResponseUser } from 'src/app/model/User.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  public user: ResponseUser;
  public idQrcode: string;
  public qrcode: Qrcode;
  public tipoQrcode: string;
  public tituloQrcode: string;
  public mensagemQrcode: string;
  public urlQrcode: string;
  public exibicaoDownload: string;
  public uploadedFilePdF: File[];
  public maskTeleArea = ['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public rua: string;
  public numero: number;
  public bairro: string;
  public estado: string;
  public cidade: string;
  public cep: string;

  constructor
    (private activatedRoute: ActivatedRoute,
      private router: Router,
      private ajax: AjaxService,
      public util: UtilService,
      private auth: AuthService) { }

  ngOnInit() {
    this.idQrcode = this.activatedRoute.snapshot.params['qr'];
    this.buscarQrcode();
    this.user = this.auth.getUser();
  }

  voltar() {
    this.router.navigate(['/home']);
  }

  carregarForm() {

    this.tipoQrcode = this.qrcode.subtipo == "Comum" ? "Link" : this.qrcode.subtipo;
    this.tituloQrcode = this.qrcode.nome;

    if (this.tipoQrcode != undefined) {
      switch (this.tipoQrcode.toLowerCase()) {
        case "link":        
        case "telegram":
        case "facebook":
        case "twitter":
        case "youtube":
        case "telefone":
        case "linkedin":
        case "instagram": {
          this.urlQrcode = this.qrcode.conteudo;
          break;
        }
        case "mapa": {
          //this.urlQrcode = this.qrcode.conteudo;
          let end = this.util.getEnderecoUrlMapa(this.qrcode.conteudo);
          this.rua = end.rua;
          this.numero = end.numero;
          this.bairro = end.bairro;
          this.cidade = end.cidade;
          this.estado = end.estado;
          break;
        }
        case "whatsapp": {
          this.mensagemQrcode = this.util.getUrlParameter(this.qrcode.conteudo, 'text');
          let phone = this.util.getUrlParameter(this.qrcode.conteudo, 'phone');
          this.urlQrcode = phone.replace("+55", "");
          break;
        }
        case "texto":
        case "conteudo": {
          this.mensagemQrcode = this.qrcode.conteudo;
          break;
        }
        case "pdf":
        case "arquivo":
        case "mp3": {
          this.urlQrcode = this.qrcode.conteudo;
          this.exibicaoDownload = this.qrcode.idTipo.toUpperCase();
          break;
        }
        default: {
          //statements; 
          break;
        }
      }
    }


    console.log(this.tipoQrcode);
  }

  buscarQrcode() {
    this.util.showLoading();
    var url = API + 'QrCode/' + this.idQrcode + "/Edit";
    this.ajax.get(url)
      .subscribe((resp: Qrcode) => {
        debugger
        this.qrcode = resp;
        this.util.closeLoading();
        this.carregarForm();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.messageError(err);
      });
  }



  salvar() {

    debugger
    this.alterarQrcode();

  }

  validarFormLink() {
    if (this.tituloQrcode == undefined || this.tituloQrcode == "") {
      this.util.toasterShowError("Atenção", "Entre com o Título do QrCode");
      return false;
    }
    if (this.urlQrcode == undefined || this.urlQrcode == "") {
      this.util.toasterShowError("Atenção", "Entre com o Link ou usuário correspondente");
      return false;
    }

    if (this.tipoQrcode.toLowerCase() == "mapa") {
      if (this.rua == undefined || this.rua == "") {
        this.util.toasterShowError("Atenção", "Entre com nome da Rua");
        return false;
      }
      if (this.numero == undefined || this.numero == 0) {
        this.util.toasterShowError("Atenção", "Entre com Número");
        return false;
      }
      if (this.bairro == undefined || this.bairro == "") {
        this.util.toasterShowError("Atenção", "Entre com o Bairro");
        return false;
      }
      if (this.cidade == undefined || this.cidade == "") {
        this.util.toasterShowError("Atenção", "Entre com a Cidade");
        return false;
      }
      if (this.estado == undefined || this.estado == "") {
        this.util.toasterShowError("Atenção", "Entre com o Estado");
        return false;
      }
    }

    if (this.tipoQrcode.toLowerCase() == "whatsapp") {
      if (this.mensagemQrcode == undefined || this.mensagemQrcode == "") {
        this.util.toasterShowError("Atenção", "Entre com uma mensagem");
        return false;
      }
    }

    return true;
  }

  validarFormTextoConteudo() {
    if (this.tituloQrcode == undefined || this.tituloQrcode == "") {
      this.util.toasterShowError("Atenção", "Entre com o Título do QrCode");
      return false;
    }

    if (this.mensagemQrcode == undefined || this.mensagemQrcode == "") {
      this.util.toasterShowError("Atenção", "Entre com uma mensagem");
      return false;
    }

    return true;
  }

  alterarQrcodeTipoArquivo() {
    let urlConteudo = this.util.montarUrl(this.tipoQrcode.toLowerCase(), this.urlQrcode, this.mensagemQrcode);

    if (this.tipoQrcode.toLowerCase() == "conteudo" || this.tipoQrcode.toLowerCase() == "texto")
      urlConteudo = this.mensagemQrcode;

    let dados = {
      "idTipoQrCode": this.exibicaoDownload,
      "idSubtipoQrCode": this.qrcode.idSubtipo,
      "nome": this.tituloQrcode,
      "ativo": true
      //"conteudo": urlConteudo
    }

    let formData = this.util.jsonToFormData(dados);
    formData.append('ConteudoArquivo', this.uploadedFilePdF[0], this.uploadedFilePdF[0].name);

    let url = API + "User/" + this.user.user.guid_usuario + "/QrCode/" + this.idQrcode + "/File";
    this.util.showLoading();
    this.ajax.putFile(url, formData)
      .subscribe((resp) => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowSuccess("Sucesso", "Alteração feita com sucesso!");
        this.router.navigate(['/home']);

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", err.message);
      });

  }

  alterarQrcode() {

    if(this.tipoQrcode.toLowerCase() == "mapa")
      this.urlQrcode = this.rua + "," + this.numero + "," +this.bairro +"," +this.cidade + "," +this.estado ;

    let urlConteudo = this.util.montarUrl(this.tipoQrcode.toLowerCase(), this.urlQrcode, this.mensagemQrcode);

    if (this.tipoQrcode.toLowerCase() == "conteudo" || this.tipoQrcode.toLowerCase() == "texto")
      urlConteudo = this.mensagemQrcode;      

    let dados = {
      "idTipoQrCode": this.qrcode.idTipo,
      "idSubtipoQrCode": this.qrcode.idSubtipo,
      "ativo": true,
      "nome": this.tituloQrcode,
      "conteudo": urlConteudo
    };
    let url = API + "User/" + this.user.user.guid_usuario + "/QrCode/" + this.idQrcode;

    if (this.qrcode.subtipo.toLowerCase() == "pdf" && this.uploadedFilePdF != null) {
      debugger
      this.alterarQrcodeTipoArquivo();
    } else {
      debugger
      console.log(url);
      this.util.showLoading();
      this.ajax.put(url, dados)
        .subscribe((resp) => {
          debugger
          this.util.closeLoading();
          this.util.toasterShowSuccess("Sucesso", "Alterado com sucesso!");
          this.router.navigate(['/home']);

        }, err => {
          debugger
          this.util.closeLoading();
          this.util.messageError(err);
          //this.util.toasterShowError("Atenção", "Erro ao tentar realizar a alteração");
        });
    }
  }

}

<div class="row">
  <div class="col-md-6 col-sm-12">
    <app-card cardClass="card-datatable" [hidHeader]="true" [options]="false">

      <app-minha-tabela [endPoint]="urlMidia" [arrayCabecalho]="arrayCabecalho"
        (itemSelecionar)="receberItemVisualizar($event)">
      </app-minha-tabela>

    </app-card>
  </div>
  <div class="col-md-6 col-sm-12">
    <app-card [hidHeader]="true" [options]="false">
      <div class="row ">
        <div class="col-sm-6 col-xl-6 ">
          <label class="sub-title">Nome</label>
          <input type="text" class="form-control" [(ngModel)]="nome" name="nome" placeholder="">
        </div>
        <div class="col-sm-12 col-xl-6 ">
          <label class="sub-title">Descrição</label>
          <textarea class="form-control" [(ngModel)]="descricao" name="descricao" rows="3"></textarea>
        </div>
        <div class="col-sm-12 col-xl-12 ">
          <br>
          <button type="button" class="btn btn-warning float-right " (click)="salvarLista()">
            <i class="feather icon-download-cloud mr-2 f-18"></i>
            Salvar
          </button>
        </div>

      </div>
      <div class="row ">
        <div class="col-sm-6 col-xl-6 ">
          <label class="sub-title">Itens Selecionados </label>
        </div>
      </div>

      <div class="row">
        <table class="table table-hover">
          <thead>
            <tr>
              <th></th>
              <th>Nome</th>
              <th>Telefone</th>
              <th>Email</th>

              <th>Acão</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of arrayContatosSelecionados  | fullTextSearch:query">
              <td>
                <i *ngIf="item.eH_IMPORTADO" class="fas fa-cloud-upload-alt"></i>
                <i *ngIf="!item.eH_IMPORTADO"class="feather icon-user"></i>
              </td>
              <td>{{item.nome}}</td>
              <td>{{item.telefone}}</td>
              <td>{{item.email}}</td>


              <td>
                <button type="button" class="btn btn-icon btn-danger ">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </app-card>
  </div>

</div>

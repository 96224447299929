import { Component, OnInit } from '@angular/core';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API, maskTime } from 'src/environments/environment';

@Component({
  selector: 'app-cadastro-grupos',
  templateUrl: './cadastro-grupos.component.html',
  styleUrls: ['./cadastro-grupos.component.scss']
})
export class CadastroGruposComponent implements OnInit {

  public maskTime = maskTime;
  public eventos: any[];
  public eventoSelecionado: any;
  public eventoMensagem: {mensagem:"", iD_MENSAGEM_AUTOMATICA: ""};
  public horarios: any[];
  public resposta: any;
  public adicionarNovo: boolean;
  public query: string;
  public aba = { atendentes: false, horarios: false, respostaRapida: false, motivoEncerramento: false, mensagemAutomatica: false };
  public arrayAtendentes: any[];
  public atendenteSelecionado: any[];
  public arrayMotivoEncerramento: any[];
  public motivoEncerramentoSelecionado: any[];
  public arrayRespostasRapidas: any[];
  public respostasRapidasSelecionado: any[];
  public arrayGrupos: any[];
  public grupoSelecionado: { ID_GRUPO: "", ID_EMPRESA: "", NOME: "", DESCRICAO: "", MAX_ATENDIMENTO_SIMULTANEO: 10, PRINCIPAL: false, ATIVO: false };

  constructor(
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService
  ) {
    this.resposta = { id: "", titulo: "", descricao: "" };
    this.resetAba();
    this.limparGrupo();
    this.limparHorarios();
    this.limparEvento();
  }

  limparHorarios() {
    this.horarios = [
      { ordem: 1, chave: 'segunda', valor: { manha: { inicio: "", fim: "" }, tarde: { inicio: "", fim: "" } }, check: false },
      { ordem: 2, chave: 'terca', valor: { manha: { inicio: "", fim: "" }, tarde: { inicio: "", fim: "" } }, check: false },
      { ordem: 3, chave: 'quarta', valor: { manha: { inicio: "", fim: "" }, tarde: { inicio: "", fim: "" } }, check: false },
      { ordem: 4, chave: 'quinta', valor: { manha: { inicio: "", fim: "" }, tarde: { inicio: "", fim: "" } }, check: false },
      { ordem: 5, chave: 'sexta', valor: { manha: { inicio: "", fim: "" }, tarde: { inicio: "", fim: "" } }, check: false },
      { ordem: 6, chave: 'sabado', valor: { manha: { inicio: "", fim: "" }, tarde: { inicio: "", fim: "" } }, check: false },
      { ordem: 7, chave: 'domingo', valor: { manha: { inicio: "", fim: "" }, tarde: { inicio: "", fim: "" } }, check: false }
    ]
  }

  buscarHorariosPorGrupo() {
    this.limparHorarios();
    this.auth.getUser();
    this.util.showLoading();
    let url = API + "HorarioAtendimento/HorarioAtendimento/buscar?ID_GRUPO=" + this.grupoSelecionado.ID_GRUPO;
    this.ajax.get(url)
      .subscribe(response => {
        this.util.closeLoading();
        console.log(response.data);
        if (response.data.horarios.length > 0)
          this.horarios = response.data.horarios;

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Horarios");
      });
  }

  salvarHorarios() {
    let objSalvar = {
      "guid_grupo": this.grupoSelecionado.ID_GRUPO,
      "horarios": this.horarios
    };

    console.log(objSalvar);
    this.util.showLoading();
    let url = API + "Grupo/Grupo/vincularHorarios";
    this.ajax.post(url, objSalvar)
      .subscribe(response => {

        if (!response.hasErrors) {
          this.limparHorarios();
          this.util.toasterShowSuccess("Atenção", "Salvo com Sucesso");
          this.buscarHorariosPorGrupo();
        }
        else
          this.util.toasterShowError("Atenção", response.message);

        this.util.closeLoading();
      }, err => {

        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
      });
  }

  validaSalvarRespostaRapida(): boolean {

    if (this.resposta.titulo == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Titulo");
      return false;
    }

    if (this.resposta.descricao == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Descricao");
      return false;
    }

    return true;
  }

  montarObjSaveRespostaRApida(): any {
    this.auth.getUser();
    let objSalvar = {
      "id_empresa": this.auth.user.idEmpresa,
      "titulo": this.resposta.titulo,
      "descricao": this.resposta.descricao,
      "ID_GRUPO": this.grupoSelecionado.ID_GRUPO
    };

    if (this.resposta.id != "")
      objSalvar['ID_RESPOSTA_RAPIDA'] = this.resposta.id;

    return objSalvar;
  }

  limparRespostaRapida() {
    this.resposta = { id: "", titulo: "", descricao: "" }
  }

  salvarRespostaRapida() {
    if (this.validaSalvarRespostaRapida()) {
      let objSalvar = this.montarObjSaveRespostaRApida();
      console.log(objSalvar);
      this.util.showLoading();
      let url = API + "RespostaRapida/RespostaRapida";
      this.ajax.post(url, objSalvar)
        .subscribe(response => {

          if (!response.hasErrors) {
            this.limparRespostaRapida();
            this.util.toasterShowSuccess("Atenção", "Salvo com Sucesso");
            this.buscarRespostasRapidas();
          }
          else
            this.util.toasterShowError("Atenção", response.message);

          this.util.closeLoading();
        }, err => {

          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
        });
    }
  }

  selecionarItemRespostaRapida(item) {
    console.log(item);
    this.resposta = {
      id: item.iD_RESPOSTA_RAPIDA,
      titulo: item.titulo,
      descricao: item.descricao,

    };
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    this.buscarGrupos();
    this.adicionarNovo = false;
  }

  btAddGrupo() {
    this.adicionarNovo = true;
    this.limparGrupo();
  }

  btVoltar() {
    this.adicionarNovo = false;
    this.limparGrupo();
  }

  limparGrupo() {
    this.grupoSelecionado = { ID_GRUPO: "", ID_EMPRESA: "", NOME: "", DESCRICAO: "", MAX_ATENDIMENTO_SIMULTANEO: 10, PRINCIPAL: false, ATIVO: false };
  }

  resetAba() {
    this.aba = { atendentes: false, horarios: false, respostaRapida: false, motivoEncerramento: false, mensagemAutomatica: false };
    this.arrayAtendentes = [];
    this.atendenteSelecionado = [];
    this.arrayMotivoEncerramento = [];
    this.motivoEncerramentoSelecionado = [];
    this.arrayRespostasRapidas = [];
    this.respostasRapidasSelecionado = [];
    this.limparRespostaRapida();
  }

  selecionaAba(titulo: string) {
    if (this.grupoSelecionado.ID_GRUPO != "") {
      switch (titulo) {
        case 'atendente':
          this.aba = { atendentes: true, horarios: false, respostaRapida: false, motivoEncerramento: false, mensagemAutomatica: false };
          this.buscarAtendentes();
          break;
        case 'horarios':
          this.aba = { atendentes: false, horarios: true, respostaRapida: false, motivoEncerramento: false, mensagemAutomatica: false };
          this.buscarHorariosPorGrupo();
          break;
        case 'respostaRapida':
          this.aba = { atendentes: false, horarios: false, respostaRapida: true, motivoEncerramento: false, mensagemAutomatica: false };
          this.buscarRespostasRapidas();
          break;
        case 'motivoEncerramento':
          this.aba = { atendentes: false, horarios: false, respostaRapida: false, motivoEncerramento: true, mensagemAutomatica: false };
          this.buscarMotivoEncerramento();
          break;
        case 'mensagemAutomatica':
          this.aba = { atendentes: false, horarios: false, respostaRapida: false, motivoEncerramento: false, mensagemAutomatica: true };
          this.buscarEventos();
          this.limparEvento();
          break;
      }
    } else {
      this.util.toasterShowInfo("Atenção", "Salve um grupo Antes de continuar!");
    }

  }

  limparEvento(){
    this.eventoSelecionado = "15d7ae27-fba8-4305-bbdc-0ae433881c11";
    this.eventoMensagem = {mensagem:"", iD_MENSAGEM_AUTOMATICA: ""};
  }

  buscarMensagemEventosPersonalizado() {
    this.eventoMensagem.mensagem = "";
    this.eventoMensagem.iD_MENSAGEM_AUTOMATICA = "";
    let idEmpresa = this.auth.getUser().idEmpresa;
    this.util.showLoading();
    let url = API + `MensagemAutomatica/MensagemAutomatica?ID_EVENTO=${this.eventoSelecionado}&ID_EMPRESA=${idEmpresa}&ID_GRUPO=${this.grupoSelecionado.ID_GRUPO}`;
    this.ajax.get(url)
      .subscribe(response => {
        debugger
        if(response.data.length > 0){
          this.eventoMensagem.mensagem = response.data[0].mensagem;
          this.eventoMensagem.iD_MENSAGEM_AUTOMATICA = response.data[0].iD_MENSAGEM_AUTOMATICA;
        }else{
          this.eventoMensagem.mensagem = "";
        }
        this.util.closeLoading();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Mensagem Personalizada");
      });
  }

  salvarEventoPersonalizado(){
    let idEmpresa = this.auth.getUser().idEmpresa;
    let dados = {
      "ID_GRUPO": this.grupoSelecionado.ID_GRUPO,
      "ID_EMPRESA": idEmpresa,
      "ID_EVENTO": this.eventoSelecionado,
      "MENSAGEM": this.eventoMensagem.mensagem == "" ? " " : this.eventoMensagem.mensagem
    }

    if(this.eventoMensagem.iD_MENSAGEM_AUTOMATICA != "")
      dados["ID_MENSAGEM_AUTOMATICA"] = this.eventoMensagem.iD_MENSAGEM_AUTOMATICA

    this.util.showLoading();
    let url = API + `MensagemAutomatica/MensagemAutomatica`;
    this.ajax.post(url, dados)
      .subscribe(response => {
        this.util.toasterShowSuccess("Atenção", "Mensagem Salva com Sucesso!");
        /*if(response.data.length > 0){
          this.eventoDescricao = response.data[0].MENSAGEM;
        }*/
        //this.eventos = response.data;
        this.util.closeLoading();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Mensagem Personalizada");
      });

  }

  buscarEventos() {
    this.util.showLoading();
    let url = API + "Evento/Evento";
    this.ajax.get(url)
      .subscribe(response => {
        this.eventos = response.data;
        this.util.closeLoading();
        this.buscarMensagemEventosPersonalizado();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Eventos");
      });
  }

  desabilitarGrupo(item) {
    console.log(item);
    this.util.showLoading();
    let url = API + "Grupo/Grupo/AlterarStatusGrupo";
    this.ajax.post(url, { "ID_GRUPO": item.iD_GRUPO, "ATIVO": item.ativo })
      .subscribe(response => {

        if (!response.hasErrors) {
          this.util.toasterShowSuccess("Atenção", "Salvo com Sucesso");
        }
        else
          this.util.toasterShowError("Atenção", response.message);

        this.util.closeLoading();
      }, err => {

        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
      });

  }

  tornarPrincipal(item) {
    console.log(item);
    this.util.showLoading();
    let url = API + "Grupo/Grupo/TornarPrincipal";
    this.ajax.post(url, { "ID_GRUPO": item.iD_GRUPO, "ID_EMPRESA": item.iD_EMPRESA })
      .subscribe(response => {

        if (!response.hasErrors) {
          this.util.toasterShowSuccess("Atenção", "Salvo com Sucesso");
          this.buscarGrupos();
        }
        else
          this.util.toasterShowError("Atenção", response.message);

        this.util.closeLoading();
      }, err => {

        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
      });
  }

  selecionarItem(item) {
    this.setObjeto(item);
    window.scrollTo(0, 0);
  }

  buscarGrupos() {
    this.auth.getUser();
    this.util.showLoading();
    let url = API + "Grupo/Grupo?ID_EMPRESA=" + this.auth.user.idEmpresa;
    this.ajax.get(url)
      .subscribe(response => {
        this.arrayGrupos = response.data;
        this.util.closeLoading();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Grupos");
      });
  }

  validarGrupo(): boolean {

    if (this.grupoSelecionado.NOME == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo nome");
      return false;
    }

    if (this.grupoSelecionado.DESCRICAO == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo descricão");
      return false;
    }

    if (this.grupoSelecionado.MAX_ATENDIMENTO_SIMULTANEO <= 0) {
      this.util.toasterShowError("Atenção", "Preencha o campo Atendimentos Simultânios");
      return false;
    }

    return true;
  }



  montarObj() {
    this.auth.getUser();
    let obj = {
      "ID_EMPRESA": this.auth.user.idEmpresa,
      "NOME": this.grupoSelecionado.NOME,
      "DESCRICAO": this.grupoSelecionado.DESCRICAO,
      "MAX_ATENDIMENTO_SIMULTANEO": this.grupoSelecionado.MAX_ATENDIMENTO_SIMULTANEO,
      "PRINCIPAL": false,
      "ATIVO": true
    };


    if (this.grupoSelecionado.ID_GRUPO != "")
      obj['ID_GRUPO'] = this.grupoSelecionado.ID_GRUPO;

    return obj;

  }

  setObjeto(obj) {
    this.resetAba();
    this.adicionarNovo = true;
    this.grupoSelecionado.ID_GRUPO = obj.iD_GRUPO;
    this.grupoSelecionado.ATIVO = obj.ativo;
    this.grupoSelecionado.DESCRICAO = obj.descricao;
    this.grupoSelecionado.ID_EMPRESA = obj.iD_EMPRESA;
    this.grupoSelecionado.MAX_ATENDIMENTO_SIMULTANEO = obj.maX_ATENDIMENTO_SIMULTANEO;
    this.grupoSelecionado.NOME = obj.nome;
    this.grupoSelecionado.PRINCIPAL = obj.principal;
  }

  salvarGrupo() {
    if (this.validarGrupo()) {
      let objSave = this.montarObj();
      console.log(objSave);
      this.util.showLoading();
      let url = API + "Grupo/Grupo";
      this.ajax.post(url, objSave)
        .subscribe(response => {

          if (!response.hasErrors) {
            this.util.toasterShowSuccess("Atenção", "Grupo Salvo com Sucesso");
            //this.setObjeto(response.data);
            this.limparGrupo();
            this.buscarGrupos();
          } else
            this.util.toasterShowError("Atenção", "Erro ao salvar Grupo");

          this.util.closeLoading();

        }, err => {
          debugger
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao salvar Grupo");
        });
    }
  }

  buscarAtendentes() {
    this.auth.getUser();
    this.util.showLoading();
    let url = API + "Atendente/Atendente/BuscarAtendentes/" + this.auth.user.idEmpresa;
    this.ajax.get(url)
      .subscribe(response => {
        this.arrayAtendentes = this.util.convertIoptions(response.data, "guid_atendente", "nome");
        console.log(this.arrayAtendentes);
        this.util.closeLoading();
        this.buscarAtendentesPorGrupos();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Atendentes");
      });
  }

  atualizarAtendentes() {
    console.log(this.atendenteSelecionado);
    this.util.showLoading();
    let url = API + "Grupo/Grupo/vincularAtendentes";
    this.ajax.post(url, { "guid_grupo": this.grupoSelecionado.ID_GRUPO, "guid_atendentes": this.atendenteSelecionado })
      .subscribe(response => {

        if (!response.hasErrors) {
          this.util.toasterShowSuccess("Atenção", "Salvo com Sucesso");
          this.buscarGrupos();
        }
        else
          this.util.toasterShowError("Atenção", response.message);

        this.util.closeLoading();
      }, err => {

        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
      });
  }

  buscarAtendentesPorGrupos() {
    this.auth.getUser();
    this.util.showLoading();
    let url = API + "GrupoAtendente/GrupoAtendente?ID_GRUPO=" + this.grupoSelecionado.ID_GRUPO;
    this.ajax.get(url)
      .subscribe(response => {
        console.log('relacionamento', response.data);
        this.ajusteAtendentesSelecionados(response.data);
        this.util.closeLoading();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Atendimentos");
      });
  }

  ajusteAtendentesSelecionados(arraySelecionados: any[]) {
    let ajustado = [];
    arraySelecionados.forEach(item => {
      ajustado.push(item.iD_ATENDENTE);
    });
    this.atendenteSelecionado = ajustado;
  }

  buscarMotivoEncerramento() {
    this.auth.getUser();
    this.util.showLoading();
    let url = API + "MotivoEncerramento/MotivoEncerramento?ID_EMPRESA=" + this.auth.user.idEmpresa;
    this.ajax.get(url)
      .subscribe(response => {
        this.arrayMotivoEncerramento = this.util.convertIoptions(response.data, "iD_MOTIVO_ENCERRAMENTO", "titulo");
        console.log(this.arrayMotivoEncerramento);
        this.util.closeLoading();
        this.buscarMotivosPorGrupos();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Motivos de Encerramento");
      });
  }

  atualizarMotivosEncerramento() {
    console.log(this.atendenteSelecionado);
    this.util.showLoading();
    let url = API + "Grupo/Grupo/vincularMotivoEncerramento";
    this.ajax.post(url, { "guid_grupo": this.grupoSelecionado.ID_GRUPO, "guid_motivos": this.motivoEncerramentoSelecionado })
      .subscribe(response => {

        if (!response.hasErrors) {
          this.util.toasterShowSuccess("Atenção", "Salvo com Sucesso");
          this.buscarGrupos();
        }
        else
          this.util.toasterShowError("Atenção", response.message);

        this.util.closeLoading();
      }, err => {

        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
      });
  }

  buscarMotivosPorGrupos() {
    this.auth.getUser();
    this.util.showLoading();
    let url = API + "MotivoEncerramentoGrupo/MotivoEncerramentoGrupo?ID_GRUPO=" + this.grupoSelecionado.ID_GRUPO;
    this.ajax.get(url)
      .subscribe(response => {
        this.ajusteMotivosSelecionados(response.data);
        this.util.closeLoading();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Motivos de Encerramento");
      });
  }

  ajusteMotivosSelecionados(arraySelecionados: any[]) {
    let ajustado = [];
    arraySelecionados.forEach(item => {
      ajustado.push(item.iD_MOTIVO_ENCERRAMENTO);
    });
    this.motivoEncerramentoSelecionado = ajustado;
  }





  buscarRespostasRapidas() {
    this.auth.getUser();
    this.util.showLoading();
    let url = API + "RespostaRapida/RespostaRapida?ID_EMPRESA=" + this.auth.user.idEmpresa + "&ID_GRUPO=" + this.grupoSelecionado.ID_GRUPO;
    this.ajax.get(url)
      .subscribe(response => {
        this.arrayRespostasRapidas = response.data;
        this.util.closeLoading();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Respostas Rápidas");
      });
  }




}


import { EventEmitter, Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { API } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})

/*
  referencia
  https://github.com/alefFSilva/live-chat-angular-net-signalR
*/
export class LiveChatService {


  public onConnectionSuccessfully: EventEmitter<any>;
  public novaMensagemRecebida: EventEmitter<any>;

  private _hubConnection: signalR.HubConnection;
  private _currentUserName: string;
  public user = { key: "", name: "", dtConnection: null, guid_empresa: "" };
  public api_hub = API.replace("/api/", "");
  public response: any;

  constructor() {

    this.novaMensagemRecebida = new EventEmitter();
    this.onConnectionSuccessfully = new EventEmitter();
    this._currentUserName = '';
    this.limparUser();


  }

  public get CurrentUserName(): string {
    return this._currentUserName;
  }

  loadUser(user) {
    this.user.dtConnection = new Date();
    this.user.key = user.user.guid_usuario;
    this.user.name = user.user.nome;
    this.user.guid_empresa = user.user.guid_empresa;
  }

  private limparUser() {
    this.user = { key: "", name: "", dtConnection: null, guid_empresa: "" };
  }

  public async initializeNewUserConnectionAsync(user: any): Promise<void> {
   
      this.loadUser(user);
      this._currentUserName = this.user.name;
      this._hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(this.api_hub + "/chat?user=" + JSON.stringify(this.user))
        .build();


      this.onReceiveMessage();

      return this._hubConnection.start().then(() => {
        this.onConnectionSuccessfully.emit(this._hubConnection);
        this._hubConnection.send('OnEnterChatAsync', this.CurrentUserName);
      });
    
  }

  public leaveChatAsync(): Promise<void> {
    return this._hubConnection.send('OnExitChatAsync', this.CurrentUserName)
      .then(() => {
        this._hubConnection.stop();
      });
  }

  public sendNewMessage(message: string): void {
    this._hubConnection.send('OnNewMessageAsync', this.CurrentUserName, message);
  }

  public setUserName(name: string): void {
    localStorage.setItem('username', name);
  }

  onReceiveMessage() {
    this._hubConnection.on("Receive", (sender, message) => {
      //console.log("recebido no service", message, sender);
      this.novaMensagemRecebida.emit(sender);
      this.response = sender;
    });
  }

}

import { CadastroTemplateModeloComponent } from './page/cadastro-template-modelo/cadastro-template-modelo.component';
import { DisparoVerificarComponent } from './page/disparo-verificar/disparo-verificar.component';
import { DispararListaComponent } from './page/disparar-lista/disparar-lista.component';
import { CadastroListaComponent } from './page/cadastro-lista/cadastro-lista.component';

import { HomeProgramaComponent } from './page/home-programa/home-programa.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './page/login/login.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { LoggedInGuard } from './page/security/loggedin.quard';
import { HomeComponent } from './page/home/home.component';
import { CadastroUsuarioComponent } from './page/cadastro-usuario/cadastro-usuario.component';
import { AvisoComponent } from './page/aviso/aviso.component';
import { TreeComponent } from './page/tree/tree.component';
import { DrawFlowComponent } from './page/draw-flow/draw-flow.component';
import { FluxoDrawComponent } from './page/fluxo-draw/fluxo-draw.component';
import { CadastroAtendenteComponent } from './page/cadastro-atendente/cadastro-atendente.component';
import { MotivoEncerramentoComponent } from './page/motivo-encerramento/motivo-encerramento.component';
import { RespostasRapidasComponent } from './page/respostas-rapidas/respostas-rapidas.component';
import { CadastroGruposComponent } from './page/cadastro-grupos/cadastro-grupos.component';
import { ImportarContatoComponent } from './page/importar-contato/importar-contato.component';
import { ContatoComponent } from './page/contato/contato.component';
import { FilaAtendimentoComponent } from './page/fila-atendimento/fila-atendimento.component';
import { CadastroEmpresaComponent } from './page/cadastro-empresa/cadastro-empresa.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'fila-atendimento',
        pathMatch: 'full'
      },
      {
        path: 'sample-page',
        loadChildren: () => import('./demo/pages/sample-page/sample-page.module').then(module => module.SamplePageModule)
      }
      , { path: 'tree', component: TreeComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'cadastro-atendente', component: CadastroAtendenteComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'cadastro-empresa', component: CadastroEmpresaComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'resposta-rapida', component: RespostasRapidasComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'motivo-encerramento', component: MotivoEncerramentoComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'cadastro-grupo', component: CadastroGruposComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'disparo-verificar', component: DisparoVerificarComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'cadastro-lista', component: CadastroListaComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'disparar-lista', component: DispararListaComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'cadastro-template-modelo', component: CadastroTemplateModeloComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'avisos', component: AvisoComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'contato', component: ContatoComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'importar-contato', component: ImportarContatoComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'fila-atendimento', component: FilaAtendimentoComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'home', component: HomeComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'home-p', component: HomeProgramaComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
    ]
  }
  ,
  {
    path: '',
    component: AuthComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'cadastro-usuario', component: CadastroUsuarioComponent },
      /*{ path: 'draw', component: DrawFlowComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },*/
      { path: 'fluxo-draw', component: FluxoDrawComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
    ]
  }/*,
  {
    path: '',
    component: AuthComponent,
    children: []
  }*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

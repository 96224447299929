import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';

@Component({
  selector: 'app-aviso',
  templateUrl: './aviso.component.html',
  styleUrls: ['./aviso.component.scss']
})
export class AvisoComponent implements OnInit {

  public titulo: string;
  public descricao: string;
  public dataEvento: string;
  public status: boolean;
  public avisoSelecionado: any;

  public arrayAvisos: any;
  
  constructor(
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService
  ) { 
    this.status = true;
  }

  ngOnInit() {
    this.buscar();
  }

  buscar() {
    this.util.showLoading();
    let url = API + "Aviso/Aviso";
    return this.ajax.get(url)
      .subscribe(response => {
        this.arrayAvisos = response.data;
        console.log(this.arrayAvisos);
        this.util.closeLoading();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Avisos");
      });
  }

  valida() {
    if (this.titulo == undefined || this.titulo == "") {
      this.util.toasterShowError("Atenção", "Entre com o Título");
      return false;
    }

    if (this.descricao == undefined || this.descricao == "") {
      this.util.toasterShowError("Atenção", "Entre com o Título");
      return false;
    }

    if (this.dataEvento == undefined || this.dataEvento == "") {
      this.util.toasterShowError("Atenção", "Entre com a Data do Evento");
      return false;
    }



    return true;

  }

  limpar() {
    this.titulo = "";
    this.dataEvento = "";
    this.descricao = "";
    this.status = true;
    this.avisoSelecionado = null;
  }

  alterar() {

    if (this.valida()) {

      debugger
      let dados = {
        "ID_AVISO": this.avisoSelecionado.iD_AVISO,
        "TITULO": this.titulo,
        "DESCRICAO": this.descricao,
        "DATA_EVENTO": this.dataEvento,
        "STATUS": this.status
      }
      let url = API + "Aviso/Aviso";
      return this.ajax.post(url, dados)
        .subscribe(response => {

          this.util.closeLoading();
          this.util.toasterShowSuccess("Atenção", "Aviso Salvo com Sucesso");
          this.limpar();
          this.buscar();
        }, err => {
          debugger
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Salvar Aviso");
        });

    }

  }

  salvar() {

    if (this.valida()) {

      debugger
      let dados = {
        "TITULO": this.titulo,
        "DESCRICAO": this.descricao,
        "DATA_EVENTO": this.util.formatDateSQL(this.dataEvento),
        "STATUS": this.status
      }
      let url = API + "Aviso/Aviso";
      return this.ajax.post(url, dados)
        .subscribe(response => {

          this.util.closeLoading();
          this.util.toasterShowSuccess("Atenção", "Aviso Salvo com Sucesso");
          this.limpar();
          this.buscar();
        }, err => {
          debugger
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Salvar Aviso");
        });

    }

  }

  carregarItem(item){
    this.avisoSelecionado = item;

    this.titulo = item.titulo;
    this.dataEvento = item.datA_EVENTO.split('T')[0];
    this.status = item.status;
    this.descricao = item.descricao;

  }

  desabilitar(id: string) {

    debugger
    let dados = {
      "id": id
    }
    let url = API + "Aviso/Aviso/Desabilitar/" + id;
    return this.ajax.post(url, dados)
      .subscribe(response => {

        this.util.closeLoading();
        this.util.toasterShowSuccess("Atenção", "Aviso Desabilitado com Sucesso");
        
        this.buscar();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Aviso");
      });

  }

}

import { formatDate } from "@angular/common";
import { AuthService } from "src/app/service/auth.service";
import { Component, Input, OnInit } from "@angular/core";
import { UtilService } from "src/app/service/util.service";
import { AjaxService } from "src/app/service/ajax.service";
import { NavigationExtras, Router } from "@angular/router";
import { ResponseUser } from "src/app/model/User.model";
import { API, API_QRCODE, TIPOS } from "src/environments/environment";
import "sweetalert2/src/sweetalert2.scss";
import Swal from "sweetalert2";
import { LiveChatService } from "src/app/service/live-chat-service";
import * as signalR from "@microsoft/signalr";

declare var $: any;

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  public chat: any;
  public arrayAtendentes: any[];
  public queryErro: string;
  public queryAcerto: string;
  public queryErroGeral: string;
  public queryErroJaInserida: string;


  public parametros = {
    atendenteConectado: 0,
    atendimentoEmEspera: 0,
    atendimentosEmAberto: 0,
  };

  public dataInicio = null;
  public dataFim = null;

  public erros = [];
  public errosOcoJaInserida = [];
  public errosGerais = [];
  public errosAgrupados = [];
  public acertos = [];

  constructor(
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService,
    public realtime: LiveChatService
  ) {
    this.limpar();
  }


  buscar() {
    this.limpar();
    if (this.dataInicio != null && this.dataFim != null) {
      this.buscarErros();
      this.buscarErrosAgrupados();
      this.buscarErrosAcertos();
    } else {
      this.util.SwalWarning(
        "Atenção",
        "Preencha os periodos para realizar a busca"
      );
    }
  }



  ngOnInit() {
    this.dataInicio = this.util.formatDateSQL(new Date().toString());
    this.dataFim = this.util.formatDateSQL(new Date().toString());
    this.buscar();
  }

  separandoErros(data) {
    console.log("separando erros", data);

    data.forEach(element => {    

        let aux = JSON.parse(element.retorno);

        if(aux.status != 0){
          if(aux.data['0'].messages != undefined && aux.data['0'].messages?.length > 0){
            if(aux.data['0'].messages[0].codigo == 1)
              this.errosOcoJaInserida.push(element);
            else
              this.errosGerais.push(element);
          }else{
            this.erros.push(element);
          }
        }else{
          this.errosGerais.push(element);
        }
        


    });

  }

  buscarErros() {
    this.util.showLoading();
    this.auth.getUser();
    let url =
      API +
      `Report/ReportMensagens/buscarErros?inicial=${this.dataInicio}&final=${this.dataFim}`;
    return this.ajax.get(url).subscribe(
      (response) => {
        console.log(response);
        this.separandoErros(response.data);
        //this.erros = response.data;
        this.util.closeLoading();
      },
      (err) => {
        debugger;
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar informaçoes");
      }
    );
  }

  buscarErrosAgrupados() {
    this.util.showLoading();
    this.auth.getUser();
    let url =
      API +
      `Report/ReportMensagens/buscarErrosAgrupados?inicial=${this.dataInicio}&final=${this.dataFim}`;
    return this.ajax.get(url).subscribe(
      (response) => {
        console.log(response);
        this.errosAgrupados = response.data;
        this.util.closeLoading();
      },
      (err) => {
        debugger;
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar informaçoes");
      }
    );
  }

  buscarErrosAcertos() {
    this.util.showLoading();
    this.auth.getUser();
    let url =
      API +
      `Report/ReportMensagens/buscarAcertos?inicial=${this.dataInicio}&final=${this.dataFim}`;
    return this.ajax.get(url).subscribe(
      (response) => {
        console.log(response);
        this.acertos = response.data;
        this.util.closeLoading();
      },
      (err) => {
        debugger;
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar informaçoes");
      }
    );
  }

  limpar() {
    this.erros = [];
     this.errosOcoJaInserida = [];
     this.errosGerais = [];
     this.errosAgrupados = [];
     this.acertos = [];
  }

  removeAtendentesInativos(arrayAtendente: any[]) {
    let arr = arrayAtendente.filter(function (item) {
      return item.ativo;
    });
    return arr;
  }

  buscarAtendentesConectados() {
    this.auth.getUser();
    let url =
      API +
      "Atendente/Atendente/BuscarQuantidadesAtendentes/" +
      this.auth.user.idEmpresa;
    return this.ajax.get(url).subscribe(
      (response) => {
        console.log(response);
        this.parametros = response.data;
        this.buscarAtendimentosFila();
      },
      (err) => {
        debugger;

        this.util.toasterShowError(
          "Atenção",
          "Erro ao Buscar quantidade de Atendentes conectados"
        );
      }
    );
  }

  buscarAtendimentosFila() {
    this.auth.getUser();
    //this.util.showLoading();
    let url =
      API +
      "FilaAtendimento/FilaAtendimento/buscarQtdEmAbertoEmEspera?ID_EMPRESA=" +
      this.auth.user.idEmpresa;
    return this.ajax.get(url).subscribe(
      (response) => {
        console.log(response);
        this.parametros.atendimentosEmAberto =
          response.data.atendimentosEmAberto;
        this.parametros.atendimentoEmEspera = response.data.atendimentoEmEspera;
      },
      (err) => {
        debugger;
        //this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Parametros");
      }
    );
  }

  buscarAtendentes() {
    this.auth.getUser();
    this.util.showLoading();
    let url =
      API + "Atendente/Atendente/BuscarAtendentes/" + this.auth.user.idEmpresa;
    return this.ajax.get(url).subscribe(
      (response) => {
        this.arrayAtendentes = this.removeAtendentesInativos(response.data);
        //this.qtdProgramas = response.data.length;
        this.util.closeLoading();
      },
      (err) => {
        debugger;
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Atendentes");
      }
    );
  }

  modalAlterarStatus(item) {
    Swal.fire({
      title: "Retorno vindo do Brudam <br> Minuta: " + item.minuta,
      html: "<code>" + item.retorno + "</code>",
      type: "warning",
      width: 1500,
      showCloseButton: true,
      showCancelButton: true,
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
      }
    });
  }

  alterarStatus(novoStatus, item) {
    console.log(item);
    this.auth.getUser();
    console.log("ira mudar para ", novoStatus);

    let dados = {
      guid_atendente: item.guid_atendente,
      novo_guid_status_atendente: novoStatus,
    };

    this.util.showLoading();
    let url = API + "Atendente/Atendente/AlterarStatusTrabalhoAtendente";
    return this.ajax.post(url, dados).subscribe(
      (response) => {
        this.buscarAtendentes();
        this.buscarAtendentesConectados();
        this.util.closeLoading();
        this.util.toasterShowSuccess("Atenção", "Status Alterado com Sucesso");
      },
      (err) => {
        debugger;
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
      }
    );
  }
}

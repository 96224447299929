import { Component, OnInit } from '@angular/core';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';

@Component({
  selector: 'app-cadastro-template-modelo',
  templateUrl: './cadastro-template-modelo.component.html',
  styleUrls: ['./cadastro-template-modelo.component.scss']
})
export class CadastroTemplateModeloComponent implements OnInit {


  public template: any;
  public arrayTemplates: any[];
  public query: string;

  constructor(
    private ajax: AjaxService,
    private util: UtilService,
    private auth: AuthService
  ) {
    this.template = { id: "", nome: "",  linguagem: "pt_BR" };
    this.auth.getUser();
  }

  ngOnInit() {
    this.buscarTemplates();
  }

  validaSalvar(): boolean {

    if (this.template.nome == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo nome");
      return false;
    }

    return true;
  }

  montarObjSave(): any {
    this.auth.getUser();
    let objSalvar = {
      "iD_EMPRESA": this.auth.user.idEmpresa,
      "nomE_TEMPLATE": this.template.nome,
      "linguagem": this.template.linguagem,

    };

    if(this.template.id != "")
      objSalvar['iD_TEMPLATE'] = this.template.id;

    return objSalvar;
  }

  limpar() {
    this.template = { id: "", nome: "",  linguagem: "pt_BR" };
  }

  salvarTemplate() {
    debugger
    if (this.validaSalvar()) {
      let objSalvar = this.montarObjSave();
      this.util.showLoading();
      let url = API + "TemplateModelo/TemplateModelo";
      this.ajax.post(url, objSalvar)
        .subscribe(response => {

          if (!response.hasErrors) {
            this.limpar();
            this.util.toasterShowSuccess("Atenção", "Salvo com Sucesso");
            this.buscarTemplates();
          }
          else
            this.util.toasterShowError("Atenção", response.message);

          this.util.closeLoading();
        }, err => {

          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
        });
    }
  }


  buscarTemplates() {
    this.auth.getUser();
    this.util.showLoading();
    let url = API + "TemplateModelo/TemplateModelo?ID_EMPRESA=" + this.auth.user.idEmpresa;
    this.ajax.get(url)
      .subscribe(response => {

        this.arrayTemplates = response.data;
        this.util.closeLoading();
      }, err => {

        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao buscar Dados");
      });
  }

  selecionarItem(item){
    console.log(item);
    this.template = {
      id: item.iD_TEMPLATE,
      nome: item.nomE_TEMPLATE,
      linguagem: item.linguagem

    };
    window.scrollTo(0, 0);
  }

}

<ul class="tree-view">
  <li class="system-main" *ngFor="let item of items">
    <div class="system-main__apps row">
      <div class="col-8" (click)="onexpand(item)">
        <span *ngIf="item.expanded" class="system-main__icon"><i class="fa fa-minus-square"></i></span>
        <span *ngIf="!item.expanded" class="system-main__icon"><i class="fa fa-plus-square"></i></span>
        <span class="system-main__name"> <span *ngIf="item.cod">{{ item.cod }} - </span> {{ item.titulO_FLUXO }} </span>
      </div>
      <div class="col-4">
        <span class="float-right">
          <i (click)="add(item)" class="fa fa-plus" style="padding:12px; border-left: 1px solid #999;"></i>
          <i (click)="update(item)" class="fa fa-edit" style="padding:12px; border-left: 1px solid #999;"></i>
          <i (click)="remove(item)" class="fa fa-trash" style="padding:12px; border-left: 1px solid #999; border-right: 1px solid #999;"></i>
        </span>
      </div>
    </div>

    <ul *ngIf="item.childrens && item.expanded">
      <app-new-tree tree [items]="item.childrens"  
        (acaoAdd)="eventoadd($event)" 
        (acaoUpdate)="eventoUpdate($event)"
        (acaoDel)="eventoDel($event)"
      ></app-new-tree>
    </ul>
  </li>
</ul>



<!--<button type="button" name="btmodal" id="btmodal"  style="display: none; width: 0px; height: 0px"  (click)="modalChaveAcesso.show()">Launch demo modal</button>

<app-ui-modal #modalChaveAcesso [hideFooter]="true" [dialogClass]="'modal-lg'"  [containerClick]="false">
    <div class="app-modal-header">
      <h5 class="modal-title titulo">ENTRE COM A INFORMAÇAO DO BOT</h5>
      <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="util.closeModal(modalChaveAcesso)"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="col-md-12" >    
            </div>
            <input type="text" hide class="form-control " id="selecionado" name="selecionado"  [(ngModel)]="selecionado" >  
      <div class="col-md-12" >
        <div class="form-group">
            <label>Título</label> 
          <input type="text" class="form-control " placeholder="Título" id="titulo" name="titulo"  [(ngModel)]="titulo" >          
        </div>
      </div>
      <div class="col-md-12" style="padding-top: 12px;">
        <div class="form-group">
         <label>Descrição</label>  
          <textarea class="form-control " placeholder="Descrição" placeholder="Digite uma descrição, caso haja" 
          id="descricao" name="descricao"  [(ngModel)]="descricao" 
           rows=5></textarea>        
        </div>
      </div>
      <div class="row" style="padding-top: 22px;">
  
        <div class="col-md-6 " align="center">
          <button type="button" class="btn  btn-info" style="padding-left: 40px; padding-right: 40px;" (click)="util.closeModal(modalChaveAcesso)">
            <i class="feather icon-edit"></i>
            VOLTAR
          </button>
        </div>
        <div class="col-md-6 " align="center">
            <button type="button" class="btn  btn-success" style="padding-left: 40px; padding-right: 40px;" (click)="salvarNode(modalChaveAcesso)">
              <i class="feather icon-edit"></i>
              SALVAR
            </button>
          </div>
  
      </div>
    </div>
  </app-ui-modal>-->

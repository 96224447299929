<div class="row">
  <div class="col-md-6 col-sm-12">
    <app-card [hidHeader]="true" [options]="false">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Nome Disparo</th>
              <th>Nome Template</th>
              <th></th>
              <th>Data Criação</th>
              <!--<th>Acão</th>-->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of arrayEnvio  ; let i = index"
            [ngClass]="{highlight: selectedRowIndex === i}" >
              <td (click)="selecionarLinha(item, i)">{{item.nomE_DISPARO}} </td>
              <td (click)="selecionarLinha(item, i)">{{item.nomE_TEMPLATE}}</td>
              <td (click)="selecionarLinha(item, i)">{{item.linguagem}}</td>
              <td (click)="selecionarLinha(item, i)">{{item.datA_CRIACAO | date: 'dd/MM/yyyy h:mm'}}</td>
              <!--<td>
                <button type="button" class="btn btn-icon btn-danger ">
                  <i class="fa fa-trash"></i>
                </button>
              </td>-->
            </tr>
          </tbody>
        </table>
      </div>

    </app-card>
  </div>
  <div class="col-md-6 col-sm-12">
    <app-card [hidHeader]="true" [options]="false">
      <div class="row ">
        <div class="col-sm-6 col-xl-6 ">
          <br>
          <label class="sub-title">Itens Lista </label>
        </div>
      </div>

      <div class="row" >
        <table class="table table-hover">
          <thead>
            <tr>
              <th></th>
              <th>Nome</th>
              <th>Telefone</th>
              <th>enviado</th>

            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of arrayEnvioItens">
              <td>
                <i *ngIf="item.eh_importacao" class="fas fa-cloud-upload-alt"></i>
                <i *ngIf="!item.eh_importacao"class="feather icon-user"></i>
              </td>
              <td>{{item.nome}}</td>
              <td>{{item.telefone}}</td>

              <td>
                <label *ngIf="!item.jA_ENVIADA" class="badge badge-light-warning">Pendente</label>
                <label *ngIf="item.jA_ENVIADA" class="badge badge-light-success">Enviado</label>

              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </app-card>
  </div>

</div>

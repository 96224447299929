<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>

<div class="draw-flow-poc">
  <!--<header>
    <h2>Crie seu Fluxo</h2>
  </header>-->
  
  <div class="wrapper">
   
    <div class="col" style="text-align: center">
      <br>
      <img  id="main-logo" src="assets/images/logo/2.png"  width="80%" class="logo">
      <br><br>
      <legend style="border-bottom: 1px solid #41b2b5;"></legend>
      <br>
      <ng-container *ngFor="let node of nodes">
        <div class="drag-drawflow" draggable="true" (dragstart)="onDragStart($event)">
          <i class="fas fa-code-branch"></i><span> </span>{{ node.name }}
        </div>
        <div>
          <br><br>
          <button class="btn btn-block btn-info mb-4" (click)="voltar()">
            <i class="fa fa-arrow-left"></i>
            Voltar
          </button>
        </div>
        <hr />
      </ng-container>
     
    </div>
    <div class="col-right">
      <div id="drawflow" (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)"
        (dragover)="onDragOver($event)" (dragend)="onDragEnd($event)" (drop)="onDrop($event)">
        <!--<div class="btn-submit" (click)="onSubmit()">Submit</div>
          <div class="btn-clear" (click)="onClear()">Clear</div>-->
        <div class="btn-lock">
          <button (click)="changeMode()" [ngStyle]="{ display: locked ? 'none' : 'block' }" class="btn-primary">
            <i class="fas fa-lock"></i> Lock
          </button>
          <button (click)="changeMode()" [ngStyle]="{ display: locked ? 'block' : 'none' }" class="btn-primary">
            <i class="fas fa-unlock"></i> Unlock
          </button>
        </div>
        <div class="bar-zoom">
          <button (click)="onZoomOut()" class="btn-primary"><i class="fas fa-search-minus"></i></button >
          <!--<button (click)="onZoomReset()">Reset</button>-->
          <button (click)="onZoomIn()" class="btn-primary"> <i class="fas fa-search-plus"></i></button>
        </div>
      </div>
    </div>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';

@Component({
  selector: 'app-motivo-encerramento',
  templateUrl: './motivo-encerramento.component.html',
  styleUrls: ['./motivo-encerramento.component.scss']
})
export class MotivoEncerramentoComponent implements OnInit {

  public motivo: any;
  public arrayMotivos: any[];

  constructor(
    private ajax: AjaxService,
    private util: UtilService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.motivo = {id: "", titulo: "",   descricao: ""};
    this.buscarMotivos();
  }
  
  validaSalvar(): boolean {

    if (this.motivo.titulo == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Titulo");
      return false;
    } 
    
    if (this.motivo.descricao == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Descricao");
      return false;
    } 

    return true;
  }


  montarObjSave(): any {
    this.auth.getUser();
    let objSalvar = {
      "id_empresa": this.auth.user.idEmpresa,
      "titulo": this.motivo.titulo,
      "descricao": this.motivo.descricao
    };

    if(this.motivo.id != "")
      objSalvar['ID_MOTIVO_ENCERRAMENTO'] = this.motivo.id;

    return objSalvar;
  }

  limpar() {
    this.motivo = {id: "", titulo: "",   descricao: ""}
  }

  salvarMotivoEncerramento() {
    if (this.validaSalvar()) {
      let objSalvar = this.montarObjSave();
      console.log(objSalvar);
      this.util.showLoading();
      let url = API + "MotivoEncerramento/MotivoEncerramento";
      this.ajax.post(url, objSalvar)
        .subscribe(response => {

          if (!response.hasErrors) {
            this.limpar();
            this.util.toasterShowSuccess("Atenção", "Salvo com Sucesso");
            this.buscarMotivos();
          }
          else
            this.util.toasterShowError("Atenção", response.message);

          this.util.closeLoading();
        }, err => {

          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
        });
    }
  }


  buscarMotivos() {
    this.auth.getUser();
    this.util.showLoading();
    let url = API + "MotivoEncerramento/MotivoEncerramento?ID_EMPRESA=" + this.auth.user.idEmpresa;
    this.ajax.get(url)
      .subscribe(response => {

        this.arrayMotivos = response.data;
        this.util.closeLoading();
      }, err => {

        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao buscar Dados");
      });
  }

  
  selecionarItem(item){
    console.log(item);
    this.motivo = { 
      id: item.iD_MOTIVO_ENCERRAMENTO, 
      titulo: item.titulo, 
      descricao: item.descricao,  

    };
    window.scrollTo(0, 0);
  }

}

import { Component, OnInit } from '@angular/core';
import { CabecalhoTabela } from 'src/app/model/CabecalhoTabela';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';

@Component({
  selector: 'app-cadastro-lista',
  templateUrl: './cadastro-lista.component.html',
  styleUrls: ['./cadastro-lista.component.scss']
})
export class CadastroListaComponent implements OnInit {

  public arrayCabecalho: CabecalhoTabela[];
  public urlMidia: string;

  public nome: string;
  public descricao: string;

  public query: string;
  public arrayTipoMidias: any[];
  public arrayContatosSelecionados: any[];

  constructor(
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService
  ) {
    this.arrayTipoMidias = [];
    this.arrayContatosSelecionados = [];

    this.auth.getUser();
    this.urlMidia = "Contato/Contato?ID_EMPRESA=" + this.auth.user.idEmpresa;

    this.limpar();
    this.arrayCabecalho = [
      { atributo: 'nome', label: 'NOME', tamanho: '2', acao: false, align: "left" },
      { atributo: 'telefone', label: 'TELEFONE', tamanho: '1', acao: false, align: "left" },
      { atributo: 'observacao', label: 'OBS', tamanho: '1', acao: false, align: "left" },
      { atributo: 'importacao', label: '', tamanho: '1', acao: false, align: "left" },
      {
        atributo: '', label: '', tamanho: '2', acao: true, align: "center",
        listAcao: [
          { atributo: '', label: 'SELECIONAR', tamanho: '1', acao: true, align: "center" }
        ]
      }


    ];
  }

  ngOnInit() {
  }

  /*buscarContatos() {
    this.auth.getUser();
    this.util.showLoading();
    let url = API + "Contato/Contato?ID_EMPRESA=" + this.auth.user.idEmpresa;
    this.ajax.get(url)
      .subscribe(response => {

        this.arrayContatos = response.data;
        this.util.closeLoading();
      }, err => {

        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao buscar Dados");
      });
  }*/

  limpar() {

  }

  validar() :boolean{
    if (this.nome == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Nome");
      return false;
    }

    if (this.arrayContatosSelecionados.length == 0) {
      this.util.toasterShowError("Atenção", "Selecione pelo meno um item");
      return false;
    }

    return true;
  }
  salvarLista(){
    if(this.validar()){
      var objSalvar = this.montarItem();
      console.log(objSalvar);

      this.util.showLoading();
      let url = API + "ListaContato/ListaContato/SalvarLista";
      this.ajax.post(url, objSalvar)
        .subscribe(response => {
          debugger
          if (!response.hasErrors) {
            this.limpar();
            this.util.toasterShowSuccess("Atenção", "Salvo com Sucesso");
            //this.buscarContatos();
          }
          else
            this.util.toasterShowError("Atenção", response.message);

          this.util.closeLoading();
        }, err => {
          debugger
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
        });

    }
  }

  montarItem(){
    this.auth.getUser();
    let cabecalho = {
      "nome" : this.nome,
      "descricao": this.descricao,
      "ID_EMPRESA": this.auth.user.idEmpresa
    }

    let itens = [];
    this.arrayContatosSelecionados.forEach(item => {
      itens.push({"ID_CONTATO": item.iD_CONTATO});
    });

    cabecalho["item"] = itens;

    return cabecalho;
  }


  receberItemVisualizar(item) {
    console.log('receberItemVisualizar', item);
    this.arrayContatosSelecionados.push(item);
  }

}

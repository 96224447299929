import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-importar-contato',
  templateUrl: './importar-contato.component.html',
  styleUrls: ['./importar-contato.component.scss']
})
export class ImportarContatoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from "@angular/core";
import { AjaxService } from "src/app/service/ajax.service";
import { AuthService } from "src/app/service/auth.service";
import { UtilService } from "src/app/service/util.service";
import { API } from "src/environments/environment";

@Component({
  selector: "app-disparar-lista",
  templateUrl: "./disparar-lista.component.html",
  styleUrls: ["./disparar-lista.component.scss"],
})
export class DispararListaComponent implements OnInit {
  public arrayListas: any[];
  public arrayTemplates: any[];
  public query: string;
  public arrayContatoLista: any[];
  public selectedRowIndex: number;
  public templateNome: string;
  public nomeDisparo: string;
  public listaSelecionada: any;

  constructor(
    private ajax: AjaxService,
    private util: UtilService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.selectedRowIndex = -1;
    this.limpar();
    this.buscarListas();
    this.buscarTemplates();
  }

  limpar(){
    this.templateNome = "";
    this.nomeDisparo = "";
    this.arrayContatoLista = [];
  }

  buscarTemplates() {
    this.auth.getUser();
    this.util.showLoading();
    let url = API + "TemplateModelo/TemplateModelo?ID_EMPRESA=" + this.auth.user.idEmpresa;
    this.ajax.get(url)
      .subscribe(response => {

        this.arrayTemplates = response.data;
        this.util.closeLoading();
      }, err => {

        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao buscar Dados");
      });
  }

  buscarListas() {
    this.auth.getUser();
    this.util.showLoading();
    let url =
      API + "ListaContato/ListaContato?ID_EMPRESA=" + this.auth.user.idEmpresa;
    this.ajax.get(url).subscribe(
      (response) => {
        this.arrayListas = response.data;
        this.util.closeLoading();
      },
      (err) => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao buscar Dados");
      }
    );
  }

  selecionarLinha(item, i) {
    this.selectedRowIndex = i;
    this.listaSelecionada = item;
    this.buscarItensLista(item);
  }

  buscarItensLista(item) {
    this.templateNome = "";
    this.auth.getUser();
    this.util.showLoading();
    let url =
      API +
      `ListaContato/ListaContato/ItensLista?ID_EMPRESA=${this.auth.user.idEmpresa}&ID_LISTA_CONTATO=${item.iD_LISTA_CONTATO}`;
    this.ajax.get(url).subscribe(
      (response) => {
        this.arrayContatoLista = response.data;
        this.util.closeLoading();
      },
      (err) => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao buscar Dados");
      }
    );
  }


  dispararEnvio() {
    debugger
    //console.log(this.arrayContatoLista);
    if (this.templateNome != "" && this.nomeDisparo != "") {
      let dadosEnviar = this.montarDisparo();
      console.log(dadosEnviar);
      ///api/Whatsapp/WhatsappApiOficial/DisparoMensagem
      let url = API + `EnvioTemplate/EnvioTemplate/SalvarLista`;
      this.ajax.post(url, dadosEnviar).subscribe(
        (response) => {

          this.util.closeLoading();
          this.util.toasterShowSuccess("Atenção", "Seus Envios irão iniciar em alguns segundos");
          this.limpar();
        },
        (err) => {
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao buscar Dados");
        }
      );
    } else {
      this.util.toasterShowError("Atenção", "Preencha o nome do Disparo e Nome do Template");
    }
  }

  montarDisparo() {
    this.auth.getUser();
    let dados = {
      ID_EMPRESA: this.auth.user.idEmpresa,
      NOME_TEMPLATE: this.templateNome,
      ID_TEMPLATE: this.templateNome,
      ID_LISTA_CONTATO: this.listaSelecionada.iD_LISTA_CONTATO,
      NOME_DISPARO: this.nomeDisparo
    };

    let itens = [];
    this.arrayContatoLista.forEach((item) => {
      itens.push({
        ID_CONTATO: item.iD_CONTATO,
      });
    });

    dados["item"] = itens;

    return dados;
  }
}

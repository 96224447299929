export class User{
    guid_usuario: string;
    guid_empresa: string;
    guid_atendente: string;
    perfil: string;
    nome: string;
    email: string;
    telefoneEmpresa: string;
  } 

  export class ResponseUser{
    user: User;
    token: string;
    idEmpresa: string;
  } 
  

  export interface jwtDecode{
    aud: string,
    exp: string ,
    iat: boolean,
    iss: any,
    nameid: any,  
    nbf: any
  } 
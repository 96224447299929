<!--<div class="row">
  <app-card cardTitle="Horário de Atendimento" [options]="false" class="col-md-6">
    <div class="row " align="center">
      <div class="col-sm-2"></div>
      <div class="col-sm-5"><span class="badge badge-primary">MANHA</span><br></div>
      <div class="col-sm-5"><span class="badge badge-primary">TARDE</span><br></div>
    </div>
    <div class="row linha" align="center" *ngFor="let hora of horarios">
      <div class="col-sm-2">
        <label class="sub-title" style="padding-top:18px;">
          <input type="checkbox" [(ngModel)]="hora.check" /><br>{{hora.chave}}</label>
      </div>
      <div class="col-sm-5">
        <br>
        <input type="time" style="width:44%" [(ngModel)]="hora.valor.manha.inicio">até
        <input type="time" style="width:44%" [(ngModel)]="hora.valor.manha.fim">
      </div>
      <div class="col-sm-5">
        <br>
        <input type="time" style="width:44%" [(ngModel)]="hora.valor.tarde.fim">até
        <input type="time" style="width:44%" [(ngModel)]="hora.valor.tarde.fim">
      </div>
    </div>
    <div class="row"  align="right">
      <div class="col-md-12"><br>
        <button type="button" class="btn btn-icon btn-success btn-sm " (click)="salvarHorarios()">
          <i class="feather icon-check-circle mr-2 f-18"></i> Salvar
        </button>
      </div>
    </div>
  </app-card>
</div>-->


<div class="row">
  <div class="col-md-12" *ngIf="!adicionarNovo">
    <button type="button" class="btn btn-warning " style="padding-right:20px;" (click)="btAddGrupo()">
      <i class="feather icon-plus-circle mr-2 f-18"></i>
      Adicionar Grupo
    </button>
    <br><br>
  </div>

  <app-card [hidHeader]="true" [options]="false" class="col-md-6" *ngIf="adicionarNovo">
    <div class="row ">
      <div class="col-sm-6">
        <label class="sub-title">Nome</label>
        <input type="text" class="form-control" [(ngModel)]="grupoSelecionado.NOME" name="grupoSelecionado.NOME"
          placeholder="">
      </div>
      <div class="col-sm-6">
        <label class="sub-title">Descricao</label>
        <input type="text" class="form-control" [(ngModel)]="grupoSelecionado.DESCRICAO"
          name="grupoSelecionado.DESCRICAO" placeholder="">
      </div>
      <div class="col-sm-6" [ngClass]="['text-center', !grupoSelecionado.PRINCIPAL]">

        <span *ngIf="!grupoSelecionado.PRINCIPAL">
          <label class="sub-title">Atendimentos Simultâneos </label>
          <input type="number" class="form-control" [(ngModel)]="grupoSelecionado.MAX_ATENDIMENTO_SIMULTANEO"
            name="grupoSelecionado.MAX_ATENDIMENTO_SIMULTANEO" placeholder="">
        </span>
        <span class="text-center" *ngIf="grupoSelecionado.PRINCIPAL" >
          <br>
          <label class="badge badge-warning" >Grupo Principal </label><br>
          <label class="badge badge-success" >sem limite </label>
        </span>

      </div>
      <div class="col-sm-6">
        <br>
        <app-card [hidHeader]="true" cardClass="bg-c-green" class="cursor" (click)="selecionaAba('atendente')">
          <h6 class="text-white">Vincular Atendentes <i class="feather icon-arrow-right float-right"></i></h6>
        </app-card>
      </div>
    </div>
    <div class="row ">

      <div class="col-sm-6">
        <app-card [hidHeader]="true" cardClass="bg-c-blue" class="cursor" (click)="selecionaAba('horarios')">
          <h6 class="text-white">Horario Atendimento <i class="feather icon-arrow-right float-right"></i></h6>
        </app-card>
      </div>

      <div class="col-sm-6">
        <app-card [hidHeader]="true" cardClass="bg-c-yellow" class="cursor" (click)="selecionaAba('respostaRapida')">
          <h6 class="text-white">Respostas rápidas <i class="feather icon-arrow-right float-right"></i></h6>
        </app-card>
      </div>
      <div class="col-sm-6">
        <app-card [hidHeader]="true" cardClass="bg-c-red" class="cursor" (click)="selecionaAba('motivoEncerramento')">
          <h6 class="text-white">Motivo Encerramento <i class="feather icon-arrow-right float-right"></i></h6>
        </app-card>
      </div>
      <div class="col-sm-6">
        <app-card [hidHeader]="true" cardClass="bg-c-blue" class="cursor " (click)="selecionaAba('mensagemAutomatica')">
          <h6 class="text-white">Mensagem Automática<i class="feather icon-arrow-right float-right"></i></h6>
        </app-card>
      </div>
      <!--<div class="col-sm-6">
        <app-card [hidHeader]="true" cardClass="bg-c-red" class="cursor" (click)="selecionaAba('mensagemAutomatica')">
          <h6 class="text-white">Mensagem Automática<i class="feather icon-arrow-right float-right"></i></h6>
        </app-card>
      </div>-->

    </div>
    <br>

    <div class="row">
      <div class="col-sm-12 ">
        <button type="button" class="btn btn-primary float-right " (click)="salvarGrupo()"
          *ngIf="grupoSelecionado.ID_GRUPO == ''">
          <i class="feather icon-check-circle mr-2 f-18"></i>
          Salvar
        </button>
        <button type="button" class="btn btn-warning float-right " (click)="btVoltar()"
          *ngIf="grupoSelecionado.ID_GRUPO == ''">
          <i class="feather icon-check-circle mr-2 f-18"></i>
          Voltar
        </button>

        <button type="button" class="btn btn-info float-right " (click)="salvarGrupo()"
          *ngIf="grupoSelecionado.ID_GRUPO != ''">
          <i class="feather icon-check-circle mr-2 f-18"></i>
          Salvar Edição
        </button>
        <div class="col-1 float-right"></div>
        <button type="button" class="btn btn-warning float-right " style="padding-right:20px;" (click)="limparGrupo()"
          *ngIf="grupoSelecionado.ID_GRUPO != ''">
          <i class="feather icon-check-circle mr-2 f-18"></i>
          Descartar Alteração
        </button>

      </div>

    </div>
  </app-card>
  <app-card cardTitle="Vincular Atendentes" [options]="false" class="col-md-6"
    *ngIf="aba.atendentes && grupoSelecionado.ID_GRUPO != ''">
    <div class="row ">
      <div class="col-sm-12">
        <label class="sub-title">Selecione os atendentes q irão participar deste grupo</label>
        <ng-select [ngClass]="'tag-select'" [options]="arrayAtendentes" [(ngModel)]="atendenteSelecionado"
          [multiple]="true"> </ng-select>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 "><br>
        <button type="button" class="btn btn-primary float-right " (click)="atualizarAtendentes()">
          <i class="feather icon-check-circle mr-2 f-18"></i>
          Atualizar
        </button>
      </div>

    </div>
  </app-card>
  <app-card cardTitle="Horário de Atendimento" [options]="false" class="col-md-6"
    *ngIf="aba.horarios && grupoSelecionado.ID_GRUPO != ''">

    <div class="row " align="center">
      <div class="col-sm-2"></div>
      <div class="col-sm-5"><span class="badge badge-primary">MANHA</span><br></div>
      <div class="col-sm-5"><span class="badge badge-primary">TARDE</span><br></div>
    </div>

    <div class="row linha" align="center" *ngFor="let hora of horarios">
      <div class="col-sm-2">
        <label class="sub-title" style="padding-top:18px;">
          <input type="checkbox" [(ngModel)]="hora.check" /><br>{{hora.chave}}</label>
      </div>
      <div class="col-sm-5">
        <br>
        <input type="time" style="width:44%" [(ngModel)]="hora.valor.manha.inicio">até
        <input type="time" style="width:44%" [(ngModel)]="hora.valor.manha.fim">
      </div>
      <div class="col-sm-5">
        <br>
        <input type="time" style="width:44%" [(ngModel)]="hora.valor.tarde.inicio">até
        <input type="time" style="width:44%" [(ngModel)]="hora.valor.tarde.fim">
      </div>
    </div>
    <div class="row" align="right">
      <div class="col-md-12"><br>
        <button type="button" class="btn btn-icon btn-success btn-sm " (click)="salvarHorarios()">
          <i class="feather icon-check-circle mr-2 f-18"></i> Salvar
        </button>
      </div>
    </div>

  </app-card>

  <app-card cardTitle="Cadastro de Mensagens Automáticas" [options]="false" class="col-md-6"
    *ngIf="aba.mensagemAutomatica && grupoSelecionado.ID_GRUPO != ''">

    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-sm-12 col-md-12">


        <div class="row ">
          <label class="sub-title">Evento</label>
          <select class="form-control" [(ngModel)]="eventoSelecionado" (change)="buscarMensagemEventosPersonalizado()">
            <option value="{{item.iD_EVENTO}}" *ngFor="let item of eventos">{{item.titulo}}</option>
          </select>
        </div>
        <div class="row">
          <label class="sub-title">Descrição</label>
          <textarea class="form-control" [(ngModel)]="eventoMensagem.mensagem" name="eventoMensagem.mensagem"
            rows="5"></textarea>
        </div>
        <br>
        <div class="row">
          <div class="col-sm-12 float-right">
            <button type="button" class="btn btn-primary float-right " (click)="salvarEventoPersonalizado()">
              <i class="feather icon-check-circle mr-2 f-18"></i>
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>




  </app-card>

  <app-card cardTitle="Cadastro de Respostas Rápidas" [options]="false" class="col-md-6"
    *ngIf="aba.respostaRapida && grupoSelecionado.ID_GRUPO != ''">

    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-sm-12 col-md-12">

        <!--<div class="col-12">
            <h4 class="tituloCard text-center">Cadastro</h4>
          </div>-->
        <div class="row ">
          <label class="sub-title">Titulo</label>
          <input type="text" class="form-control" [(ngModel)]="resposta.titulo" name="resposta.titulo" placeholder="">
        </div>
        <div class="row">
          <label class="sub-title">Descrição</label>
          <textarea class="form-control" [(ngModel)]="resposta.descricao" name="resposta.descricao" rows="5"></textarea>
        </div>
        <br>
        <div class="row">
          <div class="col-sm-12 float-right">
            <!--<button type="button" class="btn btn-primary float-right " (click)="salvarRespostaRapida()">
                <i class="feather icon-check-circle mr-2 f-18"></i>
                Salvar
              </button>-->
            <button type="button" class="btn btn-primary float-right " (click)="salvarRespostaRapida()"
              *ngIf="resposta.id == ''">
              <i class="feather icon-check-circle mr-2 f-18"></i>
              Salvar
            </button>
            <button type="button" class="btn btn-info float-right " (click)="salvarRespostaRapida()"
              *ngIf="resposta.id != ''">
              <i class="feather icon-check-circle mr-2 f-18"></i>
              Salvar Edição
            </button>
            <div class="col-1 float-right"></div>
            <button type="button" class="btn btn-warning float-right " *ngIf="resposta.id != ''"
              (click)="limparRespostaRapida()" style="padding-right:20px;">
              <i class="feather icon-check-circle mr-2 f-18"></i>
              Descartar Alteração
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      {{arrayRespostasRapidas?.length}} Respostas Cadastrados
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Titulo</th>
              <th>Acão</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of arrayRespostasRapidas">
              <td>{{item.titulo}}</td>
              <td>
                <button type="button" class="btn btn-icon btn-info " (click)="selecionarItemRespostaRapida(item)">
                  <i class="fa fa-edit"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>


  </app-card>
  <app-card cardTitle="Motivo de Encerramento" [options]="false" class="col-md-6"
    *ngIf="aba.motivoEncerramento && grupoSelecionado.ID_GRUPO != ''">
    <div class="row ">
      <div class="col-sm-12">
        <label class="sub-title">Vincule os motivos de Encerramento ao grupo</label>
        <ng-select [ngClass]="'tag-select'" [options]="arrayMotivoEncerramento"
          [(ngModel)]="motivoEncerramentoSelecionado" [multiple]="true"> </ng-select>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 "><br>
        <button type="button" class="btn btn-primary float-right " (click)="atualizarMotivosEncerramento()">
          <i class="feather icon-check-circle mr-2 f-18"></i>
          Atualizar
        </button>
      </div>

    </div>
  </app-card>


  <app-card [hidHeader]="true" [options]="false" class="col-md-12">
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Descricao</th>
            <th class="text-center">Nº Atendimento</th>
            <th class="text-center">Principal</th>
            <th class="text-center">Ativo</th>
            <th class="text-center">Acão</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of arrayGrupos  | fullTextSearch:query">
            <td>{{item.nome}}</td>
            <td>{{item.descricao}}</td>
            <td class="text-center">
              <span *ngIf="!item.principal">{{item.maX_ATENDIMENTO_SIMULTANEO}}</span>
              <span *ngIf="item.principal">-</span>
            </td>
            <td class="text-center">
              <div class="form-group">
                <div class="switch switch-primary d-inline m-r-10">
                  <input type="checkbox" id="{{item.iD_GRUPO}}2" [(ngModel)]="item.principal"
                    (change)="tornarPrincipal(item)">
                  <label for="{{item.iD_GRUPO}}2" class="cr"></label>
                </div>
              </div>
            </td>
            <td class="text-center">
              <span *ngIf="item.ativo" class="badge badge-success">ATIVO</span>
              <span *ngIf="!item.ativo" class="badge badge-danger">INATIVO</span>
              <div class="form-group">
                <div class="switch switch-primary d-inline m-r-10">
                  <input type="checkbox" id="{{item.iD_GRUPO}}" [(ngModel)]="item.ativo"
                    (change)="desabilitarGrupo(item)">
                  <label for="{{item.iD_GRUPO}}" class="cr"></label>
                </div>
              </div>
            </td>
            <!--<td>
                <span *ngIf="item.ativo" class="badge badge-success">ATIVO</span>
                <span *ngIf="!item.ativo" class="badge badge-danger">INATIVO</span>
                <div class="form-group">
                  <div class="switch switch-primary d-inline m-r-10">
                    <input type="checkbox" id="{{item.guid_atendente}}" [(ngModel)]="item.ativo"
                      (change)="desabilitarAtendente(item)">
                    <label for="{{item.guid_atendente}}" class="cr"></label>
                  </div>
                </div>
              </td>-->

            <td class="text-center">
              <button type="button" class="btn btn-icon btn-info " (click)="selecionarItem(item)">
                <i class="fa fa-edit"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </app-card>

</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AjaxService } from 'src/app/service/ajax.service';
import { UtilService } from 'src/app/service/util.service';
import { API, BASE_API } from 'src/environments/environment';


@Component({
  selector: 'app-modal-add-etapa',
  templateUrl: './modal-add-etapa.component.html',
  styleUrls: ['./modal-add-etapa.component.scss']
})
export class ModalAddEtapaComponent implements OnInit {
  @Input() ID_MODULO: string;
  @Output() acaoVoltar = new EventEmitter();

  public titulo: string;
  public ordem: number;
  public obrigatorio: boolean;
  public descricao: string;
  public uploadedFileAvatar: File;


  constructor(
    private ajax: AjaxService,
    public util: UtilService
  ) {       
  }

  ngOnInit() {
    this.limpar();
  }

  limpar(){
    this.titulo = "";
    this.ordem = 1;
    this.obrigatorio = true;
    this.descricao = "";
    this.uploadedFileAvatar = null;
  }

  validar():boolean{

    if (this.titulo == undefined || this.titulo == "") {
      this.util.toasterShowError("Atenção", "Entre com o titulo do Etapa");
      return false;
    }

    if (this.ordem == undefined ) {
      this.util.toasterShowError("Atenção", "A ordem não pode ser nula");
      return false;
    }

    if(this.uploadedFileAvatar == undefined /*&& this.uploadedFileAvatar.length > 0*/){
      this.util.toasterShowError('Atenção', 'Envie uma imagem para Avatar');
      return false;
    }

    if(this.ID_MODULO == undefined){
      this.util.toasterShowError('Atenção', 'Não é possível salvar antes de escolher o Etapa');
      return false;
    }

    return true;
  }

  montarJson(){
    return {
      "TITULO": this.titulo,
      "INSTRUCAO": this.descricao,
      "OBRIGATORIO": this.obrigatorio,
      "ORDEM": this.ordem,
      "PROXIMO": false,
      "ID_MODULO_PROGRAMA": this.ID_MODULO,
      "Arquivo": this.uploadedFileAvatar[0]
    };
  }

  salvar(){
    if(this.validar()){
      let data = this.montarJson();
      debugger
      let dados = this.util.jsonToFormData(data);


      debugger
      let url = API + "EtapaPrograma/EtapaPrograma/Salvar";
      this.ajax.postFile(url, dados)
        .subscribe(response => {

          this.util.closeLoading();
          if(!response.hasErrors){
            this.util.toasterShowSuccess("Atenção", "Etapa Salvo com Sucesso");
          this.limpar();
          }else{
            this.util.toasterShowError("Atenção", response.message);
          }
          
        }, err => {
          debugger
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Salvar Etapa");
        });
      
    }
  }

  voltar(){
    this.acaoVoltar.emit();
  }

}

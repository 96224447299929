<div class="row">
  <app-card [hidHeader]="true" [options]="false" class="col-sm-12" id="inicioPagina">
    <div class="row ">
      <div class="col-sm-6 col-xl-4 ">
        <label class="sub-title">Nome</label>
        <input type="text" class="form-control" [(ngModel)]="template.nome" name="template.nome" placeholder="">
      </div>

      <div class="col-sm-12 col-xl-4">
        <label class="sub-title">Linguagem </label>
        <select class="form-control" [(ngModel)]="template.linguagem" name="template.linguagem">
          <option value="pt_BR">Português</option>
          <option value="en_US">Inglês</option>
        </select>
      </div>
    </div>
    <br>

    <div class="row">
      <div class="col-sm-8 ">
        <button type="button" class="btn btn-primary float-right " (click)="salvarTemplate()" *ngIf="template.id == ''">
          <i class="feather icon-check-circle mr-2 f-18"></i>
          Salvar
        </button>
        <button type="button" class="btn btn-info float-right " (click)="salvarTemplate()" *ngIf="template.id != ''">
          <i class="feather icon-check-circle mr-2 f-18"></i>
          Salvar Edição
        </button>
       <div class="col-1 float-right"></div>
        <button type="button" class="btn btn-warning float-right " *ngIf="template.id != ''" (click)="limpar()" style="padding-right:20px;">
          <i class="feather icon-check-circle mr-2 f-18"></i>
          Descartar Alteração
        </button>

      </div>

    </div>
  </app-card>
</div>


<div class="row">
  <app-card cardTitle="{{arrayTemplates?.length}} Usuários Cadastrados" [options]="false" class="col-md-12">
    <div class="row ">
      <div class="col-md-6 col-sm-12 ">
        <label for="exampleInputEmail1">Filtro</label>
        <input type="text" class="form-control form-control-sm " [(ngModel)]="query"
          placeholder="Digite seu filtro, sera feito uma busca em todas as colunas" >
      </div>
    </div><br>
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Nome</th>
            <th>linguagem</th>
            <th>Acão</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of arrayTemplates  | fullTextSearch:query" >
            <td>{{item.nomE_TEMPLATE}}</td>
            <td>{{item.linguagem}}</td>
            <td>
              <button type="button" class="btn btn-icon btn-info " (click)="selecionarItem(item)">
                <i class="fa fa-edit"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </app-card>
</div>

<!--https://stackoverflow.com/questions/66267792/drawflow-library-on-typescript-->

<!--<div id="drawflow" ></div>-->
<!--<div appNgDrawFlow></div>-->

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
	<p style="font-size: 20px; color: white">Aguarde ...</p>
  </ngx-spinner>


<div class="wrapper">

	<div class="col">
		<div class="drag-drawflow" draggable="true" (dragstart)="drag($event)" data-node="template">
			<i class="fas fa-code-branch"></i><span> Adicionar Mensagem</span>
		</div>

		<!--<ul><li (dragstart)="drag($event)" >Option 1 </li><li>Option 2</li></ul>-->
		<!--<div class="drag-drawflow" draggable="true" (dragstart)="drag($event)" data-node="facebook">
			<i class="fab fa-facebook"></i><span> Facebook</span>
		</div>
		<div class="drag-drawflow" draggable="true" (dragstart)="drag($event)" data-node="slack">
			<i class="fab fa-slack"></i><span> Slack recive message</span>
		</div>
		<div class="drag-drawflow" draggable="true" (dragstart)="drag($event)" data-node="github">
			<i class="fab fa-github"></i><span> Github Star</span>
		</div>
		<div class="drag-drawflow" draggable="true" (dragstart)="drag($event)" data-node="telegram">
			<i class="fab fa-telegram"></i><span> Telegram send message</span>
		</div>
		<div class="drag-drawflow" draggable="true" (dragstart)="drag($event)" data-node="aws">
			<i class="fab fa-aws"></i><span> AWS</span>
		</div>
		<div class="drag-drawflow" draggable="true" (dragstart)="drag($event)" data-node="log">
			<i class="fas fa-file-signature"></i><span> File Log</span>
		</div>
		<div class="drag-drawflow" draggable="true" (dragstart)="drag($event)" data-node="google">
			<i class="fab fa-google-drive"></i><span> Google Drive save</span>
		</div>
		<div class="drag-drawflow" draggable="true" (dragstart)="drag($event)" data-node="email">
			<i class="fas fa-at"></i><span> Email send</span>
		</div>
		
		<div class="drag-drawflow" draggable="true" (dragstart)="drag($event)" data-node="multiple">
			<i class="fas fa-code-branch"></i><span> Multiple inputs/outputs</span>
		</div>
		<div class="drag-drawflow" draggable="true" (dragstart)="drag($event)" data-node="personalized">
			<i class="fas fa-fill"></i><span> Personalized</span>
		</div>
		<div class="drag-drawflow" draggable="true" (dragstart)="drag($event)" data-node="dbclick">
			<i class="fas fa-mouse"></i><span> DBClick!</span>
		</div>-->
	</div>
	<div class="col-right">
		<!--<div class="menu">
			<ul>
				<li (click)="editor.changeModule('Home'); changeModule($event);" class="selected">
					Home
				</li>
				<li (click)="editor.changeModule('Other'); changeModule($event);">
					Other Module
				</li>
			</ul>
		</div>-->
		<div id="drawflow" (drop)="drop($event)" (dragover)="allowDrop($event)">
			<!--<div class="btn-clear" (click)="editor.clearModuleSelected()">Clear</div>-->
			<div class="btn-submit" (click)="onSubmit()">Submit</div>
			<div class="bar-zoom">
				<i class="fas fa-search-minus" (click)="editor.zoom_out()"></i>
				<i class="fas fa-search" (click)="editor.zoom_reset()"></i>
				<i class="fas fa-search-plus" (click)="editor.zoom_in()"></i>
			</div>
		</div>
	</div>
</div>
<div class="row">
  <app-card cardTitle="Importar Contatos" [hidHeader]="false" [options]="false" class="col-sm-12">
    <div class="row ">
      <div class="col-sm-6 col-xl-6 ">
        <label class="sub-title">Entre com a planilia preenchida </label>
        <file-upload [(ngModel)]="uploadedFile" [fileslimit]="1"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          simple></file-upload>
      </div>

      <div class="col-sm-6 col-xl-6 text-right">
        <!--<a class="badge badge-primary" style="padding:5px;">
          <i class="feather icon-download-cloud mr-2 f-18"></i>
          Modelo Planilia
        </a>-->
        <button type="button" class="btn btn-warning float-right " (click)="downloadPlanilia()">
          <i class="feather icon-download-cloud mr-2 f-18"></i>
          Baixar Modelo de Planilia
        </button>

      </div>
      <div class="col-md-12">
        <br>
        <hr>
        <h5 class="mt-3">Criar Lista de Contato?</h5>

        <div class="custom-control custom-switch">
          <input type="checkbox" id="customswitch1"  class="custom-control-input" [(ngModel)]="criarLista">
          <label for="customswitch1" class="custom-control-label">
            <span *ngIf="criarLista" > Sim</span>
            <span *ngIf="!criarLista" > Não</span>
          </label>
        </div>
        <div class="row" *ngIf="criarLista">
          <div class="col-sm-12 col-md-6 ">
            <label class="sub-title">Nome da Lista </label>
            <input type="text" class="form-control" [(ngModel)]="nomeLista" name="nomeLista" placeholder="">
          </div>
        </div>
      </div>
    </div>

    <br>
    <div class="row">
      <div class="col-sm-6 float-right">
        <button type="button" class="btn btn-info float-right " (click)="uploadPlanilia()">
          <i class="feather icon-upload-cloud mr-2 f-18"></i>
          Importar Planília
        </button>
      </div>
    </div>
  </app-card>
</div>

<div class="row" *ngIf="contato.id != ''">
  <app-card cardTitle="MOTIVO" [hidHeader]="true" [options]="false" class="col-sm-12">
    <div class="row ">
      <div class="col-sm-6 col-xl-4 ">
        <label class="sub-title">Nome</label>
        <input type="text" class="form-control" [(ngModel)]="contato.nome" name="contato.nome" placeholder="">
      </div>
      <div class="col-sm-12 col-xl-4 ">
        <label class="sub-title">Telefone</label>
        <input type="text" class="form-control" [(ngModel)]="contato.telefone" name="contato.telefone" placeholder="">
      </div>
      <div class="col-sm-12 col-xl-4 ">
        <label class="sub-title">Email</label>
        <input type="text" class="form-control" [(ngModel)]="contato.email" name="contato.email" placeholder="">
      </div>
      <div class="col-sm-12 col-xl-4 ">
        <label class="sub-title">Observação</label>
        <textarea class="form-control" [(ngModel)]="contato.observacao" name="contato.observacao" rows="5"></textarea>
      </div>
    </div>

    <br>
    <div class="row">
      <div class="col-sm-12 float-right">
        <button type="button" class="btn btn-info float-right " (click)="salvarContato()" *ngIf="contato.id != ''">
          <i class="feather icon-check-circle mr-2 f-18"></i>
          Salvar Edição
        </button>
        <div class="col-1 float-right"></div>
        <button type="button" class="btn btn-warning float-right " *ngIf="contato.id != ''" (click)="limpar()"
          style="padding-right:20px;">
          <i class="feather icon-check-circle mr-2 f-18"></i>
          Descartar Alteração
        </button>
      </div>
    </div>
  </app-card>
</div>

<div class="row">
  <app-card cardTitle="{{arrayContatos?.length}} Contato Cadastrados" blockClass="table-border-style" [options]="false"
    class="col-md-12">
    <div class="row ">
      <div class="col-md-6 col-sm-12 ">
        <label for="exampleInputEmail1">Filtro</label>
        <input type="text" class="form-control form-control-sm " [(ngModel)]="query"
          placeholder="Digite seu filtro, sera feito uma busca em todas as colunas">
      </div>
    </div><br>
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th></th>
            <th>Nome</th>
            <th>Telefone</th>
            <th>Email</th>
            <th>OBS</th>

            <th>Acão</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of arrayContatos  | fullTextSearch:query">
            <td>
              <i *ngIf="item.eH_IMPORTADO" class="fas fa-cloud-upload-alt"></i>
              <i *ngIf="!item.eH_IMPORTADO" class="feather icon-user"></i>
            </td>
            <td>{{item.nome}}</td>
            <td>{{item.telefone}}</td>
            <td>{{item.email}}</td>
            <td>{{item.observacao}}</td>

            <td>
              <button type="button" class="btn btn-icon btn-info " (click)="selecionarItem(item)">
                <i class="fa fa-edit"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </app-card>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResponseNet } from 'src/app/model/ResponseNet.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-new-tree',
  templateUrl: './new-tree.component.html',
  styleUrls: ['./new-tree.component.scss']
})
export class NewTreeComponent implements OnInit {

  @Input() items; //data receive from other components
  //@Output() atualizar = new EventEmitter();
  @Output() atualizar = new EventEmitter<string>();
  @Output() acaoAdd = new EventEmitter<any>();
  @Output() acaoUpdate = new EventEmitter<any>();
  @Output() acaoDel = new EventEmitter<any>();

  public titulo: string;
  public descricao: string;
  public cod: string;
  protected selecionado: any;

  constructor(
    public util: UtilService,
    private ajax: AjaxService,
  ) { }

  ngOnInit() {
    this.limparModal();
  }

  update(parent) {
    this.acaoUpdate.emit(parent);
  }

  remove(parent) {
    this.acaoDel.emit(parent);
  }

  add(parent) {
    this.acaoAdd.emit(parent);
  }

  eventoadd(parent) {
    this.acaoAdd.emit(parent);
  }

  eventoUpdate(parent) {
    this.acaoUpdate.emit(parent);
  }
  
  eventoDel(parent) {
    this.acaoDel.emit(parent);
  }

  limparModal() {
    this.titulo = "";
    this.descricao = "";
    this.cod = "";
    //this.selecionado = null;
  }


  montarDados() {
    return {
      "TITULO_FLUXO": this.titulo,
      "TITULO": this.titulo,
      "DESCRICAO": this.descricao,
      "ID_FLUXO_PAI": this.selecionado.iD_FLUXO,
      "COD": this.cod
    };
  }

  salvarNode(modal) {
    console.log('salvar node', this.selecionado);
    this.util.closeModal(modal);
    let dados = this.montarDados();
    console.log(dados);

    this.util.showLoading();
    //let dados = this.montarDados();
    var url = API + 'Fluxo/Fluxo';
    this.ajax.post(url, dados)
      .subscribe((response: ResponseNet) => {

        this.limparModal();
        this.util.closeLoading();

        this.util.closeModal(modal);
        //this.buscarDadosTree();
        debugger
        this.atualizar.emit('sucesso');

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
      });

  }


  onexpand(item) {
    this.selecionado = item;
    if (item.expanded) {
      item.expanded = !item.expanded;
      return;
    } else {
      if (item.childrens) {
        if (item.childrens.length > 0) {
          item.expanded = true;
        } else {
          item.expanded = false;
        }
      }
    }
  }

}

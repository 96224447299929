import { Component, OnInit } from '@angular/core';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';

@Component({
  selector: 'app-disparo-verificar',
  templateUrl: './disparo-verificar.component.html',
  styleUrls: ['./disparo-verificar.component.scss']
})
export class DisparoVerificarComponent implements OnInit {

  public arrayEnvio: any[];
  public arrayEnvioItens: any[];
  public selectedRowIndex: number;


  constructor(
    private ajax: AjaxService,
    private util: UtilService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.buscarEnvio();
  }

  selecionarLinha(item, i) {
    this.selectedRowIndex = i;
    this.buscarItensLista(item);
  }

  buscarEnvio() {
    this.auth.getUser();
    this.util.showLoading();
    let url = API + "EnvioTemplate/EnvioTemplate?ID_EMPRESA=" + this.auth.user.idEmpresa;
    this.ajax.get(url).subscribe(
      (response) => {
        this.arrayEnvio = response.data;
        this.util.closeLoading();
      },
      (err) => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao buscar Dados");
      }
    );
  }

  buscarItensLista(item) {
    debugger
    this.auth.getUser();
    this.util.showLoading();
    let url =
      API +
      `EnvioTemplate/EnvioTemplate/Itens?ID_EMPRESA=${this.auth.user.idEmpresa}&ID_ENVIO_TEMPLATE=${item.iD_ENVIO_TEMPLATE}`;
    this.ajax.get(url).subscribe(
      (response) => {
        this.arrayEnvioItens = response.data;
        this.util.closeLoading();
      },
      (err) => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao buscar Dados");
      }
    );
  }

}

<!--<div class="row" *ngIf="!showChat">-->
<div class="row">
  <div class="col-xl-12 col-lg-12 filter-bar invoice-list">
    <nav class="navbar ">
      <ul class="nav">

        <li class="nav-item dropdown" ngbDropdown placement="auto">
          <!--<a class="nav-link text-secondary pausa" ngbDropdownToggle href="javascript:" id="bypriority" >
            <i class="fas fa-list"></i> EM PAUSA {{atendenteStatus?.iD_STATUS_ATENDENTE}}
          </a>-->
          <a class="nav-link text-secondary disponivel" ngbDropdownToggle href="javascript:" id="bypriority"
            *ngIf="atendenteStatus?.iD_STATUS_ATENDENTE == '2dbe82c6-d972-437e-85c2-3f2980578a0b'">
            <i class="fas fa-list"></i> DISPONÍVEL
          </a>
          <a class="nav-link text-secondary pausa" ngbDropdownToggle href="javascript:" id="bypriority"
            *ngIf="atendenteStatus?.iD_STATUS_ATENDENTE == '3dbe82c6-d972-437e-85c2-3f2980578a0c'">
            <i class="fas fa-list"></i> EM PAUSA
          </a>
          <div ngbDropdownMenu aria-labelledby="bypriority">
            <a class="dropdown-item disponivel" href="javascript:"
              (click)="alterarStatusAtendente('2dbe82c6-d972-437e-85c2-3f2980578a0b')">DISPONÍVEL</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item pausa" href="javascript:"
              (click)="alterarStatusAtendente('3dbe82c6-d972-437e-85c2-3f2980578a0c')">PAUSAR</a>
          </div>
        </li>
      </ul>

      <div class="nav-item nav-grid">
        <button type="button" class="btn btn-info btn-icon myPadding" [placement]="'top'" ngbTooltip="ABERTOS"
          (click)="buscarClientesPorAbertos()">
          ABERTOS
          <label class="badge badge-warning m-0"
            *ngIf="sender?.abertos?.totalEmFila > 0">{{sender?.abertos?.totalEmFila}}</label>
          <!--<label class="badge badge-warning m-0">14</label>-->
        </button>
        <!--<button type="button" class="btn btn-danger btn-icon myPadding" [placement]="'top'" ngbTooltip="FECHADOS">
          ENCERRADOS

        </button>-->
        <span style="margin-left:55px;"></span>
        <span *ngIf="atendenteStatus?.iD_STATUS_ATENDENTE == '2dbe82c6-d972-437e-85c2-3f2980578a0b'">
          <button type="button" class="btn btn-primary btn-icon myPadding" [placement]="'top'" ngbTooltip="{{it.nome}}"
            *ngFor="let it of arrayGruposAtendente" (click)="selecionarGrupo(it)">
            {{it.nome}}
            <label class="badge badge-warning m-0" *ngIf="it?.totalEmFila">{{it?.totalEmFila}}</label>
          </button>
        </span>

      </div>
    </nav>
  </div>
  <br> <br> <br>
</div>
<app-card [hidHeader]="true" *ngIf="!showChat">
  <div class="" align="center">
    <h5 class="">{{grupoSelecionado?.nome}}</h5>
  </div>
</app-card>

<div class="row" *ngIf="!showChat">

  <div class="col-md-12 col-sm-12" *ngIf="arrayClientesGrupos.length == 0">
    <app-card [hidHeader]="true">
      <div class="page-header-title" align="center">
        <h5 class="ng-star-inserted">Não Existem Clientes neste Grupo</h5>
      </div>
    </app-card>
  </div>


  <div class="col-md-12 col-sm-12" *ngIf="arrayClientesGrupos.length > 0">
    <div *ngFor="let item of arrayClientesGrupos">
      <app-card [hidHeader]="true" cardClass="card-border-c-blue">
        <img class="media-object imgWhatsapp" src="assets/images/icon-whatsapp1.png">
        <h5 class="d-inline-block m-b-10">{{item.nome}}</h5>
        <div class="dropdown-secondary float-right "
          *ngIf="item.guid_status_atendimento == '3dbe82c9-d972-437e-85c3-3f2380518a0a'">
          <button type="button" class="btn btn-primary btn-icon" ngbTooltip="{{item.nome}}"
            (click)="vincularAtendente(item)">
            <i class="fas fa-check-square"></i> Atender Cliente
          </button>
        </div>
        <div class="dropdown-secondary float-right myPadding">
          <span><b>Data :</b> {{item.data_fila | date:"dd/MM/yyyy HH:mm:ss"}}</span>
        </div>
        <div class="dropdown-secondary float-right myPadding">
          <span><b>Número :</b> {{item.telefone}}</span>
        </div>
      </app-card>
    </div>
  </div>


</div>


<div class="container" *ngIf="showChat">
  <br>
  <div class="row rounded-lg overflow-hidden shadow">
    <!-- Users box-->
    <div class="col-2 px-0">
      <div class="bg-white">

        <div class="bg-gray px-4 py-2 bg-light">
          <!--<button type="button" class="btn btn-primary btn-icon" (click)="esconderChat()">
            <i class="feather icon-arrow-left"></i> Voltar
          </button>-->
        </div>

        <div class="messages-box">
          <div class="list-group rounded-0">

            <a class="list-group-item list-group-item-action rounded-0 "
              [ngClass]="{'active': currentLine === item.guid_contato}"
              *ngFor="let item of arrayClientesEmAberto; let i = index">
              <div class="media">

                <img src="assets/images/icon-whatsapp1.png" alt="user" width="50" class="rounded-circle"
                  (click)="selecionarConversa(item)">
                <div class="media-body ml-4" style="padding-top:18px;padding-bottom:18px;"
                  (click)="selecionarConversa(item)">
                  <div class="d-flex align-items-center justify-content-between mb-1">

                    <h6 class="mb-0">{{item.nome}}</h6>
                    <!--<small class="small font-weight-bold">{{item.qtd_nao_lidas}}</small>-->
                    <label class="badge badge-warning m-0" *ngIf="item.qtd_nao_lidas > 0">
                      <i class="fa fa-envelope float-right f-10"></i>
                    </label>

                  </div>

                  <!--<p class="font-italic mb-0 text-small">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                    do eiusmod tempor incididunt ut labore.</p>-->
                </div>
                <!--<i class="fas fa-share-square text-right" ngbTooltip="Encaminhar " style="cursor: pointer;" (click)="modalRedirecionarOutroGrupo(item)"></i>-->
                <!--<i class="fa fa-ellipsis-v text-right f-20" style="cursor: pointer;" (click)="modalRedirecionarOutroGrupo(item)"></i>-->
                <ul class="nav">

                  <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
                    <i class="fa fa-ellipsis-v text-right f-20" style="cursor: pointer;" ngbDropdownToggle></i>
                    <div ngbDropdownMenu aria-labelledby="byCli">
                      <a class="dropdown-item encaminhar" href="javascript:"
                        (click)="modalRedirecionarOutroGrupo(item)">ENCAMINHAR</a>
                      <div class="dropdown-divider"></div>
                      <a class="dropdown-item encerrar" href="javascript:"
                        (click)="buscarMotivoEncerramentos(item)">ENCERRAR</a>
                    </div>
                  </li>
                </ul>

              </div>
              <!--<div class="media">
                <i class="fa fa-ellipsis-v text-right"></i>
              </div>-->

            </a>

            <!--<a href="#" class="list-group-item list-group-item-action list-group-item-light rounded-0 active">
              <div class="media"><img src="https://therichpost.com/wp-content/uploads/2020/06/avatar2.png" alt="user"
                  width="50" class="rounded-circle">
                <div class="media-body ml-4">
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <h6 class="mb-0">Jason Doe</h6><small class="small font-weight-bold">14 Dec</small>
                  </div>
                  <p class="font-italic text-muted mb-0 text-small">Lorem ipsum dolor sit amet, consectetur. incididunt
                    ut labore.</p>
                </div>
              </div>
            </a>-->

          </div>
        </div>
      </div>
    </div>
    <!-- Chat Box-->
    <div class="col-10 px-0">
      <div class="px-4 py-5 chat-box bg-white">

        <div *ngFor="let it of arrayChatCliente;  let i= index ;">
          <!-- Sender Message boot-->
          <div class="media w-50 mb-3" *ngIf="it.ehCliente" id="sc{{i}}">
            <img src="assets/images/icon-whatsapp1.png" alt="user" width="50" class="rounded-circle">
            <div class="media-body ml-3">
              <div class="bg-light rounded py-2 px-3 mb-2">
                <p class="text-small mb-0 text-muted"><span [innerHTML]="it.mensagem"></span></p>
              </div>
              <p class="small text-muted">{{it.data | date:'dd/MM/yyyy hh:mm:ss'}}</p>
            </div>
          </div>

          <!-- Reciever Message   cliente-->
          <div class="media w-50 ml-auto mb-3" *ngIf="it.ehAtendente" id="sc{{i}}">

            <div class="media-body">
              <div class="bg-primary rounded py-2 px-3 mb-2">
                <p class="text-small mb-0 text-white"><span [innerHTML]="it.mensagem"></span></p>
              </div>
              <p class="small text-muted">{{it.data | date:'dd/MM/yyyy hh:mm:ss'}}</p>
            </div>
            <img src="assets/images/robo.png" alt="user" width="50" class="rounded-circle">
          </div>
        </div>

      </div>
      <!--<div id="down">galoo</div>-->
      <!-- Typing area -->
      <form action="#" class="bg-light">
        <div class="input-group">
          <input type="text" placeholder="Escreva sua Mensagem" (keydown)="EnterSubmit($event)"
            class="form-control rounded-0 border-0 py-4 bg-light" [(ngModel)]="mensagem" name="mensagem">

          <div class="input-group-append">
            <label class=" control-label" for="evento">Grupo: {{respostaRapidaPorGrupo?.grupo?.nome}}</label>
            <select class="form-control" (change)="onChangeResponstaRapida($event.target.value)">
              <option value="" >Selecione Responsta Rápida </option>
              <option [value]="i.descricao" *ngFor="let i of respostaRapidaPorGrupo?.mensagens">{{i.titulo}}</option>
            </select>
            <!--
              <select [ngModel]="selectedValue" (ngModelChange)="onChange($event)" name="sel2">
              <option [value]="i" *ngFor="let i of values">{{i}}</option>
            </select>
              <button id="button-addon2" type="button" class="btn btn-link" (click)="EnviarMensagem()">
              <i class="fa fa-paper-plane"></i> Enviar
            </button>-->
          </div>
          <div class="input-group-append">
            <button id="button-addon2" type="button" class="btn btn-link" (click)="EnviarMensagem()">
              <i class="fa fa-paper-plane"></i> Enviar
            </button>
          </div>
        </div>
      </form>

    </div>
  </div>
</div>

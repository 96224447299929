import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import Drawflow, {
  ConnectionEvent,
  ConnectionStartEvent,
  DrawFlowEditorMode,
  DrawflowConnection,
  DrawflowConnectionDetail,
  DrawflowNode,
  MousePositionEvent,
} from 'drawflow';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';
import { NodeElement } from './node.model';

@Component({
  selector: 'app-fluxo-draw',
  templateUrl: './fluxo-draw.component.html',
  styleUrls: ['./fluxo-draw.component.scss']
})
export class FluxoDrawComponent implements OnInit, AfterViewInit {

  nodes: NodeElement[] = [];
  nodesHTML!: NodeListOf<Element>;

  nodesDrawn: any[] = [];
  selectedItem!: NodeElement;
  editor!: any;

  locked: boolean = false;

  lastMousePositionEv: any;
  mousePosition: any;

  drawFlowHtmlElement!: HTMLElement;
  constructor(
    private ajax: AjaxService,
    public util: UtilService,
    private router: Router,
    public auth: AuthService
  ) { }

  private initializeList(length: number) {

    for (let i = 0; i < length; i++) {
      this.nodes.push({
        id: i + 1,
        name: ' Novo Item',
        inputs: 1,
        outputs: 1
      });
    }
  }

  voltar(){
    this.router.navigate(['/home']);
  }

  buscarNodes() {
    this.auth.getUser();
    this.util.showLoading();
    var url = API + 'DrawFlow/DrawFlow/BuscarFluxo/'+ this.auth.user.idEmpresa;
    this.ajax.get(url)
      .subscribe(response => {

        this.util.closeLoading();
        let dataToImport = this.ajusteDados(response.data);
        this.editor.import(dataToImport);

      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Dados");
      });
  }

  ajusteDados(itensArray: any[]): any {
    let obj = { drawflow: { Home: { data: {} } } };

    for (const [key, value] of Object.entries(itensArray)) {

      value.inputs = value.inputs == null ? {'input_1': {'connections': []}} : value.inputs;
      value.outputs = value.outputs == null ? {'output_1': {'connections': []}} : value.outputs;
      value.data = value.data == null ? {} : value.data;

      obj.drawflow.Home.data[value.id] = value;
    }
    console.log(obj);
    return obj;
  }

  private initDrawFlow(htmlElement: HTMLElement): void {
    this.editor = new Drawflow(htmlElement);
    this.editor.reroute = true;
    this.editor.reroute_fix_curvature = true;
    this.editor.force_first_input = false;
    this.editor.start();
    this.buscarNodes();
    /*const dataToImport = {
      drawflow: {
        Home: {
          data: {
            '1': {
              id: 1,
              name: 'node1',
              data: {},
              class: '',
              html: '\n          <div>\n            <i class="title-box">node1</i>\n          </div>\n          ',
              typenode: false,
              inputs: {},
              outputs: {},
              pos_x: 134,
              pos_y: 131,
            },
            '2': {
              id: 2,
              name: 'node2',
              data: {},
              class: '',
              html: '\n          <div>\n            <i class="title-box">node2</i>\n          </div>\n          ',
              typenode: false,
              inputs: {
                input_1: {
                  connections: [
                    {
                      node: '3',
                      input: 'output_1',
                    },
                  ],
                },
              },
              outputs: {
                output_1: {
                  connections: [
                    {
                      node: '3',
                      output: 'input_1',
                    },
                  ],
                },
              },
              pos_x: 520,
              pos_y: 111,
            },
            '3': {
              id: 3,
              name: 'node3',
              data: {},
              class: '',
              html: '\n          <div>\n            <i class="title-box">node3</i>\n          </div>\n          ',
              typenode: false,
              inputs: {
                input_1: {
                  connections: [
                    {
                      node: '2',
                      input: 'output_1',
                    },
                  ],
                },
              },
              outputs: {
                output_1: {
                  connections: [
                    {
                      node: '2',
                      output: 'input_1',
                    },
                  ],
                },
              },
              pos_x: 525,
              pos_y: 321,
            },
          },
        },
      },
    };*/
    //this.editor.import(dataToImport);
  }



  createNode(obj) {

    //this.util.showLoading();
    let url = API + "DrawFlow/DrawFlow/createNode";
    this.ajax.post(url, obj)
      .subscribe(response => {
        //atualizdo o node

        debugger
          if (obj.ID_DRAW_FLOW != undefined && obj.ID_DRAW_FLOW != null && obj.ID_DRAW_FLOW != "")
            this.util.toasterShowInfo("Atenção", "Item Atualizado com Sucesso!");

        this.editor.drawflow.drawflow.Home.data[obj.ID_NODE].data.id = response.data.iD_DRAW_FLOW;
        this.util.closeLoading();
      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
      });

  }

  deleteNode(id) {
    this.util.showLoading();
    this.auth.getUser();
    let id_empresa = this.auth.user.idEmpresa;
    let url = API + "DrawFlow/DrawFlow/deleteNode/"+id+"/"+id_empresa;
    this.ajax.post(url, {})
      .subscribe(response => {
        if(!response.hasErrors)
          this.util.toasterShowInfo("Atenção", "Item Removido com Sucesso");
        else
          this.util.toasterShowError("Atenção", response.message);

        this.util.closeLoading();
      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
      });
  }

  deleteConnection(objConect) {
    this.auth.getUser();
    let id_empresa = this.auth.user.idEmpresa;
    objConect['ID_EMPRESA'] = id_empresa;
    this.util.showLoading();
    let url = API + "DrawFlow/DrawFlow/deleteConnection";
    this.ajax.post(url, objConect)
      .subscribe(response => {
        this.util.toasterShowInfo("Atenção", "Conexao Removida com Sucesso");
        this.util.closeLoading();
      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
      });
  }

  savePosition(obj) {
    this.auth.getUser();
    obj["ID_EMPRESA"] = this.auth.user.idEmpresa;
    //this.util.showLoading();
    let url = API + "DrawFlow/DrawFlow/moveNode";
    this.ajax.post(url, obj)
      .subscribe(response => {
        //this.limparModal();
        this.util.closeLoading();
        this.util.toasterShowInfo("Atenção", "Posição Atualizada com Sucesso");


      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Nova Posição");
      });
  }

  montarObjSave(obj: any): any {


    var ajustado = {
      "ID_NODE": obj.id,
      "CLASS": obj.class,
      "HTML": obj.html,
      "NAME": obj.data.titulo,
      "POS_X": obj.pos_x,
      "POS_Y": obj.pos_y,
      "TYPENODE": obj.typenode,
      "CHAVE": obj.data.chave,
      "CORPO": obj.data.corpo,
      "NOME": obj.data.nome,
      "TITULO": obj.data.titulo,
      "ACAO": obj.data.acao
    };

    if (obj.inputs.input_1.connections.length > 0) {
      let inputs = [];
      obj.inputs.input_1.connections.forEach(item => {
        inputs.push({ "NODE": item.node, "INPUT": item.input });
      })
      ajustado['INPUTS'] = inputs;
    }

    if (obj.outputs.output_1.connections.length > 0) {
      let outputs = [];
      obj.outputs.output_1.connections.forEach(item => {
        outputs.push({ "NODE": item.node, "OUTPUT": item.output });
      })
      ajustado['OUTPUTS'] = outputs;
    }

    return ajustado;
  }

  createConnection(obj) {
    this.auth.getUser();
    obj['ID_EMPRESA'] = this.auth.user.idEmpresa;

    this.util.showLoading();
    let url = API + "DrawFlow/DrawFlow/createConnection";
    this.ajax.post(url, obj)
      .subscribe(response => {
        this.util.closeLoading();
        this.util.toasterShowInfo("Atenção", "Conexao Criada com  Sucesso");

      }, err => {

        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
      });

  }

  montarNodeCreate(obj): any {
    this.auth.getUser();
    let objsave = {
      "ID_NODE": obj.id.toString(),
      "CLASS": obj.class,
      "HTML": obj.html,
      "NAME": obj.data.titulo,
      "POS_X": obj.pos_x,
      "POS_Y": obj.pos_y,
      "TYPENODE": obj.typenode,
      "CHAVE": obj.data.chave,
      "CORPO": obj.data.corpo,
      "NOME": obj.data.nome,
      "TITULO": obj.data.titulo,
      "ACAO": obj.data.acao,
      "ID_EMPRESA": this.auth.user.idEmpresa
    };

    if (obj.data.id != undefined && obj.data.id != null && obj.data.id != "")
      objsave['ID_DRAW_FLOW'] = obj.data.id

    return objsave;
  }

  ngAfterViewInit(): void {
    this.drawFlowHtmlElement = <HTMLElement>document.getElementById('drawflow');
    this.initDrawFlow(this.drawFlowHtmlElement);

    this.editor.on('click', (e: any) => {

      if (e.target.classList[0] === 'edit-node-button') {

        let selected = e.target.closest(".drawflow_content_node").parentElement.id;
        let dados = this.editor.drawflow.drawflow.Home.data[`${selected.slice(5)}`];
        var objSave = this.montarNodeCreate(dados);
        console.log(objSave);
        this.createNode(objSave);
      }

    });
    // Events!
    this.editor.on('nodeCreated', (id: any) => {

      let dados = this.editor.getNodeFromId(id);
      let objSave = this.montarNodeCreate(dados);
      console.log(objSave);
      this.createNode(objSave);

    });

    this.editor.on('nodeRemoved', (id: any) => {
      console.log('Editor Event :>> Node removed ' + id);
      //let dados = this.editor.getNodeFromId(id);
      //console.log(dados);
      this.deleteNode(id);
      //this.buscarNodes();
    });

    this.editor.on('nodeSelected', (id: any) => {

    });

    this.editor.on('moduleCreated', (name: any) => {
      console.log('Editor Event :>> Module Created ' + name);
    });

    this.editor.on('moduleChanged', (name: any) => {
      console.log('Editor Event :>> Module Changed ' + name);
    });

    this.editor.on('connectionCreated', (connection: any) => {
     console.log('connectionCreated', connection);
      this.createConnection(connection);
    });

    this.editor.on('connectionRemoved', (connection: any) => {
      console.log('connectionRemoved', connection);
      this.deleteConnection(connection);
    });

     this.editor.on('mouseMove', (position: any) => {
      this.mousePosition = position;
     });

    this.editor.on('nodeMoved', (id: any) => {
      //console.log('Editor Event :>> Node moved ' + this.mousePosition);
      let pos_x =
      this.mousePosition.x *
      (this.editor.precanvas.clientWidth /
        (this.editor.precanvas.clientWidth * this.editor.zoom)) -
      this.editor.precanvas.getBoundingClientRect().x *
      (this.editor.precanvas.clientWidth /
        (this.editor.precanvas.clientWidth * this.editor.zoom));

    let pos_y =
    this.mousePosition.y *
      (this.editor.precanvas.clientHeight /
        (this.editor.precanvas.clientHeight * this.editor.zoom)) -
      this.editor.precanvas.getBoundingClientRect().y *
      (this.editor.precanvas.clientHeight /
        (this.editor.precanvas.clientHeight * this.editor.zoom));

      let obj = {
        "id": this.editor.drawflow.drawflow.Home.data[id].data.id,
        "pos_x": pos_x,
        "pos_y": pos_y,
      };
      console.log(obj);
      this.savePosition(obj);
    });

    this.editor.on('zoom', (zoom: any) => {
      console.log('Editor Event :>> Zoom level ' + zoom);
    });

    // this.editor.on('translate', (position: any) => {
    //   console.log(
    //     'Editor Event :>> Translate x:' + position.x + ' y:' + position.y
    //   );
    // });

    this.editor.on('addReroute', (id: any) => {
      console.log('Editor Event :>> Reroute added ' + id);
    });

    this.editor.on('removeReroute', (id: any) => {
      console.log('Editor Event :>> Reroute removed ' + id);
    });
  }

  ngOnInit(): void {
    this.initializeList(1);
  }

  // Drag Events
  onDragStart(e: any) {
    if (e.type === 'dragstart') {
      console.log('onDragStart :>> e :>> ', e);
      this.selectedItem = <NodeElement>(
        this.nodes.find((node: NodeElement) => node.name === e.target.outerText)
      );
    }
  }

  onDragEnter(e: any) {
    console.log('onDragEnter :>> e :>> ', e);
  }

  onDragLeave(e: any) {
    console.log('onDragLeave :>> e :>> ', e);
  }

  onDragOver(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.lastMousePositionEv = e;
    // console.log('onDragOver :>> e :>> ', e);
  }

  onDragEnd(e: any) {
    console.log('onDragend :>> e :>> ', e);
  }

  onDrop(e: any) {
    // After dropping the element, create a node
    if (e.type === 'drop') {
      console.log('onDrop :>> e :>> ', e);
      e.preventDefault();
      this.addNodeToDrawBoard(e.clientX, e.clientY);
      this.resetAllInputsOutputs();
    }
  }

  resetAllInputsOutputs() {
    this.nodes.forEach((node) => {
      node.inputs = 1;
      node.outputs = 1;
    });
  }

  // Drawflow Editor Operations
  addNodeToDrawBoard(pos_x: number, pos_y: number) {
    if (this.editor.editor_mode === 'edit') {
      pos_x =
        pos_x *
        (this.editor.precanvas.clientWidth /
          (this.editor.precanvas.clientWidth * this.editor.zoom)) -
        this.editor.precanvas.getBoundingClientRect().x *
        (this.editor.precanvas.clientWidth /
          (this.editor.precanvas.clientWidth * this.editor.zoom));

      pos_y =
        pos_y *
        (this.editor.precanvas.clientHeight /
          (this.editor.precanvas.clientHeight * this.editor.zoom)) -
        this.editor.precanvas.getBoundingClientRect().y *
        (this.editor.precanvas.clientHeight /
          (this.editor.precanvas.clientHeight * this.editor.zoom));

      const htmlTemplate = `

          <div>
            <div class="box">
            <label class="tituloNode">Chave</label>
              <input df-chave>
              <label class="tituloNodeCenter" >Título Fluxo</label>
              <input df-titulo>
              <label class="tituloNode">Título</label>
              <input df-nome>
              <label class="tituloNode">Mensagem</label>
              <textarea df-corpo></textarea>
              <p>Ação</p>
              <select df-acao>
                <option value="NaoAplica">Não Aplica</option>
                <option value="FimFluxo">Fim Fluxo</option>
                <option value="RedirecionaAtendente">Redirecionar Atendente</option>
                <option value="proximoDraw">Próximo Item</option>
                <option value="aguardaDigitar">Aguardar Cliente Digitar</option>
              </select>
              <br>   <br>
              <button  class='edit-node-button'>Salvar</button>
            </div>
          </div>

          `;

      const nodeName = this.selectedItem.name;
      const nodeId = this.editor.addNode(
        this.selectedItem.name,
        this.selectedItem.inputs,
        this.selectedItem.outputs,
        pos_x,
        pos_y,
        '',
        { nome: "", corpo: "", chave: "", titulo: "", id: 0, acao: "NaoAplica" },
        htmlTemplate,
        false
      );

      this.nodesDrawn.push({
        nodeId,
        nodeName,
      });

      // const newNode = <DrawflowNode>this.editor.getNodeFromId(nodeId);
    }
  }

  onClear() {
    this.editor.clear();
  }

  changeMode() {
    this.locked = !this.locked;
    this.editor.editor_mode = this.locked ? 'fixed' : 'edit';
  }

  onZoomOut() {
    this.editor.zoom_out();
  }

  onZoomIn() {
    this.editor.zoom_in();
  }

  onZoomReset() {
    this.editor.zoom_reset();
  }

  onSubmit() {
    const dataExport = this.editor.export();
    console.log('dataExport :>> ', dataExport);
  }

}

import { Component, OnInit } from '@angular/core';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API, BASE_PATH } from 'src/environments/environment';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.scss']
})
export class ContatoComponent implements OnInit {

  public arrayContatos: any[];
  public contato: any;
  public query: string;
  public uploadedFile: File;
  public criarLista: boolean;
  public nomeLista: string;

  constructor(
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService
  ) {
    this.contato = { id: "", nome: "", email: "", telefone: "", observacao: "" };
    this.limpar();
  }

  limpar() {
    this.contato = { id: "", nome: "", email: "", telefone: "", observacao: "" };
    this.criarLista = true;
    this.arrayContatos = [];
    this.nomeLista = "";
  }

  downloadPlanilia() {
    let url = BASE_PATH + "/modelo/importar_contatos.csv";
    window.open(url);
  }

  uploadPlanilia() {
    debugger
    if (this.uploadedFile != undefined || this.uploadedFile != null) {
      this.auth.getUser();
      let dadosQrcode = {
        "ID_EMPRESA": this.auth.user.idEmpresa
      }
      debugger
      if (this.uploadedFile != undefined || this.uploadedFile != null)
        dadosQrcode['Arquivo'] = this.uploadedFile[0];

      if(this.criarLista){
        if(this.nomeLista == ""){
          this.util.toasterShowError("Atenção", "Entre com o nome da lista");
          return false;
        }

        dadosQrcode['NOME_LISTA'] = this.nomeLista;
      }

      let url = API + "Contato/Contato/Importar";
      let dados = this.util.jsonToFormData(dadosQrcode);

      this.util.showLoading();
      this.ajax.postFile(url, dados)
        .subscribe(response => {

          console.log('postFile',response);
          this.util.closeLoading();
          this.buscarContatos();
          this.limpar();
        }, err => {

          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
        });

    } else {
      this.util.toasterShowError("Atenção", "Entre com a Planilia");
    }

  }

  ngOnInit() {
    this.buscarContatos();
  }


  validaSalvar(): boolean {

    if (this.contato.nome == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Nome");
      return false;
    }

    if (this.contato.telefone == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Telefone");
      return false;
    }

    return true;
  }

  montarObjSave(): any {
    this.auth.getUser();
    let objSalvar = {
      "id_empresa": this.auth.user.idEmpresa,
      "nome": this.contato.nome,
      "telefone": this.contato.telefone,
      "email": this.contato.email,
      "observacao": this.contato.observacao
    };

    if (this.contato.id != "")
      objSalvar['ID_CONTATO'] = this.contato.id;

    return objSalvar;
  }

  salvarContato() {
    if (this.validaSalvar()) {
      let objSalvar = this.montarObjSave();
      console.log(objSalvar);
      this.util.showLoading();
      let url = API + "Contato/Contato";
      this.ajax.post(url, objSalvar)
        .subscribe(response => {

          if (!response.hasErrors) {
            this.limpar();
            this.util.toasterShowSuccess("Atenção", "Salvo com Sucesso");
            this.buscarContatos();
          }
          else
            this.util.toasterShowError("Atenção", response.message);

          this.util.closeLoading();
        }, err => {

          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Salvar Dados");
        });
    }
  }

  buscarContatos() {
    this.auth.getUser();
    this.util.showLoading();
    let url = API + "Contato/Contato?ID_EMPRESA=" + this.auth.user.idEmpresa;
    this.ajax.get(url)
      .subscribe(response => {

        this.arrayContatos = response.data;
        this.util.closeLoading();
      }, err => {

        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao buscar Dados");
      });
  }

  selecionarItem(item) {
    console.log(item);
    this.contato = {
      id: item.iD_CONTATO,
      nome: item.nome,
      telefone: item.telefone,
      email: item.email,
      observacao: item.observacao

    };
    window.scrollTo(0, 0);
  }

}

import { AuthService } from 'src/app/service/auth.service';
import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItemsOriginal = [
  {
    id: 'navigation',
    title: 'Menu',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [

      {
        id: 'home',
        title: 'Home',
        type: 'item',
        url: '/home',
        classes: 'nav-item',
        icon: 'feather icon-home',
        perfil: 'ADM',
      }
    ]

  },
  {
    id: 'navigation',
    title: 'Atendimento',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'fila-atendimento',
        title: 'Fila Atendimento',
        type: 'item',
        url: '/fila-atendimento',
        classes: 'nav-item',
        icon: 'feather icon-eye',
        perfil: 'ADM,ATE',
      }
    ]
  },
  {
    id: 'navigation',
    title: 'Configurações',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'cadastro-empresa',
        title: 'Empresa',
        type: 'item',
        url: '/cadastro-empresa',
        classes: 'nav-item',
        icon: 'feather icon-settings',
        perfil: 'ADM',
      } ,
      {
        id: 'cadastro-grupo',
        title: 'Grupos',
        type: 'item',
        url: '/cadastro-grupo',
        classes: 'nav-item',
        icon: 'feather icon-grid',
        perfil: 'ADM',
      } ,
      {
        id: 'cadastro-atendente',
        title: 'Atendentes',
        type: 'item',
        url: '/cadastro-atendente',
        classes: 'nav-item',
        icon: 'feather icon-users',
        perfil: 'ADM',
      } ,
      {
        id: 'motivo-encerramento',
        title: 'Motivos de Encerramento',
        type: 'item',
        url: '/motivo-encerramento',
        classes: 'nav-item',
        icon: 'feather icon-log-out',
        perfil: 'ADM',
      }
    ]
  },
  {
    id: 'navigation',
    title: 'Contatos',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'contato',
        title: 'Contatos',
        type: 'item',
        url: '/contato',
        classes: 'nav-item',
        icon: 'feather icon-phone',
        perfil: 'ADM,ATE',
      },
      {
        id: 'cadastro-template-modelo',
        title: 'Cadastro Template',
        type: 'item',
        url: '/cadastro-template-modelo',
        classes: 'nav-item',
        icon: 'feather icon-plus',
        perfil: 'ADM,ATE',
      },
      {
        id: 'cadastro-lista',
        title: 'Criar Listas',
        type: 'item',
        url: '/cadastro-lista',
        classes: 'nav-item',
        icon: 'feather icon-plus',
        perfil: 'ADM,ATE',
      },
      {
        id: 'disparar-lista',
        title: 'Disparar Lista',
        type: 'item',
        url: '/disparar-lista',
        classes: 'nav-item',
        icon: 'feather icon-message-circle',
        perfil: 'ADM,ATE',
      }
      ,
      {
        id: 'disparo-verificar',
        title: 'Verificar Disparos',
        type: 'item',
        url: '/disparo-verificar',
        classes: 'nav-item',
        icon: 'feather icon-list',
        perfil: 'ADM,ATE',
      }
    ]
  },
  {
    id: 'navigation',
    title: 'Fluxo',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'fluxo-draw',
        title: 'Montar Fluxo',
        type: 'item',
        url: '/fluxo-draw',
        classes: 'nav-item',
        icon: 'feather icon-share-2',
        perfil: 'ADM',
      }
    ]
  }

];

const NavigationItems = [
  {
    id: 'navigation',
    title: 'Menu',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [

      {
        id: 'home',
        title: 'Home',
        type: 'item',
        url: '/home',
        classes: 'nav-item',
        icon: 'feather icon-home',
        perfil: 'ADM',
      }
    ]

  }

];



@Injectable()
export class NavigationItem {

  constructor(
    private login: AuthService
  ) {

  }


    public get(ehTriagem?: boolean) {

    let itens = NavigationItems;
    let comPermissao = [];

    for (var i = 0; i < itens.length; i++) {
      comPermissao.push({...itens[i]});
      comPermissao[i].children = []

      for (var j = 0; j < itens[i].children.length; j++) {
        let perfil = this.login.getUser()["user"]["perfil"];

        //retira filhos
        let newArray = itens[i].children[j].perfil.split(",");
        //console.log("split",newArray);

        //if(perfil == itens[i].children[j].perfil){
        if((newArray.find( x => x == perfil)) != undefined){
          comPermissao[i].children.push(itens[i].children[j]);
        }
      }
    }

    this.removeReader(comPermissao);
    return comPermissao;
  }

  removeReader(comPermissao: any[]){
    for (var i = 0; i < comPermissao.length; i++) {
      if(comPermissao[i].children.length == 0){
        comPermissao.splice(i,1);
      }
    }
  }


  /*public get(ehTriagem?: boolean) {
    if(ehTriagem)
      return NavigationItems;
  }*/
}
